import React from "react";
import { io } from "socket.io-client";
import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import AuthContext from "../context/AuthContext";
import ChatBox from "./components/chat/chat/ChatBox";
import EmptyChat from "./components/chat/chat/EmptyChat";
import Menu from "./components/chat/menu/Menu";
import { styled, Box } from "@mui/material";
import { apiPost } from "../utils/apiFetch";
import apiPath from "../utils/apiPath";
import { toast } from "wc-toast";
import { isEmpty } from "lodash";

const Index = () => {
  const [socket, setSocket] = useState({});
  const [value, setValue] = useState("");
  const { user, profileData, setBellSound } = useContext(AuthContext);
  const [message, setMessage] = useState([]);
  const [singleUser, setSignleUser] = useState({});
  const [list, setList] = useState([]);
  const [select, setSelect] = useState([]);
  const [filterd, setFiltered] = useState([]);
  const [checkRefresh, setCheckRefresh] = useState(true);
  const [scroll, setScroll] = useState(false);
  const getSocket = () => {
    const newSocket = io(
      `${process.env.REACT_APP_API_BASE_URL}?token=${localStorage.getItem(
        "token"
      )}`,
      {
        transports: ["websocket"],
      }
    );
    setSocket(newSocket);
    newSocket.emit("getInbox", user?._id);
    newSocket.on("inboxData", (inbox) => {
      console.log(inbox, "inbok");
      let data =
        inbox?.length > 0
          ? inbox?.sort(function (a, b) {
              return new Date(b?.createdAt) - new Date(a?.createdAt);
            })
          : [];

      setList(data);
      setFiltered(data);
    });
    newSocket.on("newMessage", (inbox) => {
      if (inbox?.receiver == "66c0660453fe5334b07d41b6") {
        setMessage((prev) => {
          return [
            ...prev,
            {
              ...inbox,
              receiver: { _id: inbox?.receiver },
              sender: {
                _id: inbox?.sender,
              },
            },
          ];
        });
      } else {
        if (inbox?.chatId == singleUser?.chatId) {
          setMessage((prev) => {
            return [
              ...prev,
              {
                ...inbox,
                receiver: { _id: inbox?.receiver },
                sender: {
                  _id: inbox?.sender,
                },
              },
            ];
          });
        }
      }
    });
    newSocket.on("messageDeleted", (message) => {
      let ids = message?.messageIds;
      if (ids?.length > 0) {
        setMessage((prev) => {
          return prev.filter(function (obj) {
            return !ids.some(function (obj2) {
              return obj._id == obj2;
            });
          });
        });
        setSelect([]);
        newSocket.emit("getInbox", "66c0660453fe5334b07d41b6");
      }
    });
    newSocket.on("chatHistory", (messages) => {
      setScroll(true);
      setMessage(messages);
    });

    return () => newSocket.close();
  };

  useEffect(() => {
    if (checkRefresh && !isEmpty(user)) {
      getSocket();
      setBellSound(false);
    }
  }, [checkRefresh, singleUser]);

  document.addEventListener("visibilitychange", function () {
    if (!document.hidden && !checkRefresh && !isEmpty(user)) {
      setCheckRefresh(true);
      if (!isEmpty(socket)) {
        socket.disconnect();
      }
    } else {
      setCheckRefresh(false);
    }
  });

  function sendMessage(value, file, fileType) {
    if (!isEmpty(file)) {
      socket.emit("sendMessage", {
        senderId: "66c0660453fe5334b07d41b6",
        message: null,
        receiverId: singleUser?.userId,
        file: file,
        fileType: fileType,
        createdAt: new Date(),
      });
      setValue("");
    } else {
      if (!isEmpty(value) && value.trim()) {
        socket.emit("sendMessage", {
          senderId: "66c0660453fe5334b07d41b6",
          receiverId: singleUser?.userId,
          message: value,
          file: null,
          fileType: "text",
          createdAt: new Date(),
        });
        setValue("");
      }
    }
  }
  const uploadImage = async (image, type) => {
    let form = new FormData();
    form.append(
      "TransactionFile",
      type == "image"
        ? image
        : new File([image], "audiofile.mp3", {
            type: "audio/mp3",
          })
    );
    const { status, data } = await apiPost(apiPath.reciptUpload, form);
    if (status == 200) {
      sendMessage("", data?.path, data?.mimetype);
    } else {
      toast.error("Something went wrong!");
    }
  };
  const getSingleChat = (id) => {
    setSignleUser(id);
    socket.emit("getChatHistory", id?.chatId || "");
    socket.emit("markAsRead", id?.chatId, "66c0660453fe5334b07d41b6");
  };
  const submit = () => {
    sendMessage(value);
  };
  const addAudioElement = (blob) => {
    uploadImage(blob, "audio");
  };
  function deleteMessage(messageId) {
    socket.emit("deleteMessage", singleUser?.chatId, messageId.join());
  }
  return (
    <Component>
      <LeftComponent>
        <Menu
          singleUser={singleUser}
          list={filterd}
          orginal={list}
          setFiltered={setFiltered}
          message={message}
          getSingleChat={getSingleChat}
        />
      </LeftComponent>
      <RightComponent>
        {!isEmpty(singleUser) ? (
          <ChatBox
            value={value}
            setValue={setValue}
            singleUser={singleUser}
            message={message}
            uploadImage={uploadImage}
            select={select}
            setSelect={setSelect}
            scroll={scroll}
            setScroll={setScroll}
            submit={submit}
            addAudioElement={addAudioElement}
            deleteChat={deleteMessage}
          />
        ) : (
          <EmptyChat />
        )}
      </RightComponent>
    </Component>
  );
};

export default Index;

const Component = styled(Box)`
  display: flex;
`;

const LeftComponent = styled(Box)`
  min-width: 350px;
  width: 20%;
`;

const RightComponent = styled(Box)`
  width: 80%;
  min-width: 300px;
  height: 100%;
  border-left: 1px solid rgba(0, 0, 0, 0.14);
`;
