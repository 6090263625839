import { isEmpty } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import obj from "../../utils/constants";
import DropDown from "./DropDown";
import helpers from "../../utils/helpers";
import AuthContext from "../../context/AuthContext";
import { FancyListDropdown } from "./FancyListDropdown";
import { Link } from "react-router-dom";
import { MdRefresh } from "react-icons/md";
import ViewDialog from "./ViewDialog";
import { IoMdArrowDropright } from "react-icons/io";
const BetTypeListingMobile = ({
  title,
  data,
  socketOddsWork,
  refresh,
  titleNew,
}) => {
  const [showMatch, setShowMatch] = useState(false);
  let { user } = useContext(AuthContext);
  const [selectData, setSelectData] = useState("");
  const [id, setId] = useState([]);
  const [modal, setModal] = useState(false);
  const [object, setObject] = useState({});

  useEffect(() => {
    setId(
      data?.map((res) => {
        return res?._id;
      })
    );
  }, [data]);

  const handelChange = (id) => {
    setId((prev) => {
      return prev?.includes(id)
        ? prev?.filter((res) => {
            return res !== id;
          })
        : [...prev, id];
    });
  };
  return (
    <div className="risk-management-table risk-management-table-new">
      <div className="risk-management-table-header">
        {" "}
        <h2 className="common-heading">{titleNew}</h2>
        <div>
          {" "}
          <MdRefresh onClick={() => refresh()} size={20} />
        </div>
      </div>

      {data.some((res) =>
        title == "Book Maker"
          ? res?.bookmakerRunners?.length == 3
          : title == "Toss"
          ? res?.runnerData?.length == 3
          : res?.runners?.length == 3
      ) && (
        <div className="account-table match-odd-table">
          <div className="responsive">
            <Table>
              {/*  */}
              <tbody className="match-tbody">
                {isEmpty(data) && (
                  <tr>
                    <td colSpan={7}>No Record Found</td>
                  </tr>
                )}
                {data?.length > 0 &&
                  data.map((res, index) => {
                    if (
                      title == "Book Maker"
                        ? res?.bookmakerRunners?.length == 3
                        : res?.runners?.length == 3
                    ) {
                      return (
                        <>
                          <tr key={index + 1}>
                            <td className="bg-yellow border-0">
                              <div className="d-flex justify-content-between align-items-center">
                                <a>
                                  <strong>{res.eventName}</strong>
                                </a>
                                {id?.includes(res?._id) ? (
                                  <Button
                                    onClick={() => {
                                      setShowMatch(false);
                                      setSelectData("");
                                      handelChange(res?._id);
                                      socketOddsWork(res?.eventId);
                                    }}
                                    className={"angle-up"}
                                  >
                                    <i className="fas fa-angle-up"></i>
                                  </Button>
                                ) : (
                                  <Button
                                    onClick={() => {
                                      setShowMatch(true);
                                      handelChange(res?._id);
                                      setSelectData(res);
                                      socketOddsWork(res?.eventId);
                                    }}
                                    className={"angle-up down-up"}
                                  >
                                    <i className="fas fa-angle-up"></i>
                                  </Button>
                                )}
                              </div>
                            </td>
                          </tr>{" "}
                          {id?.includes(res?._id) ? (
                            <DropDown
                              showMatch={showMatch}
                              title={title}
                              user={user}
                              layData={res.lay_odds || []}
                              data={
                                title == "Book Maker"
                                  ? res?.bookmakerRunners
                                  : res?.runners
                              }
                              res={res}
                              setModalOuter={setModal}
                              modalOuter={modal}
                              setObjectOuter={setObject}
                              heading={res?.eventName}
                              backData={res.back_odds || []}
                            />
                          ) : (
                            ""
                          )}
                        </>
                      );
                    }
                  })}
              </tbody>
            </Table>
          </div>
        </div>
      )}
      {data.some((res) =>
        title == "Book Maker"
          ? res?.bookmakerRunners?.length == 2
          : title == "Toss"
          ? res?.runnerData?.length == 2
          : res?.runners?.length == 2
      ) && (
        <div className="account-table match-odd-table ">
          <div className="responsive">
            <Table>
              
              <tbody className="match-tbody">
                {isEmpty(data) && (
                  <tr>
                    <td colSpan={7}>No Record Found</td>
                  </tr>
                )}
                {data?.length > 0 &&
                  data.map((res, index) => {
                    if (
                      title == "Book Maker"
                        ? res?.bookmakerRunners?.length == 2
                        : title == "Toss"
                        ? res?.runnerData?.length == 2
                        : res?.runners?.length == 2
                    ) {
                      return (
                        <>
                          <tr key={index + 1}>
                            <td className="bg-yellow border-0">
                              <div className="d-flex justify-content-between align-items-center">
                                <a>
                                  <strong>{res.eventName}</strong>
                                </a>
                                {id?.includes(res?._id) ? (
                                  <Button
                                    onClick={() => {
                                      setShowMatch(false);
                                      setSelectData("");
                                      handelChange(res?._id);
                                      socketOddsWork(res?.eventId);
                                    }}
                                    className={"angle-up"}
                                  >
                                    <i className="fas fa-angle-up"></i>
                                  </Button>
                                ) : (
                                  <Button
                                    onClick={() => {
                                      setShowMatch(true);
                                      handelChange(res?._id);
                                      setSelectData(res);
                                      socketOddsWork(res?.eventId);
                                    }}
                                    className={"angle-up down-up"}
                                  >
                                    <i className="fas fa-angle-up"></i>
                                  </Button>
                                )}
                              </div>
                            </td>
                          </tr>
                          {id?.includes(res?._id) ? (
                            <DropDown
                              showMatch={showMatch}
                              layData={res.lay_odds || []}
                              user={user}
                              data={
                                title == "Book Maker"
                                  ? res?.bookmakerRunners
                                  : title == "Toss"
                                  ? res?.runnerData
                                  : res?.runners
                              }
                              res={res}
                              setModalOuter={setModal}
                              modalOuter={modal}
                              setObjectOuter={setObject}
                              heading={res?.eventName}
                              title={title}
                              backData={res.back_odds || []}
                            />
                          ) : (
                            ""
                          )}
                        </>
                      );
                    }
                  })}
              </tbody>
            </Table>
          </div>
        </div>
      )}
      {modal && (
        <ViewDialog
          onHide={() => {
            setModal(false);
            setObject({});
          }}
          setObject={setObject}
          show={modal}
          object={object}
        />
      )}
    </div>
  );
};

export default BetTypeListingMobile;
