import React, { useState, useEffect } from "react";
import { Container, Row, Col, Table } from "react-bootstrap";
import MyAccountSidebar from "../../components/MyAccountSidebar";
import { apiGet, apiPost } from "../../utils/apiFetch";
import apiPath from "../../utils/apiPath";
import { pick } from "lodash";
import { useForm } from "react-hook-form";
import Breadcrumbs from "./Breadcrumbs";
import { toast } from "wc-toast";
import { Link, useLocation } from "react-router-dom";
import { ResetPassword } from "../../components/ResetPassword";
import { Helmet } from "react-helmet";
import DetailSlip from "../../components/DetailSlip";
import { Modal, Form, Button } from "react-bootstrap";

const MyProfile = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm({});
  const location = useLocation();
  const [profileData, setProfileData] = useState("");
  const [password_same, set_password_same] = useState(true);
  const [code, setCode] = useState({
    status: false,
    item: "",
  });
  const [isLoader, setLoader] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const changePasswordToggle = () => setChangePassword(!changePassword);
  const myProfile = async () => {
    const { status, data: response_users } = await apiGet(apiPath.userProfile);
    if (status === 200) {
      if (response_users.success) {
        setProfileData(response_users.results);
      }
    }
  };

  const onSubmit = async (request) => {
    setLoader(true);
    set_password_same(true);

    if (request.newPassword !== request.confirmPassword) {
      setLoader(false);
      set_password_same(false);
    } else {
      const { status, data: response_users } = await apiPost(
        apiPath.changePassword,
        pick(request, ["oldPassword", "newPassword"])
      );
      if (status === 200) {
        if (response_users.success) {
          setLoader(false);
          setChangePassword();
          toast.success(response_users.message);
          reset();
        } else {
          setLoader(false);
          toast.error(response_users.message);
        }
      }
    }
  };

  const updateRefrel = async (request) => {
    const { status, data: response_users } = await apiPost(
      apiPath.updateRefrelCode,
      { referalCode: request?.referalCode }
    );
    if (status === 200) {
      if (response_users.success) {
        toast.success(response_users.message);
        myProfile();
        setCode({ status: false });
        reset();
      } else {
        toast.error(response_users.message);
      }
    }
  };
  useEffect(() => {
    myProfile();
  }, []);

  useEffect(() => {
    if (location?.search.split("=")[1] == "changepassword") {
      changePasswordToggle();
    }
  }, [location]);

  function copy(text) {
    toast.success("Link Copied!");
    return window.navigator.clipboard.writeText(text);
  }
  const [title, setTitle] = useState("");

  useEffect(() => {
    const domainName = window.location.hostname;
    let newTitle = "";
    if (domainName.includes("mirajexchange.com")) {
      newTitle = "https://mirajexchange.com/register?referral_code=";
    } else if (
      domainName.includes("anybett.live") ||
      domainName.includes("anybett.live") ||
      domainName.includes("admin.anybett.live")
    ) {
      newTitle = "https://anybett.live/register?referral_code=";
    } else if (
      domainName.includes("aaexch.live") ||
      domainName.includes("aaexch.live") ||
      domainName.includes("admin.aaexch.live")
    ) {
      newTitle = "https://aaexch.live/register?referral_code=";
    } else {
      newTitle = "https://wifiexch.live/register?referral_code=";
    }
    setTitle(newTitle);
  }, []);

  return (
    <div>
      {/* <DetailSlip /> */}
      <section className="py-4 main-inner-outer">
        <Container fluid>
          <Row>
            <Col lg={12} md={12}>
              <div className="inner-wrapper">
                {/* profile */}

                <h2>profile</h2>
                <div className="account-table w-100">
                  <div className="profile-tab table-color">
                    <Row>
                      <Col md={7}>
                        <div className="responsive">
                          <Table>
                            <thead>
                              <tr>
                                <th
                                  scope="col"
                                  colSpan="4"
                                  className="text-start"
                                >
                                  About You
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td className="text-start" width="25%">
                                  First Name
                                </td>
                                <td className="text-start" colSpan="3">
                                  {profileData.firstName}
                                </td>
                              </tr>
                              <tr>
                                <td className="text-start" width="25%">
                                  Last Name
                                </td>
                                <td className="text-start" colSpan="3">
                                  {profileData.lastName}
                                </td>
                              </tr>

                              <tr>
                                <td className="text-start" width="25%">
                                  Birthday
                                </td>
                                <td className="text-start" colSpan="3">
                                  -----
                                </td>
                              </tr>

                              <tr>
                                <td className="text-start" width="25%">
                                  Email
                                </td>
                                <td className="text-start" colSpan="3">
                                  {profileData.email}
                                </td>
                              </tr>
                              <tr>
                                <td className="text-start" width="25%">
                                  Password
                                </td>
                                <td className="text-start">************</td>
                                <td className="p-2">
                                  <Link
                                    to="#"
                                    className="text-decoration-none theme_dark_btn"
                                    onClick={changePasswordToggle}
                                  >
                                    Edit{" "}
                                    <i className="fas fa-pen text-white ps-1"></i>
                                  </Link>
                                </td>
                              </tr>
                              <tr>
                                <td className="text-start" width="25%">
                                  Exposure
                                </td>
                                <td className="text-start" colSpan="3">
                                  {profileData.exposure}
                                </td>
                              </tr>
                              <tr>
                                <td className="text-start" width="25%">
                                  Time Zone
                                </td>
                                <td className="text-start" colSpan="3">
                                  {profileData.timeZone}
                                </td>
                              </tr>
                              {profileData?.userType == "agent" && (
                                <tr style={{ fontWeight: "600" }}>
                                  <td className="text-start" width="25%">
                                    Referal Code
                                  </td>

                                  <td
                                    style={{ cursor: "pointer", color: "blue" }}
                                    onClick={() => {
                                      copy(title + profileData?.referalCode);
                                    }}
                                    className="text-start"
                                  >
                                    {profileData?.referalCode
                                      ? title + profileData?.referalCode
                                      : "-"}
                                  </td>
                                  <td className="p-2">
                                    <Link
                                      to="#"
                                      className="text-decoration-none theme_dark_btn"
                                      onClick={() => {
                                        setCode({ ...code, status: true });
                                        setValue(
                                          "referalCode",
                                          profileData?.referalCode
                                        );
                                      }}
                                    >
                                      Edit <i className="fas fa-pen ps-1"></i>
                                    </Link>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </Table>
                        </div>
                      </Col>

                      <Col md={5}>
                        {" "}
                        <div className="responsive">
                          <Table>
                            <thead>
                              <tr>
                                <th
                                  scope="col"
                                  colSpan="4"
                                  className="text-start"
                                >
                                  Contact Details
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td className="text-start">Primary Number</td>
                                <td className="text-start">
                                  {" "}
                                  {profileData.phone}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>

                {/* profile */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* change-password */}
      <ResetPassword
        changePassword={changePassword}
        changePasswordToggle={changePasswordToggle}
        onSubmit={onSubmit}
        isLoader={isLoader}
        password_same={password_same}
      />
      <Modal
        show={code?.status}
        onHide={() => {
          setCode({ status: false });
        }}
        className="change-status-modal p-0"
      >
        <Modal.Header closeButton>
          <Modal.Title className="modal-title-status h4">
            Update Referal Code
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="test-status border-0">
            <Form
              className="change-password-sec"
              onSubmit={handleSubmit(updateRefrel)}
            >
              <Form.Group className="d-flex  mb-2">
                <Form.Control
                  type="text"
                  maxLength={8}
                  placeholder="Referal Code"
                  className={errors.referalCode ? " is-invalid " : ""}
                  {...register("referalCode", {
                    required: "Please enter referal code",
                  })}
                />
                {errors.referalCode && errors.referalCode.message && (
                  <label className="invalid-feedback align-leftt">
                    {errors.referalCode.message}
                  </label>
                )}
              </Form.Group>

              <div className="text-center mt-4">
                <Button type="submit" className="green-btn">
                  Update
                </Button>
              </div>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default MyProfile;
