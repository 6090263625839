import React from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Table,
  Button,
  Modal,
} from "react-bootstrap";
import obj from "../../utils/constants";
import helpers from "../../utils/helpers";
import { isEmpty, startCase } from "lodash";
import ReactPaginate from "react-paginate";
const AllBet = ({ open, onClose, data, filter, setFilter }) => {
  let Obj = {
    all: data?.allBet || [],
    betFair: data?.BetFairBet?.matched || [],
    bookmaker: data?.BookmakerBet || [],
    fancy: data?.FancyBet || [],
  };
  return (
    <Modal show={open} onHide={onClose} size="2xl" fullscreen={"2xl"}>
      <Modal.Header closeButton className="border-0">
        <Modal.Title className="modal-title-status">View More Bet</Modal.Title>
      </Modal.Header>
      <Modal.Body className="py-3">
        <div className="all-bets-dialog">
          <Row className="mb-3">
            <Col lg={3} md={3} sm={6}>
              <Form.Label>Select Market Type</Form.Label>
              <Form.Select
                onChange={(e) =>
                  setFilter({ ...filter, betType: e.target.value })
                }
                value={filter.betType}
                aria-label="Default select example"
              >
                {" "}
                <option value={"all"}>Select Market Type</option>
                <option value={"betFair"}>BetFair</option>
                <option value={"bookmaker"}>Bookmaker</option>{" "}
                <option value={"fancy"}>Fancy</option>
              </Form.Select>
            </Col>
            {/* <Col lg={3} md={3} sm={6}>
              <Form.Label>Amount From</Form.Label>
              <Form.Control
                type="number"
                placeholder="Amount To"
                value={filter?.amountFrom}
                onChange={(e) => {
                  setFilter((prev) => {
                    return { ...prev, amountFrom: e.target.value };
                  });
                }}
              />
            </Col>
            <Col lg={3} md={3} sm={6}>
              <Form.Label>Amount To</Form.Label>
              <Form.Control
                type="number"
                placeholder="Amount To"
                value={filter?.amountTo}
                onChange={(e) => {
                  setFilter((prev) => {
                    return { ...prev, amountTo: e.target.value };
                  });
                }}
              />
            </Col> */}
          </Row>

          <div className="responsive">
            <Table className="all-bets-dialog-tabel">
              <thead>
                <tr>
                  <th scope="col">PL ID</th>
                  <th scope="col"> Bet ID</th>
                  <th scope="col">Bet placed</th>
                  <th scope="col">IP Address </th>
                  <th scope="col">Market</th>
                  <th scope="col">Selection</th>
                  <th scope="col">Type</th>
                  <th scope="col">Odds req.</th>
                  <th scope="col">Stake </th>
                  <th scope="col">Liability</th>
                  <th scope="col"> Profit/Loss</th>
                </tr>
              </thead>
              {Obj[filter?.betType]?.length > 0
                ? Obj[filter?.betType]?.map((item) => {
                    return (
                      <tr
                        bgcolor={
                          item?.betType == "back" || item?.type == "Yes"
                            ? "#72BBEF"
                            : "#FAA9BA"
                        }
                      >
                        <td>{item?.clientName || "-"}</td>
                        <td> {item?.matchBetId || item?.sessionBetId}</td>
                        <td> {helpers.dateFormat(item?.timeInserted)}</td>
                        <td>
                          {" "}
                          {(item?.ipAddress &&
                            item?.ipAddress.replace("::ffff:", "")) ||
                            "-"}
                        </td>
                        <td className="text-start">
                          {obj.betCheckObj[Number(item?.eventType)]}
                          <span
                            style={{ background: "transparent" }}
                            className="angle_unicode"
                          >
                            ▸
                          </span>
                          <strong style={{ background: "transparent" }}>
                            {item?.matchName}
                          </strong>
                          <span
                            style={{ background: "transparent" }}
                            className="angle_unicode"
                          >
                            ▸
                          </span>
                          {item?.betFaireType === "betfair" && "Match Odds"}{" "}
                          {item?.betFaireType === "bookmaker" && "Bookmaker"}
                          {item?.fancyName !== "" && item?.fancyName}
                        </td>
                        <td>
                          {item?.teamName || item?.betRun + "/" + item?.bhav}
                        </td>
                        <td> {item?.betType || item?.type} </td>
                        <td> {item?.bhav} </td>
                        <td> {item?.amount || "-"} </td>
                        <td> {item?.loseAmount || "-"} </td>
                        <td>
                          {item?.betType == "back" || item?.type == "Yes" ? (
                            <span
                              style={{ background: "transparent" }}
                              className="text-success"
                            >
                              {item?.profitAmount}
                            </span>
                          ) : (
                            <span
                              style={{ background: "transparent" }}
                              className="text-danger"
                            >
                              -({item?.loseAmount})
                            </span>
                          )}
                        </td>
                      </tr>
                    );
                  })
                : null}
              {isEmpty(Obj[filter?.betType]) && (
                <tr>
                  <td colSpan={12}>No Record Found</td>
                </tr>
              )}
            </Table>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="footer-allBet">
          <div>
            Showing 1 - {Obj[filter?.betType]?.length || 0} of{" "}
            {Obj[filter?.betType]?.length || 0} entries
          </div>
          <div className="bottom-pagination">
            <ReactPaginate
              breakLabel="..."
              nextLabel=" >"
              // forcePage={viewpage}
              // onPageChange={handlePageClick}
              pageRangeDisplayed={10}
              pageCount={1}
              previousLabel="< "
              renderOnZeroPageCount={null}
              activeClassName="p-1"
              activeLinkClassName="pagintion-li"
            />
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default AllBet;
