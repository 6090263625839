import React, { useEffect, useState, useContext } from "react";
import { Container, Row, Col, Form, Table, Button } from "react-bootstrap";
import { apiGet } from "../utils/apiFetch";
import obj from "../utils/constants";
import apiPath from "../utils/apiPath";
import moment from "moment";
import ReactPaginate from "react-paginate";
import { useLocation } from "react-router-dom";
import helpers from "../utils/helpers";
import AuthContext from "../context/AuthContext";
import { Helmet } from "react-helmet";
import DetailSlip from "../components/DetailSlip";
import BetListLottery from "../components/BetListLottery";
const BetList = () => {
  let { user } = useContext(AuthContext);

  var previousDate = moment().subtract(1, "days").format("YYYY-MM-DD");
  var currentDate = moment().format("YYYY-MM-DD");
  const [pageCount, setPageCount] = useState(0);
  const [viewpage, setViewPage] = useState(0);
  const [data, setData] = useState([]);
  const [getBetType, setBetType] = useState("betfair");
  const [lotteryType, setLotteryType] = useState("");
  const [getType, setType] = useState("4");
  const location = useLocation();
  const [filter, setFilter] = useState({
    type: "4",
    betType: "betfair",
    status: "completed",
    fromPeriod: previousDate,
    toPeriod: currentDate,
    filterByDay: "",
    page: 1,
    limit: 100,
  });

  const getData = async (type = "search") => {
    if (filter.filterByDay != "") {
      if (filter.filterByDay == "today") {
        filter.fromPeriod = currentDate;
        filter.toPeriod = currentDate;
      }
      if (filter.filterByDay == "yesterday") {
        filter.fromPeriod = previousDate;
        filter.toPeriod = currentDate;
      }
    }
    const { status, data: response_users } = await apiGet(
      apiPath.getBets,
      type == "search" ? filter : type
    );
    if (status === 200) {
      if (response_users.success) {
        setLotteryType(filter?.betType == "lottery" ? "lottery" : "");
        setData(response_users.results);
        setBetType(filter.betType);
        setType(filter.type);
      }
    }
  };

  useEffect(() => {
    if (filter.filterByDay != "") {
      getData();
    }
  }, [filter.filterByDay]);

  const handleSearch = (type) => {
    setFilter((prevState) => {
      return {
        ...prevState,
        page: 1,
      };
    });
    getData(type);
  };
  const handlePageClick = (event) => {
    setFilter((prevState) => {
      return {
        ...prevState,
        page: event.selected + 1,
      };
    });
    setViewPage(event.selected);
  };

  useEffect(() => {
    setPageCount(data?.totalPages || []);
  }, [data]);

  useEffect(() => {
    getData();
    setViewPage(filter.page ? filter.page - 1 : 0);
  }, [
    filter?.type,
    filter?.betType,
    filter?.status,
    filter?.type,
    filter?.page,
  ]);

  return (
    <div>
      {/* <DetailSlip /> */}
      <section className="find-member-sec py-3">
        <Container fluid>
          <h4 className="page-title">Bet List</h4>
          <div className="inner-wrapper">
            <div className="common-container">
              <Form className="bet_status p-0 bet-list-live d-flex flex-column w-100 align-items-start">
                <div className="bet_outer betlist-n w-100">
                  <div className="bet-sec">
                    <Form.Label>Select Sport:</Form.Label>
                    <Form.Select
                      className="small_select"
                      onChange={(e) =>
                        setFilter({
                          ...filter,
                          type: e.target.value,
                          page: 1,
                          limit: 100,
                        })
                      }
                      value={filter.type}
                      aria-label="Default select example"
                    >
                      {obj?.betCheckData?.map((res) => {
                        return <option value={res?.label}>{res?.value}</option>;
                      })}
                    </Form.Select>
                  </div>

                  <div className="bet-sec">
                    <Form.Label>Select Market Type:</Form.Label>
                    <Form.Select
                      className="small_select"
                      onChange={(e) =>
                        setFilter({
                          ...filter,
                          betType: e.target.value,
                          page: 1,
                          limit: 100,
                        })
                      }
                      value={filter.betType}
                      aria-label="Default select example"
                    >
                      {obj?.betCheckDataInner?.map((res) => {
                        return <option value={res?.label}>{res?.value}</option>;
                      })}
                    </Form.Select>
                  </div>
                  <div className="bet-sec">
                    <Form.Label>Bet Status:</Form.Label>
                    <Form.Select
                      className="small_select"
                      onChange={(e) =>
                        setFilter({ ...filter, status: e.target.value })
                      }
                      value={filter.status}
                      aria-label="Default select example"
                    >
                      {filter.betType == "betfair" &&
                        (filter.type == 4 ||
                          filter.type == 2 ||
                          filter.type == 1) && (
                          <>
                            {" "}
                            <option value="unmatched">Unmatched</option>
                            <option value="matched">Matched</option>
                          </>
                        )}

                      <option value="completed">Settled</option>
                      <option value="suspend">Cancelled</option>
                      <option value="voided">Voided</option>
                    </Form.Select>
                  </div>

                  <div className="bet-sec bet-period">
                    <Form.Label>From</Form.Label>
                    <Form.Control
                      onChange={(e) =>
                        setFilter({
                          ...filter,
                          fromPeriod: e.target.value,
                          filterByDay: "",
                        })
                      }
                      max={new Date().toISOString().split("T")[0]}
                      value={filter.fromPeriod}
                      type="date"
                    />
                  </div>
                  <div className="bet-sec bet-period">
                    <Form.Label>To</Form.Label>
                    <Form.Control
                      onChange={(e) =>
                        setFilter({
                          ...filter,
                          toPeriod: e.target.value,
                          filterByDay: "",
                        })
                      }
                      min={
                        filter?.fromPeriod
                          ? new Date(filter?.fromPeriod)
                              .toISOString()
                              .split("T")[0]
                          : new Date()
                      }
                      disabled={filter.fromPeriod ? false : true}
                      max={new Date().toISOString().split("T")[0]}
                      value={filter.toPeriod}
                      type="date"
                    />
                  </div>
                </div>{" "}
                <div className="history-btn ">
                  <ul className="list-unstyled mb-0">
                    <li>
                      <Button
                        className="theme_dark_btn"
                        onClick={() => handleSearch("search")}
                      >
                        Search
                      </Button>
                    </li>
                    <li>
                      <Button
                        className="theme_light_btn"
                        onClick={() => {
                          setFilter({
                            type: "4",
                            betType: "betfair",
                            status: "completed",
                            fromPeriod: "",
                            toPeriod: "",
                            filterByDay: "",
                            page: 1,
                            limit: 100,
                          });
                          getData({
                            type: "4",
                            betType: "betfair",
                            status: "completed",
                            fromPeriod: "",
                            toPeriod: "",
                            filterByDay: "",
                            page: 1,
                            limit: 100,
                          });
                        }}
                      >
                        Reset
                      </Button>
                    </li>
                  </ul>
                </div>
              </Form>
              {lotteryType == "lottery" ? (
                <BetListLottery data={data?.data} type='pl'/>
              ) : (
                <div className="responsive">
                  <Table className="all-bets-dialog-tabel">
                    <thead>
                      <tr>
                        <th scope="col">PL ID</th>
                        <th scope="col"> Bet ID</th>
                        <th scope="col">Bet placed</th>
                        <th scope="col">IP Address </th>
                        <th scope="col">Market</th>
                        <th scope="col">Selection</th>
                        <th scope="col">Type</th>
                        {filter?.betType != "casino" && (
                          <th scope="col">Odds req.</th>
                        )}
                        <th scope="col">Stake </th>
                        {filter?.betType != "casino" && (
                          <th scope="col">Liability</th>
                        )}
                        <th scope="col"> Profit/Loss</th>
                      </tr>
                    </thead>
                    {data?.data && data?.data?.length > 0 ? (
                      data?.data?.map((item) => {
                        return (
                          <tr
                            bgcolor={
                              item?.betType == "back" || item?.type == "Yes"
                                ? "#72BBEF"
                                : "#FAA9BA"
                            }
                          >
                            <td>{item?.clientName || "-"}</td>
                            <td>
                              {" "}
                              {getType === "3"
                                ? item?.casinoBetId
                                : item?.matchBetId || "-"}
                            </td>
                            <td>
                              {" "}
                              {helpers.dateFormat(
                                item?.timeInserted,
                                user.timeZone
                              )}
                              {/* {item?.timeInserted || "-"} */}
                            </td>
                            <td>
                              {" "}
                              {(item?.ipAddress &&
                                item?.ipAddress.replace("::ffff:", "")) ||
                                "-"}
                            </td>
                            <td className="text-start">
                              {obj.betCheckObj[getType]}
                              <span
                                style={{ background: "transparent" }}
                                className="angle_unicode"
                              >
                                ▸
                              </span>
                              <strong style={{ background: "transparent" }}>
                                {getType === "3"
                                  ? item?.casinoName
                                  : item?.matchName}
                              </strong>
                              <span
                                style={{ background: "transparent" }}
                                className="angle_unicode"
                              >
                                ▸
                              </span>
                              {getBetType === "betfair" && "Match Odds"}{" "}
                              {getBetType === "bookmaker" && "Book Maker"}
                              {getBetType === "sportBook" && item?.fancyName}
                              {getBetType === "fancy" && item?.fancyName}{" "}
                              {getBetType === "casino" && item?.casinoType}{" "}
                            </td>
                            <td>
                              {getBetType === "fancy"
                                ? item?.betRun + "/" + item?.bhav
                                : getBetType === "sportBook"
                                ? item?.runnerName
                                : getBetType === "casino"
                                ? item?.platformTxId
                                : item?.teamName}

                              {/* {item?.fancyName || item?.teamName || item?.platformTxId} */}
                            </td>

                            <td>
                              {" "}
                              {item?.betType ||
                                item?.type ||
                                item?.gameCode}{" "}
                            </td>
                            {filter?.betType != "casino" && (
                              <td>
                                {" "}
                                {getType === "3" ? 0 : item?.bhav || "-"}{" "}
                              </td>
                            )}

                            <td>
                              {" "}
                              {filter?.betType != "casino"
                                ? item?.amount
                                : item?.betAmount}{" "}
                            </td>
                            {filter?.betType != "casino" && (
                              <td> {item?.loseAmount || "-"} </td>
                            )}

                            {/* <td> */}
                            {item?.status !== "voided" ? (
                              filter?.betType != "casino" ? (
                                <td className="text-end">
                                  {getBetType == "betfair" ||
                                  getBetType === "Bookmaker" ||
                                  getBetType === "toss" ||
                                  getBetType === "tie" ? (
                                    <>
                                      {(item?.teamSelectionWin ==
                                        item?.selectionId &&
                                        item?.betType == "back") ||
                                      (item?.teamSelectionWin !=
                                        item?.selectionId &&
                                        item?.betType == "lay") ? (
                                        <span
                                          style={{ background: "transparent" }}
                                          className="text-success"
                                        >
                                          {item?.profitAmount}
                                        </span>
                                      ) : (
                                        <span
                                          style={{ background: "transparent" }}
                                          className="text-danger"
                                        >
                                          -({item?.loseAmount})
                                        </span>
                                      )}
                                    </>
                                  ) : getBetType === "sportBook" ? (
                                    <>
                                      {item?.teamSelectionWin &&
                                      item?.teamSelectionWin
                                        .split(",")
                                        .includes(item?.fancySelectionId) ? (
                                        <span
                                          style={{ background: "transparent" }}
                                          className="text-success"
                                        >
                                          {item?.profitAmount}
                                        </span>
                                      ) : (
                                        <span
                                          style={{ background: "transparent" }}
                                          className="text-danger"
                                        >
                                          -({item?.loseAmount})
                                        </span>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      {item?.type == "No" ? (
                                        item?.decisionRun < item?.betRun ? (
                                          <span
                                            style={{
                                              background: "transparent",
                                            }}
                                            className="text-success"
                                          >
                                            {item?.profitAmount}
                                          </span>
                                        ) : (
                                          <span
                                            style={{
                                              background: "transparent",
                                            }}
                                            className="text-danger"
                                          >
                                            -({item?.loseAmount})
                                          </span>
                                        )
                                      ) : item?.decisionRun >= item?.betRun ? (
                                        <span
                                          style={{ background: "transparent" }}
                                          className="text-success"
                                        >
                                          {item?.profitAmount}
                                        </span>
                                      ) : (
                                        <span
                                          style={{ background: "transparent" }}
                                          className="text-danger"
                                        >
                                          -({item?.loseAmount})
                                        </span>
                                      )}
                                    </>
                                  )}
                                </td>
                              ) : (
                                <td className="text-end">
                                  {getBetType == "betfair" ||
                                  getBetType === "Bookmaker" ? (
                                    <>
                                      {(item?.teamSelectionWin ==
                                        item?.selectionId &&
                                        item?.betType == "back") ||
                                      (item?.teamSelectionWin !=
                                        item?.selectionId &&
                                        item?.betType == "lay") ? (
                                        <span
                                          style={{ background: "transparent" }}
                                          className="text-success"
                                        >
                                          {item?.profitAmount}
                                        </span>
                                      ) : (
                                        <span
                                          style={{ background: "transparent" }}
                                          className="text-danger"
                                        >
                                          -({item?.loseAmount})
                                        </span>
                                      )}
                                    </>
                                  ) : getBetType === "sportBook" ? (
                                    <>
                                      {item?.teamSelectionWin &&
                                      item?.teamSelectionWin
                                        .split(",")
                                        .includes(item?.fancySelectionId) ? (
                                        <span
                                          style={{ background: "transparent" }}
                                          className="text-success"
                                        >
                                          {item?.profitAmount}
                                        </span>
                                      ) : (
                                        <span
                                          style={{ background: "transparent" }}
                                          className="text-danger"
                                        >
                                          -({item?.loseAmount})
                                        </span>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      {Math.sign(item?.realCutAmount) === -1 ? (
                                        <span
                                          style={{ background: "transparent" }}
                                          className="text-danger"
                                        >
                                          (
                                          {helpers.currencyFormat(
                                            item?.realCutAmount
                                          )}
                                          )
                                        </span>
                                      ) : (
                                        <span
                                          style={{ background: "transparent" }}
                                          className="text-success"
                                        >
                                          (
                                          {helpers.currencyFormat(
                                            Math.abs(item?.realCutAmount)
                                          )}
                                          )
                                        </span>
                                      )}
                                    </>
                                  )}
                                </td>
                              )
                            ) : (
                              <td>0.00</td>
                            )}
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={12}>
                          <span>You have no bets in this time period.</span>
                        </td>
                      </tr>
                    )}
                  </Table>
                </div>
              )}
              <div className="bottom-pagination">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel=" >"
                  forcePage={viewpage}
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={10}
                  pageCount={pageCount}
                  previousLabel="< "
                  renderOnZeroPageCount={null}
                  activeClassName="p-1"
                  activeLinkClassName="pagintion-li"
                />
              </div>
            </div>
          </div>
        </Container>
      </section>
    </div>
  );
};

export default BetList;
