import { useContext, useState } from "react";

import { Box, styled, Typography } from "@mui/material";
import { GetApp as GetAppIcon } from "@mui/icons-material";

import { downloadMedia, formatDate } from "../../../utils/common-utils";
import { iconPDF } from "../../../constants/data";
import { AccountContext } from "../../../../context/AccountProvider";
import AuthContext from "../../../../context/AuthContext";
import { FaCircleCheck } from "react-icons/fa6";
import { BsCheck, BsCheckAll } from "react-icons/bs";
import { isEmpty } from "lodash";
import { MdGetApp } from "react-icons/md";
import { saveAs } from "file-saver";
const Wrapper = styled(Box)`
  background: #ffffff;
  padding: 5px;
  max-width: 60%;
  width: fit-content;
  border-radius: 10px;
  word-break: break-word;
  display:flex;
 align-items:center;
`;

const Own = styled(Box)`
  background: #dcf8c6;
  padding: 5px;
  max-width: 60%;
  width: fit-content;
  margin-left: auto;
  border-radius: 10px;
  word-break: break-word;  
  display:flex;
  align-items:center;
`;

const Time = styled(Typography)`
  font-size: 10px;
  color: #919191;
  margin-top: 6px;
  word-break: keep-all;
  margin-top: auto;
`;

const Message = ({ message,select, setSelect }) => {
  const { user } = useContext(AuthContext);
 
  const handelSelect = (value) => {
    setSelect((prev) => {
      if (isEmpty(prev)) {
        return [value];
      } else {
        if (prev?.includes(value)) {
          return prev.filter((res) => {
            return res !== value;
          });
        } else {
          return [...prev, value];
        }
      }
    });
  };

  return (
    <>
      {message?.sender?._id == "66c0660453fe5334b07d41b6" ? (
        <Own onClick={() => handelSelect(message?._id)}>
          {select?.includes(message?._id) && (
            <FaCircleCheck
              color="#18b0c8"
              size={
                message?.fileType == "image/png" ||
                message?.fileType == "audio/mp3"
                  ? 25
                  : 16
              }
            />
          )}
          {message?.fileType?.split("/")[0] == "image" ? (
            <ImageMessage message={message} />
          ) : message?.fileType?.split("/")[0] == "audio" ? (
            <Audio message={message} />
          ) : message?.fileType?.split("/")[0] == "video" ? (
            <Video message={message} />
          ) : (
            !isEmpty(message?.message) && <TextMessage message={message} />
          )}
        </Own>
      ) : (
        <Wrapper onClick={() => handelSelect(message?._id)}>
          {select?.includes(message?._id) && (
            <FaCircleCheck
              color="#18b0c8"
              size={
                message?.fileType == "image/png" ||
                message?.fileType == "audio/mp3"
                  ? 25
                  : 16
              }
            />
          )}
          {message?.fileType?.split("/")[0] == "image" ? (
            <ImageMessage message={message} />
          ) : message?.fileType?.split("/")[0] == "audio" ? (
            <Audio message={message} />
          ) : message?.fileType?.split("/")[0] == "video" ? (
            <Video message={message} />
          ) : (
            !isEmpty(message?.message) && <TextMessage message={message} />
          )}
        </Wrapper>
      )}
    </>
  );
};
const Audio = ({ message }) => {
  return (
    <div
      style={{ marginLeft: "5px" }}
      className="d-flex flex-column align-items-end w-100"
    >
      <audio
        style={{ width: "200px" }}
        controls
        src={`${process.env.REACT_APP_API_BASE_URL}${message?.file}`}
      ></audio>
      <span
        style={{
          fontSize: "10px",
          color: "#919191",
          marginTop: "6px",
          wordBreak: "keep-all",
          display: "flex",
        }}
      >
        {formatDate(message?.createdAt || "")}
        <BsCheckAll color={message?.isRead ? "#6DAEEF" : ""} size={16} />
      </span>
    </div>
  );
};

const Video = ({ message }) => {
  return (
    <div
      style={{ marginLeft: "5px" }}
      className="d-flex flex-column align-items-end"
    >
      <video width="150" height="200" controls>
        <source
          src={`${process.env.REACT_APP_API_BASE_URL}${message?.file}`}
          type="video/mp4"
        />
      </video>
      <span
        style={{
          fontSize: "10px",
          color: "#919191",
          marginTop: "6px",
          wordBreak: "keep-all",
          display: "flex",
        }}
      >
        {formatDate(message?.createdAt || "")}
        <BsCheckAll color={message?.isRead ? "#6DAEEF" : ""} size={16} />
      </span>
    </div>
  );
};
const TextMessage = ({ message }) => {
  return (
    <>
      <span style={{ fontSize: "14px", padding: "0 15px 0 5px" }}>
        {message?.message}
      </span>
      <span
        style={{
          fontSize: "10px",
          color: "#919191",
          marginTop: "6px",
          wordBreak: "keep-all",
          marginTop: "auto",
          display: "flex",
        }}
      >
        {formatDate(message?.createdAt || "")}
        <BsCheckAll color={message?.isRead ? "#6DAEEF" : ""} size={16} />
      </span>
    </>
  );
};

const ImageMessage = ({ message }) => {
  return (
    <div style={{ position: "relative", marginLeft: "5px" }}>
      {message?.text?.includes(".pdf") ? (
        <div style={{ display: "flex" }}>
          {/* <img src={iconPDF} alt="pdf-icon" style={{ width: 80 }} /> */}
          <p style={{ fontSize: 14 }}>{message.text.split("/").pop()}</p>
        </div>
      ) : (
        <img
          style={{ width: 300, height: "100%", objectFit: "cover" }}
          src={`${process.env.REACT_APP_API_BASE_URL}${message?.file}`}
          alt={`${process.env.REACT_APP_API_BASE_URL}${message?.file}`}
        />
      )}
      <div
        style={{
          position: "absolute",
          top: "40%",
          left: "45%",
        }}
      >
        <MdGetApp
          onClick={(e) =>
            saveAs(
              `${process.env.REACT_APP_API_BASE_URL}${message?.file}`,
              "image.jpg"
            )
          }
          color="white"
          fontSize={25}
          style={{
            border: ".5px solid white",
            padding: "4px",
            borderRadius: "50%",
          }}
        />
      </div>
    </div>
  );
};

export default Message;
