import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const NoMatch = () => {
  return (
    <div id="error-fof">
     {/* <Helmet>
        <meta name="viewport" content="width=device-width,  initial-scale=.2" />
      </Helmet> */}
      <div className="fof">
        <h1>Error 404</h1> <br />
        <Link to={"/"}>Go to Home</Link>
      </div>
    </div>
  );
};

export default NoMatch;
