import React, { useContext } from "react";
import { Container } from "react-bootstrap";
import helpers from "../utils/helpers";

import AuthContext from "../context/AuthContext";

const DetailSlip = () => {
  let { detailSlipObj } = useContext(AuthContext);

  const availableBalance =
    (detailSlipObj?.totalAmount &&
      Math.abs(detailSlipObj?.totalAmount) -
        Math.abs(detailSlipObj?.playerExposure)) ||
    0;
  return (
    // <section class="total-balance-sec">
    //   <Container fluid>
    //     <ul
    //       style={{
    //         background: "#16a7c0",
    //         justifyContent: "space-between",
    //         marginBottom: "0",
    //       }}
    //       class="list-unstyled"
    //     >
    //       <li>
    //         <div style={{ marginBottom: "3px" }}>Total Balance</div>
    //         <strong>
    //           INR {helpers.currencyFormat(detailSlipObj?.totalAmount)}
    //         </strong>
    //       </li>
    //       <li>
    //         <div style={{ marginBottom: "3px" }}>Total Exposure</div>
    //         <strong>
    //           INR{" "}
    //           <span>
    //             {helpers.currencyFormat(detailSlipObj?.playerExposure)}
    //           </span>
    //         </strong>
    //       </li>
    //       <li>
    //         <div style={{ marginBottom: "3px" }}>Total Avail. bal.</div>
    //         <strong>INR {helpers.currencyFormat(availableBalance)}</strong>
    //       </li>
    //       <li>
    //         <div style={{ marginBottom: "3px" }}>Balance</div>
    //         <strong>
    //           INR {helpers.currencyFormat(detailSlipObj?.totalAmount)}
    //         </strong>
    //       </li>
    //       <li>
    //         <div style={{ marginBottom: "3px" }}>Available Balance</div>
    //         <strong>
    //           INR {helpers.currencyFormat(detailSlipObj?.totalAmount)}
    //         </strong>
    //       </li>
    //       <li>
    //         <div style={{ marginBottom: "3px" }}>Total Player Balance</div>
    //         <strong>
    //           INR {helpers.currencyFormat(detailSlipObj?.playerBalance)}
    //         </strong>
    //       </li>
    //     </ul>
    //   </Container>
    // </section>
    ''
  );
};

export default DetailSlip;
