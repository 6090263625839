import React, { useContext, useEffect, useState } from "react";
import {
  Container,
  Tabs,
  Tab,
  Table,
  Form,
  Col,
  Button,
} from "react-bootstrap";
import { Link, useLocation, useParams } from "react-router-dom";
import { apiGet, apiPut } from "../../utils/apiFetch";
import apiPath from "../../utils/apiPath";
import AuthContext from "../../context/AuthContext";
import obj from "../../utils/helpers";
import obj2 from "../../utils/constants";
import { isArray, isEmpty, startCase } from "lodash";
import moment from "moment";
import { toast } from "wc-toast";
import ReportTabel from "./ReportTabel";
import { ParticularTab } from "./ParticularTab";
const ReportType = () => {
  const location = useLocation();
  const parmas = useParams();
  const [key, setKey] = useState("CasinoGamePnL");
  var previousDate = moment().subtract(1, "days").format("YYYY-MM-DD");
  var currentDate = moment().format("YYYY-MM-DD");
  const [viewpage, setViewPage] = useState(0);
  const [filter, setFilter] = useState({
    fromPeriod: previousDate,
    toPeriod: currentDate,
  });
  const [data, setData] = useState([]);
  const getData = async (obj, key, type) => {
    let api = "";
    if (type == "aura") {
      api =
        key == "CasinoGamePnL"
          ? apiPath.reportAuraGamePl
          : key == "PlayerPnL"
          ? apiPath.reportAuraPlayerPl
          : apiPath.reportAura;
    } else if (type == "international") {
      api =
        key == "CasinoGamePnL"
          ? apiPath.reportInternationalGamePl
          : key == "PlayerPnL"
          ? apiPath.reportInternationalPlayerPl
          : apiPath.reportInternational;
    }

    const finalFilter = !isEmpty(obj) ? obj : filter;
    const { status, data: response_users } = await apiGet(api, finalFilter);
    if (status === 200) {
      if (response_users.success) {
        setData(response_users.results);
      }
    }
  };

  const handlePageClick = (event) => {
    setFilter((prevState) => {
      return {
        ...prevState,
        page: event.selected + 1,
      };
    });
    setViewPage(event.selected);
  };

  useEffect(() => {
    getData({}, key, parmas?.type);
  }, [key, parmas?.type]);

  return (
    <section className="py-4 main-inner-outer">
      <Container fluid>
        <div className="db-sec">
          <h2 className="common-heading">
            Profit Loss {startCase(parmas?.type)}
          </h2>
        </div>
        <div className="inner-wrapper">
          <div className="common-tab">
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k) => {
                setKey(k);
              }}
            >
              <Tab eventKey="Casino" title="Casino">
                {key === "Casino" && (
                  <ParticularTab
                    url={location.pathname.split("/")[1]}
                    title={key}
                    setFilter={setFilter}
                    filter={filter}
                    data={data}
                    getData={getData}
                    parmas={parmas?.type}
                    handlePageClick={handlePageClick}
                  />
                )}
              </Tab>
              <Tab eventKey="CasinoGamePnL" title="CasinoGamePnL">
                {key === "CasinoGamePnL" && (
                  <ParticularTab
                    url={location.pathname.split("/")[1]}
                    title={key}
                    setFilter={setFilter}
                    filter={filter}
                    data={data}
                    getData={getData}
                    viewpage={viewpage}
                    parmas={parmas?.type}
                    handlePageClick={handlePageClick}
                  />
                )}
              </Tab>{" "}
              <Tab eventKey="PlayerPnL" title="PlayerPnL">
                {key === "PlayerPnL" && (
                  <ParticularTab
                    url={location.pathname.split("/")[1]}
                    title={key}
                    setFilter={setFilter}
                    filter={filter}
                    data={data}
                    getData={getData}
                    parmas={parmas?.type}
                    handlePageClick={handlePageClick}
                  />
                )}
              </Tab>
            </Tabs>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default ReportType;
