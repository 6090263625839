import { styled, Box, Typography } from "@mui/material";
import { emptyProfilePicture } from "../../../constants/data";
import { formatDate } from "../../../utils/common-utils";
import obj from "../../../../utils/helpers";

const Component = styled(Box)`
  height: 45px;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  padding: 0 5px;
`;

const Image = styled("img")({
  width: 25,
  height: 25,
  objectFit: "cover",
  borderRadius: "50%",
  marginRight: "10px",
  justifyContent: "center",
  alignItems: "center",
  color: "grey",
});

const Timestamp = styled(Typography)`
  font-size: 12px;
  color: #00000099;
`;

const Conversation = ({ user, getSingleChat }) => {
  const url = user.picture || emptyProfilePicture;
  return (
    <Component onClick={() => getSingleChat(user)}>
      <Box>
        <Image src={url} alt="display picture" />
      </Box>
      <div
        className="d-flex justify-content-between align-items-center"
        style={{ width: "80%" }}
      >
        <div style={{width:"80%"}} className="d-flex flex-column align-items-start">
          <span style={{ fontSize: "14px" }}>{user?.username}</span>
          <span className="text-customer">
            {user?.lastMessage?.includes("localhost")
              ? "media"
              : user?.lastMessage}
          </span>
        </div>
        <div className="d-flex w-100 flex-column align-items-end">
          <Timestamp>{obj.matchDateTime(user?.createdAt)}</Timestamp>
          {user?.unreadCount > 0 && (
            <span
              style={{
                background: "green",
                borderRadius: "50%",
                padding: "1px 5px",
                color: "white",
                fontSize: "10px",
              }}
            >
              {user?.unreadCount}
            </span>
          )}
        </div>
      </div>
    </Component>
  );
};

export default Conversation;
