import { useState } from "react";

import { Box, InputBase, styled, Divider } from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";
import Conversation from "./Conversation";

const Component = styled(Box)`
  height: 40px;
  background: #ededed;
  display: flex;
  padding: 8px 16px;
  align-items: center;
`;

const Component2 = styled(Box)`
  background: #fff;
  height: 45px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f2f2f2;
`;

const Wrapper = styled(Box)`
  position: relative;
  border-radius: 10px;
  background-color: #f0f2f5;
  margin: 0 13px;
  width: 100%;
`;

const Icon = styled(Box)`
  color: #919191;
  padding: 5px;
  height: 100%;
  position: absolute;
`;

const InputField = styled(InputBase)`
  width: 100%;
  padding: 16px;
  padding-left: 35px;
  font-size: 14px;
  height: 15px;
  width: 100%;
`;
const Component3 = styled(Box)`
  overflow: overlay;
  height: 81vh;
`;
const StyledDivider = styled(Divider)`
  margin: 0 0 0 70px;
  background-color: #e9edef;
  opacity: 0.6;
`;

const Menu = ({ getSingleChat, list, setFiltered, orginal }) => {
  const [text, setText] = useState("");

  return (
    <Box>
      <Component>
        <span style={{ fontSize: "14px", fontWeight: "600" }}>
          {" "}
          Customer Support
        </span>
      </Component>
      {/* <InfoDrawer open={openDrawer} setOpen={setOpenDrawer} profile={true} /> */}
      <Component2>
        <Wrapper>
          <Icon>
            <SearchIcon fontSize="small" />
          </Icon>
          <InputField
            placeholder="Search or start new chat"
            inputProps={{ "aria-label": "search" }}
            onChange={(e) =>
              setFiltered(
                e.target.value == ""
                  ? orginal
                  : orginal.filter((id) => {
                      return id?.username?.includes(e.target.value);
                    })
              )
            }
          />
        </Wrapper>
      </Component2>
      <Component3>
        {list?.length > 0 ? (
          list?.map((user, index) => (
            <>
              <Conversation getSingleChat={getSingleChat} user={user} />
              <StyledDivider />
            </>
          ))
        ) : (
          <div className="d-flex justify-content-center align-items-center w-100 h-100">
            <h5>No User Found!</h5>
          </div>
        )}
      </Component3>
    </Box>
  );
};

export default Menu;
