import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { apiGet } from "../../utils/apiFetch";
import apiPath from "../../utils/apiPath";
import { useParams } from "react-router-dom";
import obj from "../../utils/helpers";
import Odds from "./Odds";
import FancySport from "./FancySport";
import PremiumSport from "./PremiumSport";
import SportRight from "./SportRight";
import moment from "moment";
import AllBet from "./AllBet";
import ProfitLossSport from "./ProfitLossSport";
import DetailSlip from "../../components/DetailSlip";
import { isEmpty, inRange } from "lodash";
import { io } from "socket.io-client";
import OddsMulti from "./OddsMulti";
import AuthContext from "../../context/AuthContext";
import BattingIframe from "./BettingIframe";
const SportAnalytics = () => {
  const params = useParams();
  const [detail, setDetail] = useState({});
  const [runner, setRunner] = useState([]);
  const [totalMatched, setTotalMatched] = useState(0);
  const [runnerBackOdds, setRunnerBackOdds] = useState([]);
  const [runnerLayOdds, setRunnerLayOdds] = useState([]);
  const [premiumFancy, setPremiumFancy] = useState([]);
  const [fancyList, setFancyList] = useState([]);
  const [betList, setBetList] = useState([]);
  const [allBet, setAllBet] = useState(false);
  const [pl, setPl] = useState(false);
  const [bookmaker, setBookmaker] = useState([]);
  const [premiumFancyPosition, setPremiumFancyPosition] = useState([]);
  const [bookmakerBackOdds, setBookmakerBackOdds] = useState([]);
  const [bookmakerLayOdds, setBookmakerLayOdds] = useState([]);
  const [betFairMs, setBetFairMs] = useState(1);
  const [socketObj, setSocketObj] = useState(null);
  const [checkRefresh, setCheckRefresh] = useState(false);
  const { user } = useContext(AuthContext);
  const [minMax, setMinMax] = useState({});
  const [marketNew, setMarketNew] = useState([]);
  const [tossData, setTossData] = useState([]);
  const [marketId, setMarketId] = useState([]);
  const [betFairPosition, setBetFairPosition] = useState([]);
  const [filter, setFilter] = useState({
    betType: "all",
  });
  const [active, setActive] = useState({
    matchOdds: true,
    toss: true,
    bookmaker: true,
    fancy: true,
  });

  const getData = async () => {
    const { status, data: response_users } = await apiGet(apiPath.matchDetail, {
      eventId: params?.eventId,
    });
    if (status === 200) {
      if (response_users.success) {
        setDetail(response_users.results);
        setMinMax({
          matchOdds: response_users.results?.matchSetting?.find((res) => {
            return res?.type == "betFaire";
          }),
          fancy: response_users.results?.matchSetting?.find((res) => {
            return res?.type == "fancy";
          }),
          bookmaker: response_users.results?.matchSetting?.find((res) => {
            return res?.type == "bookmaker";
          }),
        });
        if (response_users?.results?.gameType == "soccer") {
          let latest = response_users?.results?.marketList?.filter((res) => {
            return res?.market !== "Match Odds" && res?.jsonData?.length > 0;
          });
          setMarketNew(latest || []);
          let updated = latest?.map((res) => {
            return res?.marketId;
          });
          if (updated?.length > 0) {
            setMarketId(updated);
          }
          setRunner(
            response_users?.results?.marketList?.filter((res) => {
              return res?.marketId == params?.marketId;
            })[0]?.jsonData || []
          );
        } else {
          setRunner(response_users?.results?.jsonData || []);
        }
        setCheckRefresh(true);
        if (response_users?.results?.jsonData?.length == 3) {
          setTossData(response_users?.results?.jsonData.slice(0, 2) || []);
        } else {
          setTossData(response_users?.results?.jsonData || []);
        }
        setBookmaker(response_users.results?.jsonData);
      }
    }
  };

  const getFancyData = async () => {
    const { status, data: message } = await apiGet(
      apiPath.getFancyOdds + `?eventId=${params.eventId}`
    );
    if (status === 200) {
      if (message.success) {
          setFancyList(message?.results);
          let timeOut = setTimeout(() => {
            getMatchBetPosition();
          }, 1500);
          setTimeout(timeOut);
      }
    }
  };
  const getMatchBetPosition = async () => {
    const { status, data: response_users } = await apiGet(
      apiPath.getMatchDetailPosition + params?.eventId
    );
    if (status === 200) {
      if (response_users.success) {
        setBetFairPosition(response_users?.results);
        setRunner((prev) => {
          return prev.map((res) => {
            return {
              ...res,
              position:
                response_users?.results?.betFair?.length > 0
                  ? response_users?.results?.betFair?.find((item) => {
                      return item?.selectionId == res?.SelectionId;
                    })?.position
                  : 0,
            };
          });
        });
        setBookmaker((prev) => {
          return prev.map((res) => {
            return {
              ...res,
              position:
                response_users?.results?.bookmaker?.length > 0
                  ? response_users?.results?.bookmaker?.find((item) => {
                      return item?.selectionId == `B${res?.SelectionId}`;
                    })?.position
                  : 0,
            };
          });
        });
        setFancyList((prev) => {
          return prev?.length > 0
            ? prev?.map((res) => {
                let obj = response_users?.results?.fancyAndPremium?.find(
                  (item) => {
                    return (
                      item.selectionId == res.selectionId &&
                      item.fancyName == res.name
                    );
                  }
                );
                return {
                  ...res,
                  position: !isEmpty(obj) ? obj?.position : "",
                };
              })
            : [];
        });
        setPremiumFancy((current) => {
          let updatedArray = current;
          return updatedArray?.map((res) => {
            let obj = response_users?.results?.fancyAndPremium?.filter(
              (item) => {
                return item?.fancyName == res?.marketName;
              }
            );
            if (obj?.length > 0 && obj[0]?.fancyName == res?.marketName) {
              return {
                ...res,
                sportsBookSelection: res?.sportsBookSelection?.map((elem) => {
                  let temp = obj?.find(
                    (item) => elem?.id == item?.fancySelectionId
                  );
                  return {
                    ...elem,
                    position: temp?.position,
                  };
                }),
              };
            } else {
              return res;
            }
          });
        });
        setPremiumFancyPosition(response_users?.results?.fancyAndPremium);
      }
    }
  };

  const getMatchOdds = async () => {
    const { status, data: response_users } = await apiGet(
      apiPath.getMatchOdds + `?marketId=${params?.marketId}`
    );
    if (status === 200) {
      if (response_users.success) {
        const socket_data = response_users?.results;
        if (
          socket_data &&
          socket_data?.eventId &&
          params?.eventId == socket_data?.eventId
        ) {
          if (socket_data.rt?.length) {
            const back_odds = socket_data.rt.filter((rt) => rt.ib) || [];
            const lay_odds = socket_data.rt.filter((rt) => !rt.ib) || [];
            setRunnerBackOdds(back_odds);
            setRunnerLayOdds(lay_odds);
            setTotalMatched(socket_data?.totalMatched);
          } else {
            setRunnerBackOdds([]);
            setRunnerLayOdds([]);
            setTotalMatched(3);
          }
        } else {
          setRunnerBackOdds([]);
          setRunnerLayOdds([]);
          setTotalMatched(3);
        }
      }
    }
  };
  const getBetList = async () => {
    const { status, data: response_users } = await apiGet(apiPath.eventsBets, {
      eventId: params?.eventId,
    });
    if (status === 200) {
      if (response_users.success) {
        setBetList({
          ...response_users?.results,
          allBet: [
            ...(response_users?.results?.BetFairBet?.matched || []),
            ...(response_users?.results?.BookmakerBet || []),
            ...(response_users?.results?.FancyBet || []),
            ...(response_users?.results?.SportBooksBet || []),
          ].sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)),
        });
      }
    }
  };

  useEffect(() => {
    const intervalID = setInterval(() => {
      getBetList();
    }, 15 * 1000);

    return () => clearInterval(intervalID);
  }, [params]);

  const getMatchOddsMulti = async (id) => {
    const { status, data: response_users } = await apiGet(
      apiPath.getMatchOdds +
        `?marketId=${id?.join(",")}&multi=true&eventId=${params?.eventId}`
    );
    if (status === 200) {
      if (response_users.success) {
        const socket_data = response_users?.results;
        setMarketNew((prev) => {
          return prev?.map((res) => {
            let temp = socket_data?.find((temp) => {
              return temp?.mi == res?.marketId;
            });
            if (temp?.mi == res?.marketId) {
              return {
                ...res,
                odds: {
                  mi: temp?.mi,
                  ms: temp?.ms,
                  totalMatched: temp?.totalMatched,
                  ts: temp?.ts,
                  rt: temp?.rt,
                },
              };
            } else {
              return res;
            }
          });
        });
      }
    }
  };


  const prevItemIdRef = useRef();
  useEffect(() => {
    if (params?.eventId !== prevItemIdRef?.current?.eventId) {
      prevItemIdRef.current = params;
      getData();
      getBetList();
      getFancyData();
    }
  }, [prevItemIdRef, params]);

  useEffect(() => {
    if (detail?.marketId) {
      getMatchOdds(detail?.marketId);
    }
  }, [detail?.marketId]);

  useEffect(() => {
    if (marketId?.length > 0) {
      let interval = setInterval(() => {
        getMatchOddsMulti(marketId);
      }, 2000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [marketId]);


  const [topTab, setTopTab] = useState("scoreboard");
  const [score, setScore] = useState("");
  const getScore = (message) => {
    let updated = JSON.parse(message);
    if (updated?.length > 0) {
      setScore(updated?.find((res) => res?.eventId == detail?.eventId)?.score);
    }
  };

  const getSocket = () => {
    if (detail?.eventId && checkRefresh) {
      if (!isEmpty(socketObj)) {
        socketObj.disconnect();
      }
      setCheckRefresh(false);
      let randomId = isEmpty(user) ? 112233 : user?._id;
      let newSocket = io(
        `${
          detail?.socketUrl ? detail?.socketUrl : process.env.REACT_APP_SOCKET
        }?token=${randomId}&userType=front&eventId=${detail?.eventId}`,
        {
          transports: ["websocket"],
          forceNew: true,
        }
      );

      setSocketObj(newSocket);
      newSocket.on("listenDiamondFancy", listenDiamondFancy);
      newSocket.on("listenBetFairOdds", listenBetfairOdds);
      newSocket.on("listenBookmakerOdds", listenBookmakerData);
      newSocket.on("scoreAll", getScore);
      newSocket.on("disconnect", function () {
        setCheckRefresh(true);
      });
      return () => socketObj.disconnect();
    }
  };

  useEffect(() => {
    if (checkRefresh && detail?.eventId) {
      getSocket();
    }
  }, [detail?.eventId, checkRefresh]);

  const listenBetfairOdds = (event) => {
    const socket_data =
      (event &&
        event?.results?.find((item) => item.eventId == params?.eventId)) ||
      [];
    if (
      socket_data &&
      socket_data?.eventId &&
      params?.eventId == socket_data?.eventId &&
      params?.marketId == socket_data?.mi
    ) {
      if (socket_data.ms) {
        setBetFairMs(socket_data.ms);
      }
      if (socket_data.rt?.length) {
        const back_odds = socket_data.rt.filter((rt) => rt.ib) || [];
        const lay_odds = socket_data.rt.filter((rt) => !rt.ib) || [];
        setRunnerBackOdds(back_odds);
        setRunnerLayOdds(lay_odds);
        setTotalMatched(socket_data?.totalMatched);
      }
    }
  };

  const checkLayOdds = (rt) => {
    if (inRange(rt, 0, 10)) {
      return Number(rt + 0.5);
    } else if (inRange(rt, 10, 30)) {
      return Number(rt + 1);
    } else if (inRange(rt, 30, 50)) {
      return Number(rt + 1.5);
    } else if (inRange(rt, 50, 99)) {
      return Number(rt + 2);
    } else {
      return rt;
    }
  };

  const listenBookmakerData = (message) => {
    if (message.eventId === params?.eventId) {
      const back_odds = message?.results?.rt?.filter((rt) => rt.ib) || [];
      const newLayOdds = message?.results?.rt?.filter((rt) => !rt.ib) || [];
      let lay_odds = [];
      if (back_odds?.length > 0 && newLayOdds?.length > 0) {
        lay_odds = back_odds?.map((res) => {
          let obj = newLayOdds?.find((item) => {
            return item?.runnerName == res?.runnerName;
          });
          if (obj?.runnerName == res?.runnerName && obj?.rt == res?.rt) {
            return obj;
          } else {
            return {
              ...res,
              ib: false,
              rt: checkLayOdds(Number(res?.rt)),
            };
          }
        });
      } else {
        lay_odds = newLayOdds;
      }
      setBookmakerLayOdds(lay_odds);
      setBookmakerBackOdds(back_odds);
    }
  };

  const listenDiamondFancy = (message) => {
    if (message?.eventId === params?.eventId) {
      if (message?.results?.length > 0) {
        setFancyList((prev) => {
          return message?.results?.map((res) => {
            let obj = prev?.find((item) => item.selectionId == res.selectionId);
            return {
              ...res,
              odds: res,
              fancyName: res.name,
              position: !isEmpty(obj) ? obj?.position : "",
              newPosition:
                Math.abs(obj?.newPosition) > 0 ? obj?.newPosition : "",
            };
          });
        });
      }
    }
  };

  document.addEventListener("visibilitychange", function () {
    if (!document.hidden && !checkRefresh) {
      setCheckRefresh(true);
      if (!isEmpty(socketObj)) {
        socketObj.disconnect();
      }
    } else {
      setCheckRefresh(false);
    }
  });

  const display = () => {
    if (detail?.gameType == "soccer" && marketNew?.length > 0) {
      return detail?.eventName?.includes(" SRL ") ||
        detail?.eventName?.includes(" T10 ")
        ? ""
        : runner?.length > 0 &&
            detail?.matchOdds == "on" &&
            marketNew?.map((res, index) => {
              return (
                <OddsMulti
                  parmas={params}
                  res={res}
                  betFairMs={!isEmpty(res?.odds) ? res?.odds?.ms : 3}
                  detail={detail}
                  market={marketNew}
                  backOdds={
                    !isEmpty(res?.odds)
                      ? res?.odds?.rt?.filter((rt) => rt.ib) || []
                      : []
                  }
                  layOdds={
                    !isEmpty(res?.odds)
                      ? res?.odds?.rt?.filter((rt) => rt.ib) || []
                      : []
                  }
                  data={res?.jsonData}
                  type={res?.market}
                  active={active?.matchOdds}
                  activeInner={"multi"}
                  setActive={setActive}
                  minMax={minMax?.matchOdds || {}}
                />
              );
            });
    }
  };

  return (
    <div>
      {/* <DetailSlip /> */}
      <div class="wrapper">
        <div class="container-fluid container-padding-0">
          <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 sport-anaylsis">
              <div class="main-box mainbox-mobile">
                <div class="row analysis-running-market mt-1">
                  <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-7 sport-anaylsis-inner">
                    <div class="in-play-left pb-0 mb-0 p-0">
                      <div class="main-in-play">
                        <div class="top grd-background toppadding">
                          <h4 class="w-100">
                            <span>
                              {detail?.eventName} - {detail?.seriesName}
                            </span>
                            <span class="float-right">
                              {obj?.dateFormat(detail?.eventDateTime)}
                            </span>
                          </h4>
                        </div>
                        <div class="livetv mt-1"></div>
                      </div>
                    </div>
                    <div>
                      {!isEmpty(user) && detail?.status == "in_play" && (
                        <div class="topnav">
                          <p
                            onClick={() => setTopTab("live")}
                            class={topTab === "live" ? "active" : ""}
                          >
                            Live
                          </p>
                          <p
                            onClick={() => setTopTab("scoreboard")}
                            class={topTab === "scoreboard" ? "active" : ""}
                          >
                            ScoreBoard
                          </p>
                        </div>
                      )}
                      {isEmpty(user) &&
                        !isEmpty(detail.scoreId) &&
                        detail?.status == "in_play" && (
                          <BattingIframe detail={detail} />
                        )}
                      {!isEmpty(user) &&
                      detail?.status == "in_play" &&
                      !isEmpty(detail?.channel) ? (
                        topTab === "live" ? (
                          <iframe
                            title="Live TV Player"
                            // onClick={() => setControls(true)}
                            src={`https://5por-tt1.top/getscore.php?chid=${detail?.channel}`}
                            id="ContentPlaceHolder1_myFrame1"
                            style={{ width: "100%", height: "230px" }}
                            class="sc-dUjcNx eLUYUT"
                          />
                        ) : (
                          <BattingIframe detail={detail} />
                        )
                      ) : (
                        ""
                      )}
                    </div>
                    {runner?.length > 0 && detail?.matchOdds == "on" && (
                      <Odds
                        data={runner}
                        backOdds={runnerBackOdds}
                        layOdds={runnerLayOdds}
                        type={"Match Odds"}
                        active={active?.matchOdds}
                        activeInner={"matchOdds"}
                        setActive={setActive}
                        minMax={minMax?.matchOdds || {}}
                      />
                    )}
                    {bookmaker?.length > 0 &&
                      detail?.hasBookmaker &&
                      detail?.bookMaker == "on" && (
                        <Odds
                          data={bookmaker}
                          backOdds={bookmakerBackOdds}
                          layOdds={bookmakerLayOdds}
                          type={"Bookmaker"}
                          active={active?.bookmaker}
                          activeInner={"bookmaker"}
                          setActive={setActive}
                          minMax={minMax?.bookmaker || {}}
                        />
                      )}
                    {display()}
                    {moment(new Date()).isBefore(
                      moment(detail.eventDateTime).subtract(40, "m").toDate()
                    ) &&
                      detail?.isTossEnable &&
                      runner?.length > 0 &&
                      runnerBackOdds?.length > 0 &&
                      detail?.gameType == "cricket" && (
                        <Odds
                          data={tossData}
                          backOdds={[]}
                          layOdds={[]}
                          type={"Toss"}
                          active={active?.toss}
                          activeInner={"toss"}
                          setActive={setActive}
                          minMax={minMax?.matchOdds || {}}
                        />
                      )}

                    {/*  {runner?.length > 0 && runnerBackOdds?.length > 0 && (
                      <Odds
                        data={runner}
                        backOdds={runnerBackOdds}
                        layOdds={runnerLayOdds}
                        type={"Bookmaker"}
                        active={active?.bookmaker}
                        activeInner={"bookmaker"}
                        setActive={setActive}
                      />
                    )} */}
                    {detail?.eventType == 4 &&
                      fancyList?.length > 0 &&
                      detail?.fancy == "on" && (
                        <FancySport
                          active={active}
                          setActive={setActive}
                          data={fancyList || []}
                          minMax={minMax?.fancy || {}}
                        />
                      )}
                    {/* {premiumFancy?.length > 0 && (
                      <PremiumSport
                        data={premiumFancy || []}
                        handelPremiumFancy={handelPremiumFancy}
                      />
                    )} */}
                  </div>
                  <SportRight
                    data={betList || []}
                    setPl={setPl}
                    setAllBet={setAllBet}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {allBet && (
        <AllBet
          open={allBet}
          onClose={() => setAllBet(false)}
          data={betList || []}
          filter={filter}
          setFilter={setFilter}
        />
      )}
      {pl && (
        <ProfitLossSport
          open={pl}
          detail={detail}
          onClose={() => setPl(false)}
          params={params}
        />
      )}
    </div>
  );
};

export default SportAnalytics;
