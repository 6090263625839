import React, { useEffect, useState, useContext } from "react";
import { Container, Row, Col, Form, Table, Button } from "react-bootstrap";
import { apiGet } from "../utils/apiFetch";
import apiPath from "../utils/apiPath";
import obj from "../utils/constants";
import helpers from "../utils/helpers";
import AuthContext from "../context/AuthContext";
import { get, isEmpty } from "lodash";
import { Helmet } from "react-helmet";
import DetailSlip from "../components/DetailSlip";
import BetListLottery from "../components/BetListLottery";
const BetListLive = () => {
  let { user } = useContext(AuthContext);
  const [unmatched, setUnmatched] = useState("");
  const [lotteryType, setLotteryType] = useState("");
  const [getBetType, setBetType] = useState("betfair");
  const [getType, setType] = useState("all");
  const [filter, setFilter] = useState({
    type: "all",
    betType: "betfair",
    sortType: "amount",
    sortOrder: "asc",
    last: 25,
    refereshTime: 15,
    status: "active",
  });
  const [data, setData] = useState([]);
  const getData = async (obj) => {
    let object = !isEmpty(obj) ? obj : filter;
    const { status, data: response_users } = await apiGet(
      apiPath.getBetslive,
      object
    );
    if (status === 200) {
      if (response_users.success) {
        setLotteryType(filter?.betType == "lottery" ? "lottery" : "");
        if (response_users?.results?.length > 0) {
          setBetType(object.betType);
          setType(object.type);
          let temp = response_users?.results?.filter((res) => !res.isMatched);
          setUnmatched(temp);
          setData(response_users?.results);
        } else {
          setData([]);
        }
      }
    }
  };
  useEffect(() => {
    if (!isEmpty(user)) {
      getData();
    }
  }, [user]);
  useEffect(() => {
    const intervalID = setInterval(() => {
      getData(filter);
    }, filter?.refereshTime * 1000);

    return () => clearInterval(intervalID);
  }, [filter]);

  return (
    <div>
      {/* <DetailSlip /> */}
      <section className="find-member-sec py-3">
        <Container fluid>
          <h4 className="page-title">Bet List Live</h4>
          <div className="inner-wrapper">
            <div className="common-container">
              <Form className="bet_status p-0 bet-list-live d-flex flex-column w-100 align-items-start">
                <div className="bet_outer betlist-n  w-100">
                  <div className="bet-sec">
                    <Form.Label>Select Sport:</Form.Label>
                    <Form.Select
                      className="small_select"
                      onChange={(e) =>
                        setFilter({ ...filter, type: e.target.value })
                      }
                      value={filter.type}
                      aria-label="Default select example"
                    >
                      <option value={"all"}>All</option>
                      {obj?.betCheckData?.map((res) => {
                        return <option value={res?.label}>{res?.value}</option>;
                      })}
                    </Form.Select>
                  </div>

                  <div className="bet-sec">
                    <Form.Label>Select Market Type:</Form.Label>
                    <Form.Select
                      className="small_select"
                      onChange={(e) =>
                        setFilter({ ...filter, betType: e.target.value })
                      }
                      value={filter.betType}
                      aria-label="Default select example"
                    >
                      {obj?.betCheckDataInner?.map((res) => {
                        return <option value={res?.label}>{res?.value}</option>;
                      })}
                    </Form.Select>
                  </div>
                  <div className="bet-sec">
                    <Form.Label>Order of display:</Form.Label>
                    <Form.Select
                      className="small_select"
                      onChange={(e) =>
                        setFilter({ ...filter, sortType: e.target.value })
                      }
                      value={filter.sortType}
                      aria-label="Default select example"
                    >
                      <option value="amount">Stake</option>
                      <option value="timeInserted">Time</option>
                    </Form.Select>
                  </div>

                  <div className="bet-sec bet-period">
                    <Form.Label>of</Form.Label>
                    <Form.Select
                      className="small_select"
                      onChange={(e) =>
                        setFilter({
                          ...filter,
                          sortOrder: e.target.value,
                        })
                      }
                      value={filter.sortOrder}
                      aria-label="Default select example"
                    >
                      <option value="asc">Ascending</option>
                      <option value="desc">Decending</option>
                    </Form.Select>
                  </div>

                  <div className="bet-sec">
                    <Form.Label>Bet Status:</Form.Label>
                    <Form.Select
                      className="small_select"
                      onChange={(e) =>
                        setFilter({ ...filter, status: e.target.value })
                      }
                      value={filter.status}
                      aria-label="Default select example"
                    >
                      <option value={"active"}>Active</option>
                      <option value={"suspend"}>Suspend</option>
                    </Form.Select>
                  </div>
                </div>
                <div className="betList-bottom">
                  <div
                    className="betlist_btn"
                    style={{
                      display: "flex",
                    }}
                  >
                    <Button
                      className="theme_dark_btn"
                      style={{ marginRight: "10px" }}
                      onClick={() => getData(filter)}
                    >
                      Search
                    </Button>
                    <Button
                      className="theme_light_btn"
                      onClick={() => {
                        setFilter({
                          type: "4",
                          betType: "betfair",
                          sortType: "amount",
                          sortOrder: "",
                          last: 25,
                          status: "active",
                        });
                        getData({
                          type: "4",
                          betType: "betfair",
                          sortType: "amount",
                          sortOrder: "",
                          last: 25,
                          status: "active",
                        });
                      }}
                    >
                      Reset
                    </Button>
                  </div>
                </div>
              </Form>

              {lotteryType == "lottery" ? (
                <BetListLottery data={data}/>
              ) : (
                <div className="responsive">
                  <Table className="all-bets-dialog-tabel">
                    <thead>
                      <tr>
                        <th scope="col">PL ID</th>
                        <th scope="col"> Bet ID</th>
                        <th scope="col">Bet placed</th>
                        <th scope="col">IP Address </th>
                        <th scope="col">Market</th>
                        <th scope="col">Selection</th>
                        <th scope="col">Type</th>
                        {filter?.betType != "casino" && (
                          <th scope="col">Odds req.</th>
                        )}

                        <th scope="col">Stake </th>

                        {filter?.betType != "casino" && (
                          <>
                            <th scope="col">Liability</th>
                            <th scope="col"> Profit/Loss</th>
                          </>
                        )}
                      </tr>
                    </thead>
                    {filter?.betType != "casino" && data?.length > 0
                      ? data?.map((item) => {
                          return (
                            <tr
                              bgcolor={
                                item?.betType == "back" || item?.type == "Yes"
                                  ? "#72BBEF"
                                  : "#FAA9BA"
                              }
                            >
                              <td>{item?.clientName || "-"}</td>
                              <td>
                                {" "}
                                {getType === "3"
                                  ? item?.casinoBetId
                                  : getBetType === "fancy"
                                  ? item?.sessionBetId
                                  : item?.matchBetId || "-"}
                              </td>
                              <td>
                                {" "}
                                {helpers.dateFormat(
                                  item?.timeInserted,
                                  user.timeZone
                                )}
                              </td>
                              <td>
                                {" "}
                                {(item?.ipAddress &&
                                  item?.ipAddress.replace("::ffff:", "")) ||
                                  "-"}
                              </td>
                              {console.log("getBetType", getBetType)}
                              <td className="text-start">
                                {getType == "all"
                                  ? "All"
                                  : obj.betCheckObj[Number(getType)]}
                                <span
                                  style={{ background: "transparent" }}
                                  className="angle_unicode"
                                >
                                  ▸
                                </span>
                                <strong style={{ background: "transparent" }}>
                                  {getType === "3"
                                    ? item?.casinoName
                                    : item?.matchName}
                                </strong>
                                <span
                                  style={{ background: "transparent" }}
                                  className="angle_unicode"
                                >
                                  ▸
                                </span>
                                {getBetType === "betfair" && "Match Odds"}{" "}
                                {getBetType === "bookmaker" && "Book Maker"}
                                {getBetType === "sportBook" && item?.fancyName}
                                {getBetType === "fancy" && item?.fancyName}{" "}
                                {getBetType === "casino" && item?.casinoType}{" "}
                              </td>
                              <td>
                                {getBetType === "fancy"
                                  ? item?.betRun + "/" + item?.bhav
                                  : getBetType === "sportBook"
                                  ? item?.runnerName
                                  : getBetType === "casino"
                                  ? item?.platformTxId
                                  : item?.teamName}

                                {/* {(getBetType === "fancy") ? item?.selectionId : item?.teamName || "-"} */}
                              </td>
                              <td> {item?.betType || item?.type} </td>
                              <td>
                                {" "}
                                {getType === "3" ? 0 : item?.bhav || "-"}{" "}
                              </td>
                              <td> {item?.amount || "-"} </td>
                              <td> {item?.loseAmount || "-"} </td>
                              <td>
                                {getType === "3" ? (
                                  Math.sign(item?.playerPL) === -1 ? (
                                    <span
                                      style={{ background: "transparent" }}
                                      className="text-danger"
                                    >
                                      ({helpers.currencyFormat(item?.playerPL)})
                                    </span>
                                  ) : (
                                    <span
                                      style={{ background: "transparent" }}
                                      className="text-success"
                                    >
                                      (
                                      {helpers.currencyFormat(
                                        Math.abs(item?.playerPL)
                                      )}
                                      )
                                    </span>
                                  )
                                ) : item?.betType == "back" ||
                                  item?.type == "Yes" ? (
                                  <span
                                    style={{ background: "transparent" }}
                                    className="text-success"
                                  >
                                    {item?.profitAmount}
                                  </span>
                                ) : (
                                  <span
                                    style={{ background: "transparent" }}
                                    className="text-danger"
                                  >
                                    -({item?.loseAmount})
                                  </span>
                                )}
                              </td>
                            </tr>
                          );
                        })
                      : null}
                    {filter?.betType == "casino" && data && data?.length > 0
                      ? data?.map((item) => {
                          return (
                            <tr
                              bgcolor={
                                item?.betType == "back" || item?.type == "Yes"
                                  ? "#72BBEF"
                                  : "#FAA9BA"
                              }
                            >
                              <td>{item?.clientName || "-"}</td>
                              <td> {item?.casinoBetId}</td>
                              <td>
                                {" "}
                                {helpers.dateFormat(
                                  item?.timeInserted,
                                  user.timeZone
                                )}
                              </td>
                              <td>
                                {" "}
                                {(item?.ipAddress &&
                                  item?.ipAddress.replace("::ffff:", "")) ||
                                  "-"}
                              </td>
                              <td className="text-start">
                                {obj.betCheckObj[Number(getType)]}
                                <span
                                  style={{ background: "transparent" }}
                                  className="angle_unicode"
                                >
                                  ▸
                                </span>
                                <strong style={{ background: "transparent" }}>
                                  {getType === "3"
                                    ? item?.casinoName
                                    : item?.matchName}
                                </strong>
                                <span
                                  style={{ background: "transparent" }}
                                  className="angle_unicode"
                                >
                                  ▸
                                </span>
                                {getBetType === "betfair" && "Match Odds"}{" "}
                                {getBetType === "Bookmaker" && "Book Maker"}
                                {getBetType === "sportBook" && "Sport Book"}
                                {getBetType === "fancy" && item?.fancyName}{" "}
                                {getBetType === "casino" && item?.casinoType}{" "}
                              </td>
                              <td> {item?.platformTxId}</td>
                              <td> {item?.gameCode} </td>
                              <td> {item?.betAmount || "-"} </td>

                              {/* <td>
                              {getType === "3" ? (
                                Math.sign(item?.playerPL) === -1 ? (
                                  <span className="text-danger">
                                    ({helpers.currencyFormat(item?.playerPL)})
                                  </span>
                                ) : (
                                  <span className="text-success">
                                    (
                                    {helpers.currencyFormat(
                                      Math.abs(item?.playerPL)
                                    )}
                                    )
                                  </span>
                                )
                              ) : item?.betType == "back" || item?.type == "Yes" ? (
                                <span className="text-success">
                                  {item?.profitAmount}
                                </span>
                              ) : (
                                <span className="text-danger">
                                  -({item?.loseAmount})
                                </span>
                              )}
                            </td> */}
                            </tr>
                          );
                        })
                      : null}
                    {isEmpty(data) && (
                      <tr>
                        <td colSpan={12}>No Record Found</td>
                      </tr>
                    )}
                  </Table>
                </div>
              )}
            </div>
            {/* </div> */}
          </div>
        </Container>
      </section>
    </div>
  );
};

export default BetListLive;
