import React, { useContext, useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Navbar,
  NavDropdown,
  Nav,
  Button,
  Offcanvas,
  Form,
} from "react-bootstrap";
import { GiHamburgerMenu } from "react-icons/gi";
import { FaArrowRightLong } from "react-icons/fa6";
import { isEmpty } from "lodash";
import { useForm } from "react-hook-form";
import io from "socket.io-client";
import AuthContext from "../context/AuthContext";
import { FaDownload, FaUpload } from "react-icons/fa";
import { BiSupport } from "react-icons/bi";
import { IoMdMore } from "react-icons/io";
import {
  Link,
  NavLink,
  useLocation,
  useParams,
  useNavigate,
} from "react-router-dom";
import helpers from "../utils/helpers";
import Dropdown from "react-bootstrap/Dropdown";
import { CiSquareMinus, CiSquarePlus, CiSquareRemove } from "react-icons/ci";
import useWindowDimensions from "./getWindowSize";
import { apiGet } from "../utils/apiFetch";
import apiPath from "../utils/apiPath";
// import HeaderLogo from "../assets/images/Logo_Transparent.png";
import Sound from "./notification.wav";
const Header = () => {
  const { register, handleSubmit } = useForm({ mode: "onChange" });
  const location = useLocation();
  const parmas = useParams();
  const navigate = useNavigate();
  const current_url = location.pathname.split("/")[1];
  let {
    user,
    logoutUser,
    user_coins,
    setUserCoins,
    getCoins,
    isRefereshLoader,
    setRefereshLoader,
    setNewSideBar,
    count,
    bellSound,
  } = useContext(AuthContext);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleFormSubmit = (form_data = {}) => {
    document.documentElement.style.setProperty("--primary", "#b36c3d");
  };
  const [socket, setSocket] = useState({});
  const [checkRefresh, setCheckRefresh] = useState(true);

  const getSocket = async () => {
    setCheckRefresh(false);
    if (!isEmpty(socket)) {
      socket.disconnect();
    }
    const newSocket = io(
      `${process.env.REACT_APP_API_BASE_URL}?token=${
        user?._id ? user?._id : 112233
      }`,
      {
        transports: ["websocket"],
      }
    );
    const coinListener = (message) => {
      setUserCoins(message.results.totalCoins);
    };
    const forceLogout = (message) => {
      const uniqueId = localStorage.getItem("uniqueId");
      if (uniqueId !== message.results.uniqueId) {
        logoutUser();
      }
    };
    setSocket(newSocket);
    newSocket.emit("getCoins", { user_id: user?._id });
    newSocket.on("listenGetCoin", coinListener);
    newSocket.on("listenForceLogout", forceLogout);
    newSocket.on("disconnect", function () {
      setCheckRefresh(true);
    });
    return () => newSocket.close();
  };

  useEffect(() => {
    if (checkRefresh) {
      getSocket();
    }
  }, [checkRefresh]);

  document.addEventListener("visibilitychange", function () {
    if (!document.hidden && !checkRefresh) {
      if (!isEmpty(socket)) {
        socket.disconnect();
      }
      setCheckRefresh(true);
    } else {
      setCheckRefresh(false);
    }
  });

  const [expanded, setExpanded] = useState(false);
  const { width } = useWindowDimensions();

  const [data, setData] = useState({
    cricket: [],
    tennis: [],
    soccer: [],
  });
  const getData = async () => {
    const { status, data: response_users } = await apiGet(apiPath.seriesList);
    if (response_users?.success) {
      if (response_users?.results?.length > 0) {
        response_users?.results?.map((res) => {
          return { ...res, expand: false };
        });
        setData({
          cricket: response_users?.results?.filter((res) => {
            return res?.gameType == "cricket";
          }),
          tennis: response_users?.results?.filter((res) => {
            return res?.gameType == "tennis";
          }),
          soccer: response_users?.results?.filter((res) => {
            return res?.gameType == "soccer";
          }),
        });
      }
    }
  };
  useEffect(() => {
    if (!isEmpty(user)) {
      getData();
    }
  }, [user]);

  useEffect(() => {
    setExpanded(false);
  }, [location]);

  useEffect(() => {
    if (bellSound) {
      let interval = setInterval(() => {
        const clickSound = new Audio(Sound);
        clickSound.play().catch(function (error) {
          console.log(
            "Chrome cannot play sound without user interaction first"
          );
        });
      }, 1500);
      return () => {
        clearInterval(interval);
      };
    }
  }, [bellSound]);

  const [HeaderLogo, setHeaderLogo] = useState(
    "../assets/images/Logo_Transparent.png"
  );

  useEffect(() => {
    // Get the domain name
    const domainName = window.location.hostname;
    // Dynamically set the logo based on the domain
    if (
      domainName.includes("anybett.live") ||
      domainName.includes("admin.anybett.live")
    ) {
      setHeaderLogo("../assets/images/anybett.png"); // Update with actual logo path
    } else if (
      domainName.includes("aaexch.live") ||
      domainName.includes("admin.aaexch.live")
    ) {
      setHeaderLogo("../assets/images/aaexch.png"); // Update with actual logo path
    } else {
      setHeaderLogo("../assets/images/Logo_Transparent.png"); // Default logo
    }
  }, []);

  return (
    <div>
      <header>
        <div className="top-header">
          <Container fluid>
            <Row className="">
              <Col
                sm={8}
                lg={2}
                xs={7}
                className="hide-sm d-flex align-items-center"
              >
                {width > 750 && (
                  <GiHamburgerMenu
                    onClick={() =>
                      setNewSideBar((prev) => {
                        return !prev;
                      })
                    }
                    size={22}
                    color="white"
                    cursor={"pointer"}
                  />
                )}
                <div className="logo">
                  <a href="/dashboard" className="d-inline-block">
                    <img
                      src={HeaderLogo}
                      alt=""
                      style={{ maxWidth: "145px" }}
                    />
                  </a>
                </div>
              </Col>
              <Col lg={9} sm={1} xs={1}>
                <Container fluid>
                  <Navbar expand="lg" expanded={expanded}>
                    <Navbar.Toggle
                      aria-controls="basic-navbar-nav"
                      onClick={() => setExpanded(expanded ? false : "expanded")}
                    />
                    <Navbar.Collapse id="basic-navbar-nav">
                      <Nav className="me-auto">
                        <Nav.Link
                          as={NavLink}
                          to="/dashboard"
                          onClick={() => setExpanded(false)}
                          className={
                            current_url === "dashboard" ? "active1" : ""
                          }
                        >
                          {" "}
                          Dashboard
                        </Nav.Link>
                        <Nav.Link
                          as={NavLink}
                          to="/"
                          onClick={() => setExpanded(false)}
                          className={current_url === "/" ? "active1" : ""}
                        >
                          {" "}
                          Clients
                        </Nav.Link>

                        <NavDropdown title="My Report" id="basic-nav-dropdown">
                          <NavDropdown.Item
                            onClick={() => setExpanded(false)}
                            as={NavLink}
                            to="/my-account-statement"
                          >
                            {" "}
                            Account Statement
                          </NavDropdown.Item>
                          <NavDropdown.Item
                            onClick={() => setExpanded(false)}
                            as={NavLink}
                            to="/AprofitDownline"
                          >
                            {" "}
                            Profit/Loss by Downline
                          </NavDropdown.Item>
                          <NavDropdown.Item
                            onClick={() => setExpanded(false)}
                            as={NavLink}
                            to="/Adownlinesportspl"
                          >
                            Match Profit Loss
                          </NavDropdown.Item>

                          <NavDropdown.Item
                            onClick={() => setExpanded(false)}
                            as={NavLink}
                            to="/AprofitMarket"
                          >
                            Profit/Loss Report by Market
                          </NavDropdown.Item>
                          <NavDropdown.Item
                            onClick={() => setExpanded(false)}
                            as={NavLink}
                            to="/Adownlinesportspl"
                          >
                            Profit/Loss Sports Wise
                          </NavDropdown.Item>
                          <NavDropdown.Item
                            onClick={() => setExpanded(false)}
                            as={NavLink}
                            to="/AprofitPlayer"
                          >
                            Profit/Loss Report by Player
                          </NavDropdown.Item>

                          <NavDropdown.Item
                            onClick={() => setExpanded(false)}
                            as={NavLink}
                            to="/AprofitCasino"
                          >
                            Casino Profit/Loss Report by Date
                          </NavDropdown.Item>

                          <NavDropdown.Item
                            onClick={() => setExpanded(false)}
                            as={NavLink}
                            to="/report/aura"
                          >
                            {" "}
                            Profit/Loss Aura Casino Bets
                          </NavDropdown.Item>
                          <NavDropdown.Item
                            onClick={() => setExpanded(false)}
                            as={NavLink}
                            to="/report/international"
                          >
                            {" "}
                            Profit/Loss International Casino Bets
                          </NavDropdown.Item>
                        </NavDropdown>

                        <Nav.Link
                          as={NavLink}
                          onClick={() => setExpanded(false)}
                          to="/Betlist"
                          className={current_url === "Betlist" ? "active1" : ""}
                        >
                          BetList
                        </Nav.Link>

                        <Nav.Link
                          as={NavLink}
                          onClick={() => setExpanded(false)}
                          to="/BetListLive"
                          className={
                            current_url === "BetListLive" ? "active1" : ""
                          }
                        >
                          BetListLive
                        </Nav.Link>

                        <Nav.Link
                          as={NavLink}
                          to="/RiskManagement"
                          onClick={() => setExpanded(false)}
                          className={
                            current_url === "RiskManagement" ? "active1" : ""
                          }
                        >
                          Risk Management
                        </Nav.Link>

                        {(user?.userType == "owner" ||
                          user?.userType == "sub_owner") && (
                          <>
                            {(user?.userType == "sub_owner" ||
                              user?.userType == "owner") && (
                              <NavDropdown
                                title="Wallet"
                                id="basic-nav-dropdown"
                              >
                                <NavDropdown.Item
                                  onClick={() => setExpanded(false)}
                                  as={NavLink}
                                  to="/wallet-deposit"
                                >
                                  {" "}
                                  Wallet Deposit
                                </NavDropdown.Item>
                                <NavDropdown.Item
                                  onClick={() => setExpanded(false)}
                                  as={NavLink}
                                  to="/wallet-withdrawal"
                                >
                                  {" "}
                                  Wallet Withdrawal
                                </NavDropdown.Item>
                                <NavDropdown.Item
                                  onClick={() => setExpanded(false)}
                                  as={NavLink}
                                  to="/deposit-history"
                                >
                                  {" "}
                                  Deposit History
                                </NavDropdown.Item>
                                <NavDropdown.Item
                                  onClick={() => setExpanded(false)}
                                  as={NavLink}
                                  to="/withdrawal-history"
                                >
                                  {" "}
                                  Withdrawal History
                                </NavDropdown.Item>
                              </NavDropdown>
                            )}

                            <NavDropdown
                              title="Commission"
                              id="basic-nav-dropdown"
                            >
                              {user?.userType == "owner" ||
                                (user?.userType == "sub_owner" && (
                                  <NavDropdown.Item
                                    onClick={() => setExpanded(false)}
                                    as={NavLink}
                                    to="/Withdraw_request"
                                  >
                                    {" "}
                                    Withdraw Request
                                  </NavDropdown.Item>
                                ))}
                              <NavDropdown.Item
                                onClick={() => setExpanded(false)}
                                as={NavLink}
                                to="/commission-report"
                              >
                                {" "}
                                Commission Report
                              </NavDropdown.Item>
                              {(user?.userType == "owner" ||
                                user?.userType == "sub_owner") && (
                                <NavDropdown.Item
                                  onClick={() => setExpanded(false)}
                                  as={NavLink}
                                  to="/agent-withdraw-history"
                                >
                                  {" "}
                                  Agent Withdraw History
                                </NavDropdown.Item>
                              )}
                              {user?.userType == "owner" ||
                                user?.userType == "sub_owner" ||
                                (user?.userType == "super_admin" && (
                                  <NavDropdown.Item
                                    onClick={() => setExpanded(false)}
                                    as={NavLink}
                                    to="/Commission_setting"
                                  >
                                    {" "}
                                    Commission Setting
                                  </NavDropdown.Item>
                                ))}
                            </NavDropdown>
                            {(user?.userType == "sub_owner" ||
                              user?.userType == "owner") && (
                              <NavDropdown
                                title="Banking"
                                id="basic-nav-dropdown"
                              >
                                <NavDropdown.Item
                                  onClick={() => setExpanded(false)}
                                  as={NavLink}
                                  to="/banking"
                                >
                                  {" "}
                                  Banking
                                </NavDropdown.Item>

                                <NavDropdown.Item
                                  onClick={() => setExpanded(false)}
                                  as={NavLink}
                                  to="/banks"
                                >
                                  {" "}
                                  Banks
                                </NavDropdown.Item>
                                <NavDropdown.Item
                                  as={NavLink}
                                  onClick={() => setExpanded(false)}
                                  to="/sms"
                                >
                                  SMS
                                </NavDropdown.Item>
                              </NavDropdown>
                            )}

                            {/* b2c Link end*/}
                            {/* {(user?.userType == "owner" ||
                              user?.userType == "sub_owner") && (
                              <Nav.Link
                                as={NavLink}
                                onClick={() => setExpanded(false)}
                                to="/LotteryResult"
                                className={
                                  current_url === "LotteryResult"
                                    ? "active1"
                                    : ""
                                }
                              >
                                Lottery Result
                              </Nav.Link>
                            )} */}
                          </>
                        )}
                        <Nav.Link
                          as={NavLink}
                          to="/general-setting"
                          onClick={() => setExpanded(false)}
                          className={
                            current_url === "general-setting" ||
                            current_url === "active-match" ||
                            current_url === "in-active-match" ||
                            current_url === "manage-links" ||
                            current_url === "WebsiteSetting"
                              ? "active1"
                              : ""
                          }
                        >
                          Setting
                        </Nav.Link>

                        <NavDropdown title="Other" id="basic-nav-dropdown">
                          {" "}
                          <NavDropdown.Item
                            onClick={() => setExpanded(false)}
                            as={NavLink}
                            to="/block-market"
                          >
                            {" "}
                            Block Market
                          </NavDropdown.Item>
                          <NavDropdown.Item
                            onClick={() => setExpanded(false)}
                            as={NavLink}
                            to="/banner-list"
                          >
                            {" "}
                            Banner Manager
                          </NavDropdown.Item>
                          <NavDropdown.Item
                            onClick={() => setExpanded(false)}
                            as={NavLink}
                            to="/casino-control"
                          >
                            {" "}
                            Casino Control
                          </NavDropdown.Item>
                          <NavDropdown.Item
                            onClick={() => setExpanded(false)}
                            as={NavLink}
                            to="/promotionalOffer"
                          >
                            {" "}
                            Promotional Offer
                          </NavDropdown.Item>
                        </NavDropdown>
                        {(user?.userType == "owner" ||
                          user?.userType == "sub_owner") && (
                          <NavDropdown title="Results" id="basic-nav-dropdown">
                            {" "}
                            <NavDropdown.Item
                              onClick={() => setExpanded(false)}
                              as={NavLink}
                              to="/fancyResult"
                            >
                              {" "}
                              Fancy Result
                            </NavDropdown.Item>
                            <NavDropdown.Item
                              onClick={() => setExpanded(false)}
                              as={NavLink}
                              to="/marketResult"
                            >
                              {" "}
                              Match Result
                            </NavDropdown.Item>
                            <NavDropdown.Item
                              onClick={() => setExpanded(false)}
                              as={NavLink}
                              to="/LotteryResult"
                            >
                              {" "}
                              Lottery Result
                            </NavDropdown.Item>
                          </NavDropdown>
                        )}
                        {width < 756 && (
                          <>
                            <NavDropdown
                              title="Cricket"
                              className="dropdown-outer-new"
                              id="basic-nav-dropdown"
                            >
                              <div className="accordion-outer-item">
                                {data?.cricket?.map((res) => {
                                  return (
                                    <>
                                      <div
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          setData((prev) => {
                                            return {
                                              ...prev,
                                              cricket: prev?.cricket?.map(
                                                (item) => {
                                                  if (item?._id == res?._id) {
                                                    return {
                                                      ...item,
                                                      expand: !res?.expand,
                                                    };
                                                  } else {
                                                    return item;
                                                  }
                                                }
                                              ),
                                            };
                                          });
                                        }}
                                      >
                                        {res?.expand ? (
                                          <CiSquareMinus
                                            cursor={"pointer"}
                                            size={16}
                                          />
                                        ) : (
                                          <CiSquarePlus
                                            cursor={"pointer"}
                                            size={16}
                                          />
                                        )}
                                        <span>{res?.seriesName}</span>
                                      </div>
                                      {res?.expand && (
                                        <div className="accordion-outer-item-inner">
                                          {res?.matchList?.map((item) => {
                                            return (
                                              <div
                                                onClick={() => {
                                                  navigate(
                                                    `/sportanalysis/${item?.eventId}/${item?.marketId}`
                                                  );
                                                  setExpanded(false);
                                                }}
                                              >
                                                <CiSquareRemove
                                                  className="accordion-outer-item-inner-icon"
                                                  cursor={"pointer"}
                                                  size={16}
                                                />
                                                <span>{item?.eventName}</span>
                                              </div>
                                            );
                                          })}
                                        </div>
                                      )}
                                    </>
                                  );
                                })}
                              </div>
                            </NavDropdown>
                            <NavDropdown
                              title="Soccer"
                              className="dropdown-outer-new"
                              id="basic-nav-dropdown"
                            >
                              <div className="accordion-outer-item">
                                {data?.soccer?.map((res) => {
                                  return (
                                    <>
                                      <div
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          setData((prev) => {
                                            return {
                                              ...prev,
                                              soccer: prev?.soccer?.map(
                                                (item) => {
                                                  if (item?._id == res?._id) {
                                                    return {
                                                      ...item,
                                                      expand: !res?.expand,
                                                    };
                                                  } else {
                                                    return item;
                                                  }
                                                }
                                              ),
                                            };
                                          });
                                        }}
                                      >
                                        {res?.expand ? (
                                          <CiSquareMinus
                                            cursor={"pointer"}
                                            size={16}
                                          />
                                        ) : (
                                          <CiSquarePlus
                                            cursor={"pointer"}
                                            size={16}
                                          />
                                        )}
                                        <span>{res?.seriesName}</span>
                                      </div>
                                      {res?.expand && (
                                        <div className="accordion-outer-item-inner">
                                          {res?.matchList?.map((item) => {
                                            return (
                                              <div
                                                onClick={() => {
                                                  navigate(
                                                    `/sportanalysis/${item?.eventId}/${item?.marketId}`
                                                  );
                                                  setExpanded(false);
                                                }}
                                              >
                                                <CiSquareRemove
                                                  className="accordion-outer-item-inner-icon"
                                                  cursor={"pointer"}
                                                  size={16}
                                                />
                                                <span>{item?.eventName}</span>
                                              </div>
                                            );
                                          })}
                                        </div>
                                      )}
                                    </>
                                  );
                                })}
                              </div>
                            </NavDropdown>
                            <NavDropdown
                              title="Tennis"
                              className="dropdown-outer-new"
                              id="basic-nav-dropdown"
                            >
                              <div className="accordion-outer-item">
                                {data?.tennis?.map((res) => {
                                  return (
                                    <>
                                      <div
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          setData((prev) => {
                                            return {
                                              ...prev,
                                              tennis: prev?.tennis?.map(
                                                (item) => {
                                                  if (item?._id == res?._id) {
                                                    return {
                                                      ...item,
                                                      expand: !res?.expand,
                                                    };
                                                  } else {
                                                    return item;
                                                  }
                                                }
                                              ),
                                            };
                                          });
                                        }}
                                      >
                                        {res?.expand ? (
                                          <CiSquareMinus
                                            cursor={"pointer"}
                                            size={16}
                                          />
                                        ) : (
                                          <CiSquarePlus
                                            cursor={"pointer"}
                                            size={16}
                                          />
                                        )}
                                        <span>{res?.seriesName}</span>
                                      </div>
                                      {res?.expand && (
                                        <div className="accordion-outer-item-inner">
                                          {res?.matchList?.map((item) => {
                                            return (
                                              <div
                                                onClick={() => {
                                                  navigate(
                                                    `/sportanalysis/${item?.eventId}/${item?.marketId}`
                                                  );
                                                  setExpanded(false);
                                                }}
                                              >
                                                <CiSquareRemove
                                                  className="accordion-outer-item-inner-icon"
                                                  cursor={"pointer"}
                                                  size={16}
                                                />
                                                <span>{item?.eventName}</span>
                                              </div>
                                            );
                                          })}
                                        </div>
                                      )}
                                    </>
                                  );
                                })}
                              </div>
                            </NavDropdown>
                          </>
                        )}
                      </Nav>
                    </Navbar.Collapse>
                  </Navbar>
                </Container>
              </Col>{" "}
              {!isEmpty(user) ? (
                <Col
                  sm={3}
                  xs={4}
                  lg={1}
                  className="d-flex align-items-center justify-content-end user-btn"
                >
                  {" "}
                  {width > 1250 && (
                    <div className="d-flex">
                      {(user?.userType == "sub_owner" ||
                        user?.userType == "owner") && (
                        <>
                          <div
                            onClick={() => navigate("/wallet-deposit")}
                            className="header-count cursor-pointer"
                            style={{ cursor: "pointer", marginRight: "5px" }}
                          >
                            <FaDownload size={14} color="white" />
                            <span>{count?.depositRequests || 0}</span>
                          </div>
                          <div
                            onClick={() => navigate("/wallet-withdrawal")}
                            className="header-count cursor-pointer"
                            style={{ cursor: "pointer" }}
                          >
                            <FaUpload size={14} color="white" />
                            <span>{count?.withdrawalRequests || 0}</span>
                          </div>

                          <div
                            onClick={() => navigate("/customer-support")}
                            className="header-count cursor-pointer"
                            style={{ cursor: "pointer" }}
                          >
                            <BiSupport color="white" size={14} />
                            <span>{count?.totalUnreadCount || 0}</span>
                          </div>
                        </>
                      )}
                    </div>
                  )}
                  {width < 1250 && (
                    <Dropdown>
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        <IoMdMore size={30} />
                      </Dropdown.Toggle>
                      <Dropdown.Menu style={{ background: "black" }}>
                        <Dropdown.Item className="d-flex justify-content-between align-items-center">
                          <>
                            <div
                              onClick={() => navigate("/wallet-deposit")}
                              className="header-count cursor-pointer"
                              style={{ cursor: "pointer", marginRight: "5px" }}
                            >
                              <FaDownload size={14} color="white" />
                              <span>{count?.depositRequests || 0}</span>
                            </div>
                            <div
                              onClick={() => navigate("/wallet-withdrawal")}
                              className="header-count cursor-pointer"
                              style={{ cursor: "pointer" }}
                            >
                              <FaUpload size={14} color="white" />
                              <span>{count?.withdrawalRequests || 0}</span>
                            </div>

                            <div
                              onClick={() => navigate("/customer-support")}
                              className="header-count cursor-pointer"
                              style={{ cursor: "pointer" }}
                            >
                              <BiSupport color="white" size={14} />
                              <span>{count?.totalUnreadCount || 0}</span>
                            </div>
                          </>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  )}
                  <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      {user?.username}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                      // style={{ borderBottom: "1px solid black" }}
                      >
                        <div className="text-sm-end text-center top-header-owner">
                          <ul className="list-unstyled mb-0">
                            {/* <li>
                              <span>{user?.userType || ""}</span>{" "}
                              <strong>{user?.username || ""}</strong>
                            </li> */}
                            {isRefereshLoader ? (
                              <li>
                                <p
                                  className="loading-bar"
                                  id="menuRefreshLoading"
                                >
                                  <span></span>
                                  <span></span>
                                  <span></span>
                                  <span></span>
                                  <span></span>
                                  <span></span>
                                </p>
                              </li>
                            ) : (
                              <li>
                                <a href="#" className="text-decoration-none">
                                  <span>Main</span>{" "}
                                  <strong>
                                    INR {helpers.currencyFormat(user_coins)}
                                  </strong>
                                </a>
                                <a
                                  href="#"
                                  className="btn"
                                  onClick={() => getCoins()}
                                >
                                  <span>
                                    <i
                                      style={{ color: "black" }}
                                      className="fas fa-redo-alt"
                                    ></i>
                                  </span>
                                </a>
                              </li>
                            )}
                          </ul>
                        </div>
                      </Dropdown.Item>
                      <Dropdown.Item className="d-flex align-items-center">
                        <FaArrowRightLong
                          style={{ marginRight: "5px" }}
                          size={20}
                        />
                        <a
                          style={{ cursor: "pointer" }}
                          as={NavLink}
                          // to="#"
                          onClick={() => navigate("/my-profile")}
                          to={"#"}
                        >
                          Profile{" "}
                        </a>
                      </Dropdown.Item>
                      <Dropdown.Item className="d-flex align-items-center">
                        <FaArrowRightLong
                          style={{ marginRight: "5px" }}
                          size={20}
                        />
                        <a
                          style={{ cursor: "pointer" }}
                          as={NavLink}
                          // to="#"
                          onClick={() =>
                            navigate("/my-profile?type=changepassword")
                          }
                          to={"#"}
                        >
                          Change Password{" "}
                        </a>
                      </Dropdown.Item>

                      <Dropdown.Item className="d-flex align-items-center">
                        {" "}
                        <FaArrowRightLong
                          style={{ marginRight: "5px" }}
                          size={20}
                        />
                        <a
                          style={{ cursor: "pointer" }}
                          as={NavLink}
                          to="#"
                          onClick={logoutUser}
                        >
                          Logout{" "}
                          {/* <i className="fa-solid fa-right-from-bracket"></i> */}
                        </a>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  {/* <div className="text-sm-end text-center top-header-owner">
                    <ul className="list-unstyled mb-0">
                      <li>
                        <span>{user?.userType || ""}</span>{" "}
                        <strong>{user?.username || ""}</strong>
                      </li>
                      {isRefereshLoader ? (
                        <li>
                          <p className="loading-bar" id="menuRefreshLoading">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                          </p>
                        </li>
                      ) : (
                        <li>
                          <a href="#" className="text-decoration-none">
                            <span>Main</span>{" "}
                            <strong>
                              INR {helpers.currencyFormat(user_coins)}
                            </strong>
                          </a>
                          <a
                            href="#"
                            className="btn"
                            onClick={() => getCoins()}
                          >
                            <span>
                              <i className="fas fa-redo-alt"></i>
                            </span>
                          </a>
                        </li>
                      )}
                    </ul>
                  </div> */}
                </Col>
              ) : null}
            </Row>
          </Container>
        </div>
      </header>

      {/* <Button
        variant="primary"
        onClick={handleShow}
        className="color-picker-btn"
      >
        <i className="fa-solid fa-gear"></i>
      </Button> */}

      <Offcanvas show={show} onHide={handleClose} placement={"end"}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Change Your Theme</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form onSubmit={handleSubmit(handleFormSubmit)}>
            <div className="d-flex align-items-center justify-content-between">
              <div className="text-center">
                <Form.Label for="primary" className="form-label">
                  Primary Color
                </Form.Label>
                <Form.Control
                  type="color"
                  className="form-control-color m-auto"
                  id="primary"
                  value="#3db39e"
                  title="Choose your color"
                  {...register("primary_color", {
                    required: "Please select color",
                  })}
                />
              </div>

              <div className="text-center">
                <Form.Label for="secondary" className="form-label">
                  Secondary Color
                </Form.Label>
                <Form.Control
                  type="color"
                  className="form-control-color m-auto"
                  id="secondary"
                  value="#060316"
                  title="Choose your color"
                  {...register("secondary_color", {
                    required: "Please select color",
                  })}
                />
              </div>
            </div>
            <button className="btn" type="submit">
              Save
            </button>
            <button className="btn" type="button">
              Reset
            </button>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default Header;
