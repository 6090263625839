import React, { useState, useEffect } from "react";
import { Form, Row, Col, Button, Modal } from "react-bootstrap";
import { toast } from "wc-toast";
import { useForm } from "react-hook-form";
import { apiPost, apiGet } from "../../utils/apiFetch";
import apiPath from "../../utils/apiPath";
import { isEmpty, pick, startCase } from "lodash";
import constants from "../../utils/constants";

const AddBank = ({ type, onClose, data, getData }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    watch,
  } = useForm({
    defaultValues: {
      type: "deposit",
    },
  });
  const [image, setImage] = useState("");
  const uploadImage = async (obj) => {
    let form = new FormData();
    form.append("TransactionFile", obj);
    const { status, data } = await apiPost(apiPath.reciptUpload, form);
    if (status == 200) {
      return data?.path;
    } else {
      return false;
    }
  };

  const onSubmit = async (request) => {
    let imageCheck = "";
    if (image !== "") {
      imageCheck = await uploadImage(image);
    }
    try {
      const { status, data: response_users } = await apiPost(
        apiPath.addEditBank,
        {
          ...request,
          bankId: type == "edit" ? data?._id : "",
          account_number:
            request?.type == "deposit" ? request?.account_number : "",
          qr_path: image !== "" ? imageCheck : "",
        }
      );
      if (status === 200) {
        if (response_users.success) {
          toast.success(response_users.message);
          getData();
          reset();
          onClose();
        } else {
          toast.error(response_users.message);
        }
      } else {
        toast.error(response_users.message);
      }
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };

  useEffect(() => {
    if (type == "edit" && !isEmpty(data)) {
      setValue("bank_name", data?.bank_name);
      setValue("account_number", data?.account_number);
      setValue("account_name", data?.account_name);
      setValue("type", data?.type);
      setValue("depositLimit", data?.depositLimit);
      setValue("discount", data?.discount);
      setValue("depositType", data?.depositType);
      setValue("upiId", data?.upiId);
      if (!isEmpty(data?.qr_path)) {
        setPreview(data?.qr_path);
      }
    }
  }, [type]);

  const [preview, setPreview] = useState("");
  // useEffect(() => {
  //   if (image !== "") {
  //     const objectUrl = URL.createObjectURL(image);
  //     setPreview(objectUrl);
  //     return () => URL.revokeObjectURL(objectUrl);
  //   }
  // }, [image]);
  return (
    <div>
      <Modal show={true} onHide={onClose} className="super-admin-modal">
        <Modal.Header closeButton className="border-0 pb-0">
          <Modal.Title className="modal-title-status">
            {type == "add" ? "Add Bank" : "Edit Bank"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="super-admin-form" onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col sm={12} className="mb-2 mb-md-3">
                <Form.Group className="row">
                  <Col md={4}>
                    <Form.Label>Bank Type</Form.Label>
                  </Col>
                  <Col md={8}>
                    <Form.Select {...register("type")}>
                      {/* <option value="withdrawal">Withdrawal</option>{" "} */}
                      <option value="deposit">Deposit</option>
                    </Form.Select>
                  </Col>
                </Form.Group>
              </Col>
              {/* <Col sm={12} className="mb-2 mb-md-3">
                <Form.Group className="row">
                  <Col md={4}>
                    <Form.Label>Bank Name</Form.Label>
                  </Col>
                  <Col md={8}>
                    <Form.Control
                      type="text"
                      placeholder="Enter Bank Name"
                      className={errors?.bank_name ? " is-invalid " : ""}
                      {...register("bank_name", {
                        required: "Please enter bank name",
                      })}
                    />
                    {errors?.bank_name && errors?.bank_name?.message && (
                      <label className="invalid-feedback text-left">
                        {errors?.bank_name?.message}
                      </label>
                    )}
                  </Col>
                </Form.Group>
              </Col>
              <Col sm={12} className="mb-2 mb-md-3">
                <Form.Group className="row">
                  <Col md={4}>
                    <Form.Label>Account Name</Form.Label>
                  </Col>
                  <Col md={8}>
                    <Form.Control
                      type="text"
                      placeholder="Enter account name"
                      className={errors?.account_name ? " is-invalid " : ""}
                      {...register("account_name", {
                        required: "Please enter account name",
                      })}
                    />
                    {errors?.account_name && errors?.account_name?.message && (
                      <label className="invalid-feedback text-left">
                        {errors?.account_name?.message}
                      </label>
                    )}
                  </Col>
                </Form.Group>
              </Col> */}
              {watch("type") == "deposit" && (
                <>
                  {" "}
                  <Col sm={12} className="mb-2 mb-md-3">
                    <Form.Group className="row">
                      <Col md={4}>
                        <Form.Label>Deposit Type</Form.Label>
                      </Col>
                      <Col md={8}>
                        <Form.Select
                          className={errors?.depositType ? " is-invalid " : ""}
                          {...register("depositType", {
                            required: "Please enter deposit type",
                          })}
                        >
                          <option value="">Select Deposit Type</option>
                          <option value="bankTransfer">Bank Transfer</option>
                          <option value="upi">UPI</option>
                          <option value="qr">QR</option>
                        </Form.Select>{" "}
                        {errors?.depositType &&
                          errors?.depositType?.message && (
                            <label className="invalid-feedback text-left">
                              {errors?.depositType?.message}
                            </label>
                          )}
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col sm={12} className="mb-2 mb-md-3">
                    <Form.Group className="row">
                      <Col md={4}>
                        <Form.Label>Account Name</Form.Label>
                      </Col>
                      <Col md={8}>
                        <Form.Control
                          type="text"
                          placeholder="Enter account name"
                          className={errors?.account_name ? " is-invalid " : ""}
                          {...register("account_name", {
                            required: "Please enter account name",
                          })}
                        />
                        {errors?.account_name &&
                          errors?.account_name?.message && (
                            <label className="invalid-feedback text-left">
                              {errors?.account_name?.message}
                            </label>
                          )}
                      </Col>
                    </Form.Group>
                  </Col>
                  {watch("depositType") == "bankTransfer" && (
                    <>
                      <Col sm={12} className="mb-2 mb-md-3">
                        <Form.Group className="row">
                          <Col md={4}>
                            <Form.Label>IFSC Code</Form.Label>
                          </Col>
                          <Col md={8}>
                            <Form.Control
                              type="text"
                              placeholder="Enter ifsc"
                              className={
                                errors?.ifsc_code ? " is-invalid " : ""
                              }
                              {...register("ifsc_code", {
                                required: "Please enter ifsc",
                              })}
                            />
                            {errors?.ifsc_code &&
                              errors?.ifsc_code?.message && (
                                <label className="invalid-feedback text-left">
                                  {errors?.ifsc_code?.message}
                                </label>
                              )}
                          </Col>
                        </Form.Group>
                      </Col>
                      <Col sm={12} className="mb-2 mb-md-3">
                        <Form.Group className="row">
                          <Col md={4}>
                            <Form.Label>Bank Name</Form.Label>
                          </Col>
                          <Col md={8}>
                            <Form.Control
                              type="text"
                              placeholder="Enter Bank Name"
                              className={
                                errors?.bank_name ? " is-invalid " : ""
                              }
                              {...register("bank_name", {
                                required: "Please enter bank name",
                              })}
                            />
                            {errors?.bank_name &&
                              errors?.bank_name?.message && (
                                <label className="invalid-feedback text-left">
                                  {errors?.bank_name?.message}
                                </label>
                              )}
                          </Col>
                        </Form.Group>
                      </Col>
                      <Col sm={12} className="mb-2 mb-md-3">
                        <Form.Group className="row">
                          <Col md={4}>
                            <Form.Label>Account Number</Form.Label>
                          </Col>
                          <Col md={8}>
                            <Form.Control
                              type="number"
                              placeholder="Enter account number"
                              className={
                                errors?.account_number ? " is-invalid " : ""
                              }
                              {...register("account_number", {
                                required: "Please enter account number",
                              })}
                            />
                            {errors?.account_number &&
                              errors?.account_number?.message && (
                                <label className="invalid-feedback text-left">
                                  {errors?.account_number?.message}
                                </label>
                              )}
                          </Col>
                        </Form.Group>
                      </Col>
                    </>
                  )}
                  {(watch("depositType") == "upi" ||
                    watch("depositType") == "qr") && (
                    <Col sm={12} className="mb-2 mb-md-3">
                      <Form.Group className="row">
                        <Col md={4}>
                          <Form.Label>UPI ID</Form.Label>
                        </Col>
                        <Col md={8}>
                          <Form.Control
                            type="text"
                            placeholder="Enter UPI Id"
                            className={errors?.upiId ? " is-invalid " : ""}
                            {...register(
                              "upiId",
                              watch("depositType") == "qr"
                                ? {
                                    required: "Please enter UPI Id",
                                  }
                                : {}
                            )}
                          />
                          {errors?.upiId && errors?.upiId?.message && (
                            <label className="invalid-feedback text-left">
                              {errors?.upiId?.message}
                            </label>
                          )}
                        </Col>
                      </Form.Group>
                    </Col>
                  )}
                  {watch("depositType") == "qr" && (
                    <Col sm={12} className="mb-2 mb-md-3">
                      <Form.Group className="row">
                        <Col md={4}>
                          <Form.Label>QR Code</Form.Label>
                        </Col>
                        <Col md={8}>
                          <Form.Control
                            type="file"
                            // placeholder="Enter UPI Id"
                            // className={errors?.qrCode ? " is-invalid " : ""}
                            // {...register("qrCode", {
                            //   required: "Please enter QR Code",
                            // })}
                            onChange={(e) => {
                              setImage(e.target.files[0]);
                              setPreview(e.target.files[0]);
                            }}
                          />
                          {/* {errors?.qrCode && errors?.qrCode?.message && (
                            <label className="invalid-feedback text-left">
                              {errors?.qrCode?.message}
                            </label>
                          )} */}
                        </Col>
                        {preview !== "" && (
                          <div
                            className="w-100 d-flex justify-content-end"
                            style={{ paddingRight: "65px" }}
                          >
                            <img
                              style={{ marginTop: "5px", width: "50%" }}
                              src={
                                typeof preview == "object"
                                  ? URL.createObjectURL(preview)
                                  : `${process.env.REACT_APP_API_BASE_URL}${preview}`
                              }
                            />
                          </div>
                        )}
                      </Form.Group>
                    </Col>
                  )}
                  <Col sm={12} className="mb-2 mb-md-3">
                    <Form.Group className="row">
                      <Col md={4}>
                        <Form.Label>Deposit Limit</Form.Label>
                      </Col>
                      <Col md={8}>
                        <Form.Control
                          type="number"
                          placeholder="Enter deposit limit"
                          className={errors?.depositLimit ? " is-invalid " : ""}
                          {...register("depositLimit", {
                            required: "Please enter Deposit Limit",
                          })}
                        />
                        {errors?.depositLimit?.message && (
                          <label className="invalid-feedback text-left">
                            {errors?.depositLimit?.message}
                          </label>
                        )}
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col sm={12} className="mb-2 mb-md-3">
                    <Form.Group className="row">
                      <Col md={4}>
                        <Form.Label>Deposit Discount</Form.Label>
                      </Col>
                      <Col md={8}>
                        <Form.Control
                          type="number"
                          max={20}
                          placeholder="Enter deposit discount"
                          className={errors?.discount ? " is-invalid " : ""}
                          {...register("discount", {
                            required: "Please enter Deposit Discount",
                          })}
                        />
                        {errors?.discount?.message && (
                          <label className="invalid-feedback text-left">
                            {errors?.discount?.message}
                          </label>
                        )}
                      </Col>
                    </Form.Group>
                  </Col>
                </>
              )}
            </Row>

            <Form.Group className="mt-3 text-center">
              <button type="submit" className="theme_dark_btn px-5">
                {type == "add" ? "Add" : "Update"}
              </button>
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AddBank;
