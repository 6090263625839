import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Col,
  Row,
  Container,
  Form,
  Table,
  Modal,
} from "react-bootstrap";
import ReactPaginate from "react-paginate";
import helpers from "../utils/helpers";
import pathObj from "../utils/apiPath";
import { apiGet, apiPost } from "../utils/apiFetch";
import AuthContext from "../context/AuthContext";
import { toast } from "wc-toast";
import DetailSlip from "../components/DetailSlip";
import { result, startCase } from "lodash";
const LotteryResult = () => {
  let { user } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const setOpenToggle2 = () => setOpen2(!open2);
  const [open2, setOpen2] = useState(false);
  const [resultType, setResultType] = useState({ eventId: "", type: "" });
  const [modelData, setModelData] = useState({});
  const [viewpage, setViewPage] = useState(0);
  const [selectedRunner, setSelectedRunner] = useState("");
  const [selectedBookMaker, setSelectedBookmaker] = useState("");
  const [selectedWinner, setSelectedWinner] = useState("");
  const [filter, setFilter] = useState({
    isDeclared: false,
    page: 1,
    limit: 10,
    keyword: "",
  });
  const [data, setData] = useState([]);
  const getData = async () => {
    const { status, data: response_users } = await apiGet(
      pathObj.resultsSetMarker,
      filter
    );
    if (status === 200) {
      if (response_users.success) {
        setData(response_users.results);
      }
    }
  };
  useEffect(() => {
    getData();
  }, []);

  const close = () => {
    setOpen(false);
    setSelectedRunner("");
    setSelectedBookmaker("");
    setModelData("");
  };
  const onSubmit = async (type) => {
    if (type == "bookmaker" || type == "runner" || type == "tie") {
      let path;
      if (type == "bookmaker") {
        path = pathObj.bookmakerResult;
      } else if (type == "tie") {
        path = pathObj.tieResult;
      } else {
        path = pathObj.betfairResult;
      }

      try {
        const { status, data: response_users } = await apiPost(path, {
          wonSelectionId:
            type == "bookmaker"
              ? selectedBookMaker
              : type == "tie"
              ? selectedWinner
              : selectedRunner,
          marketId:
            type == "bookmaker"
              ? modelData?.bookmakerMarketId
              : modelData?.marketId,
          eventId: modelData?.eventId,
        });
        if (status === 200) {
          if (response_users.success) {
            if (
              filter.status == "tie" ||
              filter.status == "abounded" ||
              filter.status == "completed"
            ) {
              await apiGet(
                pathObj.resultTieandAbonded +
                  `?eventId=${modelData.eventId}&status=active`
              );
            }
            close();
            getData();
            toast.success(response_users.message);
          } else {
            toast.error(response_users.message);
          }
        }
      } catch (err) {
        toast.error(err.response.data.message);
      }
    }
  };
  const updateMatchStatus = async () => {
    const { status, data: response_users } = await apiGet(
      pathObj.resultTieandAbonded +
        `?eventId=${resultType.eventId}&status=${resultType.type}`
    );
    if (status === 200) {
      if (response_users.success) {
        getData();
        setResultType({
          eventId: "",
          type: "",
        });
        setOpen2(false);
        toast.success(response_users.message);
      }
    }
  };
  const handelDigit = async (value, id, type) => {
    setData((prev) => {
      return {
        ...prev,
        data: prev?.data?.map((res) => {
          if (res?._id == id) {
            if (type == "roleback") {
              return {
                ...res,

                result: value,
              };
            } else {
              return {
                ...res,

                decision: value,
              };
            }
          } else {
            return res;
          }
        }),
      };
    });
  };
  const updateRoleback = (id, type) => {
    setData((prev) => {
      return {
        ...prev,
        data: prev?.data?.map((res) => {
          if (res?._id == id) {
            if (type == "single") {
              return { ...res, isSingleDigitDeclared: false };
            } else {
              return { ...res, isDoubleDigitDeclared: false };
            }
          } else {
            return res;
          }
        }),
      };
    });
  };

  const submitResult = async (item, type) => {
    let value = 0;
    if (type == "roleback") {
      value = item?.result;
    } else {
      value = item?.decision;
    }
      try {
        const { status, data: response_users } = await apiPost(
          pathObj.LotteryResult,
          {
            decision: value,
            marketId: item?.marketId,
            eventId: item?.eventId,
            id: item?._id,
          }
        );
        if (status === 200) {
          if (response_users.success) {
            getData();
            toast.success(response_users.message);
          } else {
            toast.error(response_users.message);
          }
        }
      } catch (err) {
        toast.error(err.response.data.message);
      }
 
  };

  const handlePageClick = (event) => {
    setFilter((prevState) => {
      return {
        ...prevState,
        page: event.selected + 1,
      };
    });
  };
  return (
    <div>
      {/* <DetailSlip /> */}
      <section className="main-inner-outer py-3">
        <Container fluid>
          <h4 className="page-title">Lottery Result</h4>
          <div className="inner-wrapper">
            <div className="common-container">
              <Form className="bet_status p-0 bet-list-live d-flex flex-column w-100 align-items-start">
                <div className="bet_outer betlist-n ">
                  <div className="bet-sec">
                    <Form.Label>Declared</Form.Label>
                    <Form.Select
                      className="small_select"
                      onChange={(e) =>
                        setFilter({ ...filter, isDeclared: e.target.value })
                      }
                      value={filter.isDeclared}
                      aria-label="Default select example"
                    >
                      <option value={true}>Declared</option>
                      <option value={false}>Pending</option>
                    </Form.Select>
                  </div>{" "}
                  <div className="bet-sec" style={{ flex: "none" }}>
                    <Form.Label>Event Name</Form.Label>
                    <Form.Control
                      style={{ height: "32px" }}
                      onChange={(e) =>
                        setFilter({
                          ...filter,
                          keyword: e.target.value,
                        })
                      }
                      placeholder="keyword"
                      type="text"
                      value={filter.keyword}
                      aria-label="Default select example"
                    />
                  </div>
                </div>
                <div className="history-btn ">
                  <ul className="list-unstyled mb-0">
                    <li>
                      <Button
                        className="theme_dark_btn p-1"
                        onClick={() => getData()}
                      >
                        Search
                      </Button>
                    </li>
                    <li>
                      <Button
                        className="theme_light_btn p-1"
                        onClick={() => {
                          setFilter({
                            isDeclared: false,
                            page: 1,
                            limit: 10,
                            keyword: "",
                          });
                          getData({
                            isDeclared: false,
                            page: 1,
                            limit: 10,
                            keyword: "",
                          });
                        }}
                      >
                        Reset
                      </Button>
                    </li>
                  </ul>
                </div>
              </Form>
              <div className="account-table home-table w-100">
                <div className="responsive">
                  <Table>
                    <thead>
                      <tr>
                        <th scope="col"> Match ID</th>
                        <th scope="col"> Match Name</th>
                        <th scope="col">Market Id</th>
                        <th scope="col">Lottery Type</th>
                        <th scope="col">
                          {!filter?.isDeclared ? "Declared By" : "Winner"}
                        </th>
                        <th scope="col">Date</th>
                        <th scope="col">Result</th>
                      </tr>
                    </thead>

                    <tbody>
                      {data?.data?.length > 0 ? (
                        data?.data?.map((item) => {
                          return (
                            <tr>
                              <td>{item?.eventId}</td>
                              <td>{item?.eventName}</td>
                              <td>{item?.marketId}</td>
                              <td>{startCase(item?.lotteryType)}</td>
                              {item?.isDeclared ? (
                                <td>{item?.isDeclaredBy}</td>
                              ) : (
                                <td>
                                  {item?.status === "completed"
                                    ? ""
                                    : "Not Declared"}
                                </td>
                              )}
                              <td>
                                {helpers.dateFormat(
                                  item?.eventDateTime,
                                  user.timeZone
                                )}
                              </td>
                              {/* <td>
                                <>
                                  {item?.bookmakerRunners &&
                                    item?.bookmakerRunners.length > 0 && (
                                      <Button
                                        onClick={() => {
                                          setOpen(true);
                                          setModelData({
                                            bookmaker:
                                              item?.bookmakerRunners || [],
                                            eventId: item?.eventId,
                                            marketId: item?.marketId,
                                            bookmakerMarketId:
                                              item?.bookmakerMarketId,
                                            betfairDecision:
                                              item.isBetFairDeclared,
                                            bookmakerDecision:
                                              item.isBookmakerDeclared,
                                          });
                                        }}
                                        className="green-btn"
                                      >
                                        {item.isBookmakerDeclared
                                          ? "Bookmaker Rollback"
                                          : "Bookmaker"}
                                      </Button>
                                    )}

                                  <Button
                                    disabled={
                                      (item?.bookmakerRunners &&
                                        item?.bookmakerRunners.length > 0 &&
                                        !item.isBookmakerDeclared) ||
                                      (item?.jsonTieData &&
                                        item?.jsonTieData.length > 0 &&
                                        !item.isTieDeclared)
                                        ? true
                                        : false
                                    }
                                    onClick={() => {
                                      setOpen(true);
                                      setModelData({
                                        runner: item?.runners || [],

                                        eventId: item?.eventId,
                                        marketId: item?.marketId,
                                        bookmakerMarketId:
                                          item?.bookmakerMarketId,
                                        betfairDecision: item.isBetFairDeclared,
                                        bookmakerDecision:
                                          item.isBookmakerDeclared,
                                      });
                                    }}
                                    className="green-btn"
                                  >
                                    {item.isBetFairDeclared ||
                                    item?.status === "completed"
                                      ? "Match Odds Rollback"
                                      : "Match Odds"}
                                  </Button>
                                  {item?.jsonTieData &&
                                    item?.jsonTieData.length > 0 && (
                                      <Button
                                        onClick={() => {
                                          setOpen(true);
                                          setModelData({
                                            jsonTieData:
                                              item?.jsonTieData || [],
                                            eventId: item?.eventId,
                                            marketId: item?.tieMarketId,
                                            tieWinner: item?.tieWinner,
                                          });
                                        }}
                                        className="green-btn"
                                      >
                                        {item.isTieDeclared
                                          ? "Tie Rollback"
                                          : "Match Tie"}
                                      </Button>
                                    )}

                                  {item.status !== "tie" &&
                                  item.status !== "abounded" &&
                                  item.status !== "completed" ? (
                                    <>
                                      <Button
                                        onClick={() => {
                                          setOpenToggle2();
                                          setResultType({
                                            type: "abounded",
                                            eventId: item.eventId,
                                          });
                                        }}
                                        className="green-btn"
                                      >
                                        Abounded
                                      </Button>
                                      <Button
                                        onClick={() => {
                                          setOpenToggle2();
                                          setResultType({
                                            type: "noResult",
                                            eventId: item.eventId,
                                          });
                                        }}
                                        className="green-btn"
                                      >
                                        No Result
                                      </Button>
                                      {item?.isBetFairDeclared &&
                                        item?.isBookmakerDeclared && (
                                          <Button
                                            onClick={() => {
                                              setOpenToggle2();
                                              setResultType({
                                                type: "completed",
                                                eventId: item.eventId,
                                              });
                                            }}
                                            className="green-btn"
                                          >
                                            Completed
                                          </Button>
                                        )}
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </>
                              </td> */}
                              <td>
                                {item?.isDeclared ? (
                                  <div className="d-flex">
                                    <input
                                      value={item?.result || ""}
                                      type="number"
                                      onChange={(e) => {
                                        handelDigit(
                                          e.target.value,
                                          item._id,
                                          "roleback"
                                        );
                                      }}
                                      style={{
                                        marginRight: "5px",
                                        width: "100px",
                                      }}
                                    />
                                    <Button
                                      onClick={(e) =>
                                        submitResult(item, "roleback")
                                      }
                                      className="green-btn"
                                    >
                                      Roleback
                                    </Button>
                                  </div>
                                ) : (
                                  <div className="d-flex">
                                    <input
                                      value={item?.decision || ""}
                                      type="number"
                                      onChange={(e) => {
                                        handelDigit(e.target.value, item._id);
                                      }}
                                      style={{
                                        marginRight: "5px",
                                        width: "100px",
                                      }}
                                    />
                                    <Button
                                      onClick={(e) =>
                                        submitResult(item, "result")
                                      }
                                      className="green-btn"
                                    >
                                      Submit Result
                                    </Button>
                                  </div>
                                )}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={9}>No Record Found</td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
                <div className="bottom-pagination">
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel=">"
                    forcePage={viewpage}
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={10}
                    pageCount={data?.totalPages}
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                    activeClassName="p-1"
                    activeLinkClassName="pagintion-li"
                  />
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </div>
  );
};

export default LotteryResult;
