import { Box, InputBase, styled } from "@mui/material";
import ChatHeader from "./ChatHeader";
import Message from "./Message";
import { EmojiEmotions, AttachFile, Mic } from "@mui/icons-material";
import { toast } from "wc-toast";
import { IoMdSend } from "react-icons/io";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import { useEffect, useRef } from "react";
const Wrapper = styled(Box)`
  background-image: url(${"https://user-images.githubusercontent.com/15075759/28719144-86dc0f70-73b1-11e7-911d-60d70fcded21.png"});
  background-size: 50%;
`;

const Component = styled(Box)`
  height: 83vh;
  overflow-y: scroll;
  padding-top: 10px;
`;

const Container = styled(Box)`
  padding: 1px 80px;
`;

const Container2 = styled(Box)`
  height: 55px;
  background: #ededed;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 15px;
  & > * {
    margin: 5px;
    color: #919191;
  }
`;

const Search = styled(Box)`
  border-radius: 18px;
  background-color: #ffffff;
  width: calc(94% - 100px);
`;

const InputField = styled(InputBase)`
  width: 100%;
  padding: 20px;
  padding-left: 25px;
  font-size: 14px;
  height: 20px;
  width: 100%;
`;

const ClipIcon = styled(AttachFile)`
  transform: "rotate(40deg)";
`;

const ChatBox = ({
  singleUser,
  message,
  value,
  setValue,
  uploadImage,
  select,
  setSelect,
  submit,
  addAudioElement,
  deleteChat,
  scroll,
  setScroll,
}) => {
  const messageRef = useRef(null);
  useEffect(() => {
    if (scroll) {
      setTimeout(() => {
        if (messageRef.current) {
          messageRef.current.scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest",
          });
        }
      }, 800);
      setScroll(false);
    }
  }, [scroll]);

  const recorderControls = useAudioRecorder();
  return (
    <Box style={{ height: "100%" }}>
      <ChatHeader select={select} deleteChat={deleteChat} person={singleUser} />
      <Wrapper>
        <Component>
          {message &&
            message?.map((obj) => (
              <Container ref={messageRef}>
                <Message message={obj} select={select} setSelect={setSelect} />
              </Container>
            ))}
        </Component>
        <Container2>
          {/* <EmojiEmotions /> */}
          <label htmlFor="fileInput">
            <ClipIcon />
          </label>
          <input
            type="file"
            id="fileInput"
            style={{ display: "none" }}
            onChange={(e) => {
              if (
                ["video", "audio", "image"].includes(
                  e.target.files[0]?.type?.split("/")[0]
                )
              ) {
                uploadImage(e.target.files[0], "image");
              } else {
                toast.error(
                  "Please upload only Image , Video and Audio Format"
                );
              }
            }}
          />

          <Search>
            <InputField
              placeholder="Type a message"
              inputProps={{ "aria-label": "search" }}
              onChange={(e) => setValue(e.target.value)}
              value={value}
            />
          </Search>
          <IoMdSend onClick={() => submit()} size={20} color="#919191" />
          <AudioRecorder
            onRecordingComplete={(blob) => addAudioElement(blob)}
            audioTrackConstraints={{
              noiseSuppression: true,
              echoCancellation: true,
            }}
            recorderControls={recorderControls}
            downloadFileExtension="webm"
          />
        </Container2>
      </Wrapper>
    </Box>
  );
};

export default ChatBox;
