import React, { useEffect, useState } from "react";
import DetailSlip from "../components/DetailSlip";
import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import moment from "moment";
import { Helmet } from "react-helmet";
import helpers from "../utils/helpers";
import { apiGet } from "../utils/apiFetch";
import apiPath from "../utils/apiPath";
import { Link, useNavigate } from "react-router-dom";
const Dashboard = () => {
  var previousDate = moment().subtract(1, "days").format("YYYY-MM-DD");
  var currentDate = moment().format("YYYY-MM-DD");
  const [topData, setTopdata] = useState({});
  const [filter, setFilter] = useState({
    fromPeriod: previousDate,
    toPeriod: currentDate,
  });

  const navigate = useNavigate();
  const getTopAmount = async () => {
    const { status, data: response_users } = await apiGet(apiPath.riskProfile);
    if (status === 200) {
      if (response_users.success) {
        if (response_users.results) {
          setTopdata(response_users.results);
        }
      }
    }
  };

  useEffect(() => {
    getTopAmount();
  }, []);

  useEffect(() => {
    const intervalID = setInterval(() => {
      getTopAmount();
    }, 30 * 1000);

    return () => clearInterval(intervalID);
  }, []);

  return (
    <>
      {/* <DetailSlip /> */}
      <section className="find-member-sec">
        <Container fluid>
          <h4 className="page-title">Dashboard</h4>
          <Row className="mb-md-0 mb-lg-3 mb-3 p-3 dashboard-header">
            <Col xl={2} lg={2} md={3} sm={6}>
              <div className="">
                <span>From Date :</span>
                <Form.Control
                  onChange={(e) =>
                    setFilter({
                      ...filter,
                      fromPeriod: e.target.value,
                      filterByDay: "",
                    })
                  }
                  max={new Date().toISOString().split("T")[0]}
                  value={filter.fromPeriod}
                  type="date"
                />
              </div>
            </Col>
            <Col xl={2} lg={2} md={3} sm={6}>
              <div className="">
                <span>To Date :</span>
                <Form.Control
                  onChange={(e) =>
                    setFilter({
                      ...filter,
                      fromPeriod: e.target.value,
                      filterByDay: "",
                    })
                  }
                  max={new Date().toISOString().split("T")[0]}
                  value={filter.fromPeriod}
                  type="date"
                />
              </div>
            </Col>
            <Col
              xl={2}
              lg={2}
              md={3}
              className="d-flex justify-content-start align-items-end"
            >
              <Button
                className="theme_dark_btn"
                style={{
                  marginRight: "10px",
                  border: "none",
                }}
              >
                Submit
              </Button>
              <Button className="theme_light_btn">Reset</Button>
            </Col>
          </Row>
          <Row className="mb-lg-3 mb-xl-3 mb-md-3 mb-sm-3 mb-xs-0 p-0">
            <Col
              xl={2}
              lg={2}
              md={3}
              xs={6}
              className="mb-3 mb-md-0 mb-lg-0 mb-sm-0 pe-2 pe-xs-0"
            >
              <div className="dashboard-single">
                <span>P&L</span>
                <span className="second">13086</span>
              </div>
            </Col>
            <Col
              xl={2}
              lg={2}
              md={3}
              xs={6}
              className="mb-3 mb-md-0 mb-lg-0 mb-sm-0 ps-2 ps-xs-0"
            >
              <div className="dashboard-single">
                <span>COMMISSION</span>
                <span className="second">23</span>
              </div>
            </Col>
            <Col
              xl={2}
              lg={2}
              md={3}
              xs={6}
              className="mb-3 mb-md-0 mb-lg-0 mb-sm-0 pe-2 pe-xs-0"
            >
              <div className="dashboard-single">
                <span>DEPOSIT</span>
                <span className="second">0</span>
              </div>
            </Col>
            <Col
              xl={2}
              lg={2}
              md={3}
              xs={6}
              className="mb-3 mb-md-0 mb-lg-0 mb-sm-0 ps-2 ps-xs-0"
            >
              <div className="dashboard-single">
                <span>WITHDRAWAL</span>
                <span className="second">0</span>
              </div>
            </Col>
            <Col
              xl={2}
              lg={2}
              md={3}
              xs={6}
              className="mb-3 mb-md-0 mb-lg-0 mb-sm-0 pe-2 pe-xs-0"
            >
              <div className="dashboard-single">
                <span>TOTAL BETS</span>
                <span className="second">12</span>
              </div>
            </Col>
            <Col
              xl={2}
              lg={2}
              md={3}
              xs={6}
              className="mb-3 mb-md-0 mb-lg-0 mb-sm-0 ps-2 ps-xs-0"
            >
              <div className="dashboard-single">
                <span>SPORTBOOK P&L</span>
                <span className="second">0</span>
              </div>
            </Col>
          </Row>
          <Row className="mb-lg-3 mb-xl-3 mb-md-3 mb-sm-3 mb-xs-0 p-0">
            <Col xl={3} lg={3} md={6} className="mb-3">
              <div className="dashboard-single">
                <span>Top 10 Matched Amount Player</span>
                <Table className="dashboard-single-tabel">
                  <thead>
                    <tr>
                      <th>UID</th>
                      <th>Exposure</th>
                      <th>Matched Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {topData?.matched?.slice(0, 5)?.length > 0 ? (
                      topData?.matched?.slice(0, 5)?.map((res, index) => {
                        return (
                          <tr>
                            <td>
                              {" "}
                              <Link
                                to={`/currentBets/${res._id}/${res.userType}`}
                              >
                                {index + 1} <a href="#">{res.username}</a>
                              </Link>
                            </td>
                            <td>
                              <strong className="text-danger">
                                ( {helpers.currencyFormat(res.exposure)}){" "}
                              </strong>
                            </td>
                            <td>{helpers.currencyFormat(res.totalAmount)}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={3}>No Record Found</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
                {topData?.matched?.length == 10 && (
                  <div className="w-100 d-flex justify-content-end align-items-center p-2">
                    {" "}
                    <Button
                      onClick={() => navigate("/matchedAll")}
                      className="green-btn p-1"
                    >
                      View All
                    </Button>
                  </div>
                )}
              </div>
            </Col>
            <Col xl={3} lg={3} md={6} className="mb-3">
              <div className="dashboard-single">
                <span>Top 10 Exposure Player</span>
                <Table className="dashboard-single-tabel">
                  <thead>
                    <tr>
                      <th>UID</th>
                      <th>Exposure</th>
                      <th>Matched Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {topData?.exposure?.slice(0, 5)?.length > 0 ? (
                      topData?.exposure?.slice(0, 5)?.map((res, index) => {
                        return (
                          <tr>
                            <td>
                              {" "}
                              <Link
                                to={`/currentBets/${res._id}/${res.userType}`}
                              >
                                {index + 1} <a href="#">{res.username}</a>
                              </Link>
                            </td>
                            <td>
                              <strong className="text-danger">
                                ( {helpers.currencyFormat(res.exposure)}){" "}
                              </strong>
                            </td>
                            <td>{helpers.currencyFormat(res.totalAmount)}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={3}>No Record Found</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
                {topData?.matched?.length == 10 && (
                  <div className="w-100 d-flex justify-content-end align-items-center p-2">
                    {" "}
                    <Button
                      onClick={() => navigate("/matchedAll")}
                      className="green-btn p-1"
                    >
                      View All
                    </Button>
                  </div>
                )}
              </div>
            </Col>

            <Col xl={3} lg={3} md={6} className="mb-3 mb-md-0 mb-lg-0 mb-sm-0">
              <div className="dashboard-single">
                <span>TOP 5 WINNING PLAYER</span>
                <Table className="dashboard-single-tabel">
                  <thead>
                    <tr>
                      <th scope="col">Player</th>
                      <th scope="col" className="text-end">
                        Amount
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>sixexch</td>
                      <td className="text-end">0</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Col>
            <Col xl={3} lg={3} md={6} className="mb-3 mb-md-0 mb-lg-0 mb-sm-0">
              <div className="dashboard-single">
                <span>TOP 5 LOSING PLAYER</span>
                <Table className="dashboard-single-tabel">
                  <thead>
                    <tr>
                      <th scope="col">Player</th>
                      <th scope="col" className="text-end">
                        Amount
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>sixexch</td>
                      <td className="text-end">0</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Col>
            <Col xl={3} lg={3} md={6} className="mb-3 mb-md-0 mb-lg-0 mb-sm-0">
              <div className="dashboard-single">
                <span>TOP 5 WINNING MARKETS</span>
                <Table className="dashboard-single-tabel">
                  <thead>
                    <tr>
                      <th scope="col">Player</th>
                      <th scope="col" className="text-end">
                        Amount
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>sixexch</td>
                      <td className="text-end">0</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Col>
            <Col xl={3} lg={3} md={6} className="mb-3 mb-md-0 mb-lg-0 mb-sm-0">
              <div className="dashboard-single">
                <span>TOP 5 LOSING MARKET</span>
                <Table className="dashboard-single-tabel">
                  <thead>
                    <tr>
                      <th scope="col">Player</th>
                      <th scope="col" className="text-end">
                        Amount
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>sixexch</td>
                      <td className="text-end">0</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
          <Row className="mb-md-0 mb-lg-3 mb-0 p-0">
            <Col xl={3} lg={3} md={6} className="mb-3 mb-md-0 mb-lg-0 mb-sm-0">
              <div className="dashboard-single">
                <span>USER COUNT</span>
                <Table className="dashboard-single-tabel">
                  <thead>
                    <tr>
                      <th scope="col">Role</th>
                      <th scope="col" className="text-end">
                        Count
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>sixexch</td>
                      <td className="text-end">0</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Col>
            <Col xl={3} lg={3} md={6} className="mb-3 mb-md-0 mb-lg-0 mb-sm-0">
              <div className="dashboard-single">
                <span>SPORTS GAMEPLAY DETAILS</span>
                <Table className="dashboard-single-tabel">
                  <thead>
                    <tr>
                      <th scope="col">Player</th>
                      <th scope="col" className="text-end">
                        Amount
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>sixexch</td>
                      <td className="text-end">0</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Col>
            <Col xl={3} lg={3} md={6} className="mb-3 mb-md-0 mb-lg-0 mb-sm-0">
              <div className="dashboard-single">
                <span>OTHERS GAMEPLAY DETAILS</span>
                <Table className="dashboard-single-tabel">
                  <thead>
                    <tr>
                      <th scope="col">Player</th>
                      <th scope="col" className="text-end">
                        Amount
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>sixexch</td>
                      <td className="text-end">0</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Col>
            <Col xl={3} lg={3} md={6} className="mb-3 mb-md-0 mb-lg-0 mb-sm-0">
              <div className="dashboard-single">
                <span>CASINO GAMEPLAY DETAILS</span>
                <Table className="dashboard-single-tabel">
                  <thead>
                    <tr>
                      <th scope="col">Player</th>
                      <th scope="col" className="text-end">
                        Amount
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>sixexch</td>
                      <td className="text-end">0</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Dashboard;
