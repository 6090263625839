import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { FaArrowRight } from "react-icons/fa";
import BookFancy from "./BookFancy";
const FancySport = ({ data, active, setActive, minMax }) => {
  const [bookPositionData, setBookPositionData] = useState({});
  return (
    <div class="row mt-3">
      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div class="main-analysis mb-2">
          <div class="fancybtn-new">
            <a>Fancy </a>
          </div>
          <div class="w-100 d-block fancybg">
            <ul class="fancy-filter">
              <li>
                <a class="active">All</a>
              </li>
            </ul>
            <span
              class="fancyplus-icon"
              style={{ paddingLeft: "5px", cursor: "pointer" }}
            >
              {active?.fancy ? (
                <i
                  onClick={() =>
                    setActive((prev) => {
                      return {
                        ...prev,
                        fancy: !prev.fancy,
                      };
                    })
                  }
                  class="fa fa-minus"
                  aria-hidden="true"
                ></i>
              ) : (
                <i
                  onClick={() =>
                    setActive((prev) => {
                      return {
                        ...prev,
                        fancy: !prev.fancy,
                      };
                    })
                  }
                  class="fa fa-plus"
                  aria-hidden="true"
                ></i>
              )}
            </span>
          </div>

          {active?.fancy && (
            <div class="table-responsive  game-wrap">
              <table class="w-100 analysis-running-market bookmakerfancy fancytable">
                <tbody>
                  <tr class="bet-all-new">
                    <td class="fancyw-65 pb-0">&nbsp;</td>
                    <td class="fancyw-20 pb-0 text-center">
                      <div>
                        <div class="fantitle d-inline">
                          <a id="btnBack" class="btn-back bg-trans" side="Back">
                            <span class="ask-price">No</span>
                          </a>
                        </div>
                        <div class="fantitle d-inline">
                          <a id="btnLay" class="btn-lay bg-trans" side="Lay">
                            <span class="ask-price">Yes</span>
                          </a>
                        </div>
                      </div>
                    </td>
                    <td class="fancyw-15 pb-0 p-relative mobile-hide">
                      &nbsp;
                    </td>
                  </tr>
                  {data?.map((res) => {
                    return (
                      <tr>
                        <td class="fancyw-50 px-1">
                          <div class="runningf in-play-title ">
                            <div class="d-block">
                              <span class="marketnamemobile">
                                {res?.fancyName}
                              </span>
                            </div>
                            {Math.abs(res?.position) > 0 && (
                              <div className="d-flex mt-1">
                                <div
                                  style={{
                                    fontWeight: "600",
                                    fontSize: "11px",
                                  }}
                                  class={
                                    res?.position <= 0
                                      ? "text-danger d-flex align-items-center"
                                      : "text-success  d-flex align-items-center"
                                  }
                                >
                                  <FaArrowRight
                                    style={{
                                      marginRight: "3px",
                                    }}
                                    class={
                                      res?.position <= 0
                                        ? "text-danger"
                                        : "text-success"
                                    }
                                  />{" "}
                                  {parseFloat(Math.abs(res?.position)).toFixed(
                                    2
                                  )}
                                </div>
                              </div>
                            )}
                          </div>{" "}
                          {Math.abs(res?.position) > 0 && (
                            <Button
                              className="btn-book-new"
                              onClick={() => {
                                setBookPositionData({
                                  status: true,
                                  item: res,
                                });
                              }}
                            >
                              Book
                            </Button>
                          )}
                        </td>
                        <td class="w-16">
                          <div
                            class={
                              res?.odds?.ms == 9 ||
                              res?.odds?.ms == 2 ||
                              res?.odds?.ms == 3 ||
                              res?.odds?.ms == 4
                                ? "ballrunning-fancy"
                                : ""
                            }
                            datascustomattribute={
                              res?.odds?.ms == 9
                                ? "Ball Running"
                                : res?.odds?.ms == 2
                                ? "In Active"
                                : res?.odds?.ms == 3
                                ? "Suspended"
                                : res?.odds?.ms == 4
                                ? "Closed"
                                : ""
                            }
                          >
                            {/* class="ballrunning-fancy" */}
                            {/* condition for ball running */}
                            <div class=" ask d-inline">
                              <span class="ask-price">
                                {res?.rt?.length > 0 ? res?.rt[0]?.rt : "-"}
                              </span>
                              <span class="ask-price-small">
                                {res?.rt?.length > 0 ? res?.rt[0]?.pt : "-"}
                              </span>
                            </div>
                            <div class="bid d-inline">
                              <span class="bid-price">
                                {res?.rt?.length > 0 ? res?.rt[1]?.rt : "-"}
                              </span>
                              <span class="bid-price-small">
                                {res?.rt?.length > 0 ? res?.rt[1]?.pt : "-"}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td class="w-16 w-16-1 mobile-hide">
                          <div class="min-max-price">
                            <span class="d-block">
                              Min: <span>{minMax?.minAmount || 100}</span>
                            </span>
                            <span class="d-block ">
                              Max: <span>{minMax?.maxAmount || 100000}</span>
                            </span>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
      {bookPositionData?.status && (
        <BookFancy
          setBookPositionData={setBookPositionData}
          data={bookPositionData}
        />
      )}
    </div>
  );
};

export default FancySport;
