import React, { useState } from "react";
import { Table, Button, Modal } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { apiGet } from "../../utils/apiFetch";
import apiPath from "../../utils/apiPath";
import constants from "../../utils/constants";
import { useEffect } from "react";
import obj from "../../utils/constants";
import { isEmpty } from "lodash";
import { Helmet } from "react-helmet";
const ViewDialog = ({ object, onHide, setObject }) => {
  const params = useParams();
  const [isLoader, setLoader] = useState(false);
  const [matchData, setMatchData] = useState([]);
  const [detailsData, setDetailsData] = useState({});
  const [search_params, setSearchParams] = useState({
    userId: object?.userId,
    userType: object?.userType,
    eventId: object?.id,
    betType:
      object?.title == "Book Maker"
        ? "bookmaker"
        : object?.title == "Toss"
        ? "toss"
        : "betFair",
  });

  const getMatchData = async () => {
    setLoader(true);

    const { status, data: response_users } = await apiGet(
      apiPath.downlineDataRisk,
      search_params
    );
    if (status === 200) {
      if (response_users.success) {
        if (response_users.results) {
          setLoader(false);
          setMatchData(response_users.results);
        }
      }
    }
  };
  const setFunc = (object) => {
    if (!isEmpty(object)) {
      setSearchParams({
        userId: object?.userId,
        userType: object?.userType,
        eventId: object?.id,
        betType:
          object?.title == "Book Maker"
            ? "bookmaker"
            : object?.title == "Toss"
            ? "toss"
            : "betFair",
      });
    }
  };
  useEffect(() => {
    setFunc(object);
  }, [object]);

  useEffect(() => {
    getMatchData();
    getDetails();
  }, [object?.userId, object?.eventId, object?.betType]);

  useEffect(() => {
    const intervalID = setInterval(() => {
      getMatchData();
      getDetails();
    }, 10 * 1000);
    return () => clearInterval(intervalID);
  }, [object?.userId, object?.eventId, object?.betType]);

  const getDetails = async () => {
    const { status, data: response_users } = await apiGet(
      apiPath.riskManagementDetails + `?eventId=${search_params.eventId}`
    );
    if (status === 200) {
      if (response_users.success) {
        if (response_users.results) {
          setDetailsData(response_users.results);
        }
      }
    }
  };

  var first = 0;
  var third = 0;
  var second = 0;

  return (
    <div className="risk-popup">
      <Modal size="2xl" centered show={true} onHide={onHide}>
        <Modal.Body>
          <section className="account-table risk-pop">
            <div className="container-fluid px-0">
              <div className="db-sec d-flex justify-content-between align-items-center mb-2">
                <h2 className="common-heading">
                  {detailsData?.eventName} ( {object?.title} )
                </h2>
                <Button className="green-btn" onClick={() => onHide()}>
                  X
                </Button>
              </div>
              {matchData?.length > 0 && (
                <div className="responsive">
                  <Table>
                    <thead>
                      <tr>
                        <th scope="col">Downline</th>
                        <th scope="col">
                          {" "}
                          {detailsData?.jsonData
                            ? detailsData?.jsonData[0]?.RunnerName
                            : ""}
                        </th>
                        {detailsData?.jsonData?.length > 2 && (
                          <th scope="col">The Draw</th>
                        )}
                        <th scope="col">
                          {detailsData?.jsonData
                            ? detailsData?.jsonData[1]?.RunnerName
                            : ""}
                        </th>
                      </tr>
                    </thead>
                    {!isLoader ? (
                      <tbody>
                        {matchData &&
                          matchData?.length > 0 &&
                          matchData?.map((res) => {
                            if (Object.keys(detailsData).length !== 0) {
                              first +=
                                res?.position?.find(
                                  (res) =>
                                    res?.selectionId ==
                                    detailsData?.jsonData[0]?.SelectionId
                                )?.position != 0
                                  ? res?.position?.find(
                                      (res) =>
                                        res?.selectionId ==
                                        detailsData?.jsonData[0]?.SelectionId
                                    )?.position
                                  : 0;
                              third +=
                                res?.position?.find(
                                  (res) =>
                                    res?.selectionId ==
                                    detailsData?.jsonData[2]?.SelectionId
                                )?.position != 0
                                  ? res?.position?.find(
                                      (res) =>
                                        res?.selectionId ==
                                        detailsData?.jsonData[2]?.SelectionId
                                    )?.position
                                  : 0;
                              second +=
                                res?.position?.find(
                                  (res) =>
                                    res?.selectionId ==
                                    detailsData?.jsonData[1]?.SelectionId
                                )?.position != 0
                                  ? res?.position?.find(
                                      (res) =>
                                        res?.selectionId ==
                                        detailsData?.jsonData[1]?.SelectionId
                                    )?.position
                                  : 0;
                            }

                            return first == 0 && second == 0 && third == 0 ? (
                              ""
                            ) : (
                              <>
                                <tr>
                                  <td
                                    className="text-start"
                                    // style={{ display: "flex", width: "100%" }}
                                  >
                                    {search_params?.userType == "agent" ? (
                                      <>
                                        <a href={"#"} className="text-primary">
                                          <span>
                                            {
                                              constants?.user_status[
                                                res?.userType || ""
                                              ]
                                            }
                                          </span>
                                        </a>
                                        {res?.username || null}
                                      </>
                                    ) : (
                                      <Link
                                        to="#"
                                        onClick={() => {
                                          setObject({
                                            userId: res?._id,
                                            userType: res?.userType,
                                            id: search_params?.eventId,
                                            title: object?.title,
                                          });
                                          setFunc({
                                            userId: res?._id,
                                            userType: res?.userType,
                                            id: search_params?.eventId,
                                            title: object?.title,
                                          });
                                        }}
                                        // to={`/DownlinePnl/${res._id}/${res?.userType}/${search_params.eventId}/${object?.type}`}
                                      >
                                        <a href={"#"} className="text-primary">
                                          <span>
                                            {
                                              constants?.user_status[
                                                res?.userType || ""
                                              ]
                                            }
                                          </span>
                                        </a>
                                        {res?.username || null}
                                      </Link>
                                    )}
                                  </td>
                                  <td>
                                    {Math.sign(
                                      res?.position
                                        ?.find(
                                          (res) =>
                                            res?.selectionId ==
                                            detailsData?.jsonData[0]
                                              ?.SelectionId
                                        )
                                        ?.position?.toFixed(2)
                                    ) === -1 ? (
                                      search_params?.userType == "agent" ? (
                                        <span className="text-danger">
                                          {res?.position
                                            ?.find(
                                              (res) =>
                                                res?.selectionId ==
                                                detailsData?.jsonData[0]
                                                  ?.SelectionId
                                            )
                                            ?.position?.toFixed(2)}
                                        </span>
                                      ) : (
                                        <span className="text-success">
                                          {Math.abs(
                                            res?.position
                                              ?.find(
                                                (res) =>
                                                  res?.selectionId ==
                                                  detailsData?.jsonData[0]
                                                    ?.SelectionId
                                              )
                                              ?.position?.toFixed(2)
                                          )}
                                        </span>
                                      )
                                    ) : search_params?.userType == "agent" ? (
                                      <span className="text-success">
                                        {res?.position
                                          ?.find(
                                            (res) =>
                                              res?.selectionId ==
                                              detailsData?.jsonData[0]
                                                ?.SelectionId
                                          )
                                          ?.position?.toFixed(2)}
                                      </span>
                                    ) : (
                                      <span className="text-danger">
                                        {
                                          -res?.position
                                            ?.find(
                                              (res) =>
                                                res?.selectionId ==
                                                detailsData?.jsonData[0]
                                                  ?.SelectionId
                                            )
                                            ?.position?.toFixed(2)
                                        }
                                      </span>
                                    )}
                                  </td>
                                  {detailsData?.jsonData?.length > 2 && (
                                    <td>
                                      {Math.sign(
                                        res?.position
                                          ?.find(
                                            (res) =>
                                              res?.selectionId ==
                                              detailsData?.jsonData[2]
                                                ?.SelectionId
                                          )
                                          ?.position?.toFixed(2)
                                      ) === -1 ? (
                                        search_params?.userType == "agent" ? (
                                          <span className="text-danger">
                                            {res?.position
                                              ?.find(
                                                (res) =>
                                                  res?.selectionId ==
                                                  detailsData?.jsonData[2]
                                                    ?.SelectionId
                                              )
                                              ?.position?.toFixed(2)}
                                          </span>
                                        ) : (
                                          <span className="text-success">
                                            {Math.abs(
                                              res?.position
                                                ?.find(
                                                  (res) =>
                                                    res?.selectionId ==
                                                    detailsData?.jsonData[2]
                                                      ?.SelectionId
                                                )
                                                ?.position?.toFixed(2)
                                            )}
                                          </span>
                                        )
                                      ) : search_params?.userType == "agent" ? (
                                        <span className="text-success">
                                          {res?.position
                                            ?.find(
                                              (res) =>
                                                res?.selectionId ==
                                                detailsData?.jsonData[2]
                                                  ?.SelectionId
                                            )
                                            ?.position?.toFixed(2)}
                                        </span>
                                      ) : (
                                        <span className="text-danger">
                                          {
                                            -res?.position
                                              ?.find(
                                                (res) =>
                                                  res?.selectionId ==
                                                  detailsData?.jsonData[2]
                                                    ?.SelectionId
                                              )
                                              ?.position?.toFixed(2)
                                          }
                                        </span>
                                      )}
                                    </td>
                                  )}
                                  <td>
                                    {Math.sign(
                                      res?.position
                                        ?.find(
                                          (res) =>
                                            res?.selectionId ==
                                            detailsData?.jsonData[1]
                                              ?.SelectionId
                                        )
                                        ?.position?.toFixed(2)
                                    ) === -1 ? (
                                      search_params?.userType == "agent" ? (
                                        <span className="text-danger">
                                          {res?.position
                                            ?.find(
                                              (res) =>
                                                res?.selectionId ==
                                                detailsData?.jsonData[1]
                                                  ?.SelectionId
                                            )
                                            ?.position?.toFixed(2)}
                                        </span>
                                      ) : (
                                        <span className="text-success">
                                          {Math.abs(
                                            res?.position
                                              ?.find(
                                                (res) =>
                                                  res?.selectionId ==
                                                  detailsData?.jsonData[1]
                                                    ?.SelectionId
                                              )
                                              ?.position?.toFixed(2)
                                          )}
                                        </span>
                                      )
                                    ) : search_params?.userType == "agent" ? (
                                      <span className="text-success">
                                        {res?.position
                                          ?.find(
                                            (res) =>
                                              res?.selectionId ==
                                              detailsData?.jsonData[1]
                                                ?.SelectionId
                                          )
                                          ?.position?.toFixed(2)}
                                      </span>
                                    ) : (
                                      <span className="text-danger">
                                        {
                                          -res?.position
                                            ?.find(
                                              (res) =>
                                                res?.selectionId ==
                                                detailsData?.jsonData[1]
                                                  ?.SelectionId
                                            )
                                            ?.position?.toFixed(2)
                                        }
                                      </span>
                                    )}
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                        {matchData?.length > 0 && (
                          <tr style={{ fontWeight: "600" }}>
                            <td
                              className="text-start"
                              // style={{ display: "flex", width: "100%" }}
                            >
                              Total
                            </td>
                            <td>
                              {Math.sign(first?.toFixed(2)) === -1 ? (
                                search_params?.userType == "agent" ? (
                                  <span className="text-danger">
                                    {first?.toFixed(2)}
                                  </span>
                                ) : (
                                  <span className="text-success">
                                    {Math.abs(first?.toFixed(2))}
                                  </span>
                                )
                              ) : search_params?.userType == "agent" ? (
                                <span className="text-success">
                                  {first?.toFixed(2)}
                                </span>
                              ) : (
                                <span className="text-danger">
                                  {-first?.toFixed(2)}
                                </span>
                              )}
                            </td>
                            {detailsData?.jsonData?.length > 2 && (
                              <td>
                                {Math.sign(third?.toFixed(2)) === -1 ? (
                                  search_params?.userType == "agent" ? (
                                    <span className="text-danger">
                                      {" "}
                                      {third?.toFixed(2)}
                                    </span>
                                  ) : (
                                    <span className="text-success">
                                      {" "}
                                      {Math.abs(third?.toFixed(2))}
                                    </span>
                                  )
                                ) : search_params?.userType == "agent" ? (
                                  <span className="text-success">
                                    {third?.toFixed(2)}
                                  </span>
                                ) : (
                                  <span className="text-danger">
                                    {-third?.toFixed(2)}
                                  </span>
                                )}
                              </td>
                            )}

                            <td>
                              {Math.sign(second?.toFixed(2)) === -1 ? (
                                search_params?.userType == "agent" ? (
                                  <span className="text-danger">
                                    {second?.toFixed(2)}
                                  </span>
                                ) : (
                                  <span className="text-success">
                                    {Math.abs(second?.toFixed(2))}
                                  </span>
                                )
                              ) : search_params?.userType == "agent" ? (
                                <span className="text-success">
                                  {second?.toFixed(2)}
                                </span>
                              ) : (
                                <span className="text-danger">
                                  {-second?.toFixed(2)}
                                </span>
                              )}
                            </td>
                          </tr>
                        )}

                        {isEmpty(matchData) ? (
                          <tr>
                            <td colSpan={9}>No records found</td>
                          </tr>
                        ) : null}
                      </tbody>
                    ) : (
                      <div
                        id="loader"
                        className="spinner"
                        style={{ display: `block` }}
                      ></div>
                    )}
                  </Table>
                </div>
              )}
            </div>
          </section>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ViewDialog;
