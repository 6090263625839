import React, {
  startTransition,
  useContext,
  useEffect,
  useState,
  useSyncExternalStore,
} from "react";
import { Container, Form, Row, Col, Button } from "react-bootstrap";
import { toast } from "wc-toast";
import { apiGet, apiPost } from "../utils/apiFetch";
import pathObj from "../utils/apiPath";
import { inRange, isEmpty, isNumber } from "lodash";
import AuthContext from "../context/AuthContext";
import obj from "../utils/constants";
import { useForm } from "react-hook-form";
const PromotionalOffer = () => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    reset,
  } = useForm({});
  const [data, setData] = useState([]);
  const [select, setSelect] = useState("");
  const [filter, setFilter] = useState({});
  const [percent, setPercent] = useState("");
  const getCount = async () => {
    const { data } = await apiGet(pathObj.notificationCount);
    if (data?.success) {
      setSelect(data?.results?.default_agentID);
      setPercent(data?.results?.defualtCommision);
    }
  };

  const getData = async (type) => {
    try {
      const { status, data: response_users } = await apiGet(
        pathObj.getpromotionalOffer,
        {
          type: type,
        }
      );
      if (status === 200) {
        if (response_users.success) {
          startTransition(() => {
            setData(response_users?.results);
            let obj = response_users?.results?.data;
            let firstSecond = {
              amount: obj?.amount,
              discount: obj?.promotalDiscount,
              fromPeriod: obj?.promotal_start_date,
              toPeriod: obj?.promotal_end_date,
              turnover: obj?.turnover,
              userLimit: obj?.userLimit,
              maxAmount: obj?.maxAmount,
              discription: obj?.discription,
            };
            let refralBouns = {
              bonus: obj?.bonus,
              fromPeriod: obj?.promotal_start_date,
              toPeriod: obj?.promotal_end_date,
              turnover: obj?.turnover,
              userLimit: obj?.userLimit,
              maxAmount: obj?.maxAmount,
              discription: obj?.discription,
            };
            let slotGame = {
              gameSlot: obj?.gameSlot,
              min_turnover: obj?.min_turnover,
              use_limit: obj?.use_limit,
              bonus_available: obj?.bonus_available,
              fromPeriod: obj?.promotal_start_date,
              toPeriod: obj?.promotal_end_date,
              userLimit: obj?.userLimit,
              maxAmount: obj?.maxAmount,
              discription: obj?.discription,
            };
            let special_bonus = {
              deposit_bonus: obj?.deposit_bonus,
              bonus: obj?.bonus,
              specialDate: obj?.specialDate,
              minAmount: obj?.minAmount,
              turnover: obj?.turnover,
              userLimit: obj?.userLimit,
              maxAmount: obj?.maxAmount,
              discription: obj?.discription,
            };
            let arr = {
              First_deposit_bonus: firstSecond,
              Altime_deposit_bonus: firstSecond,
              user_referral_bouns: refralBouns,
              weekly_loss_bonus: refralBouns,
              slot_game_bonus: slotGame,
              special_date_bonus: special_bonus,
              userLimit: obj?.userLimit,
              maxAmount: obj?.maxAmount,
              discription: obj?.discription,
            };
            setChecked(obj?.onOff);
            if (!isEmpty(obj)) {
              Object.entries(arr[response_users?.results?.type]).forEach(
                ([name, value]) => setValue(name, value)
              );
            }

            // setFilter({
            //   ...obj,
            //   fromPeriod: obj?.promotal_start_date,
            //   toPeriod: obj?.promotal_end_date,
            //   checked: obj?.onOff,
            //   discount: obj?.promotalDiscount,
            //   turnover: obj?.turnover || 0,
            // });
            // if (!isEmpty(response_users?.results?.data)) {
            //   setPercent(response_users?.results?.data?.amount);
            // }
          });
        }
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const check = () => {
    if (select == "First_deposit_bonus" || select == "Altime_deposit_bonus") {
      if (percent == 0 || isEmpty(percent)) {
        toast.error("Please enter amount greater than 0 ");
        return false;
      } else if (isEmpty(select)) {
        toast.error("Please select Offer");
        return false;
      } else if (isEmpty(filter?.fromPeriod)) {
        toast.error("Please select from period");
        return false;
      } else if (isEmpty(filter?.toPeriod)) {
        toast.error("Please select to period");
        return false;
      } else if (!inRange(filter?.discount, 0, 100)) {
        toast.error("Discount should not be greater than 100");
        return false;
      } else {
        return true;
      }
    } else if (
      select == "user_referral_bouns" ||
      select == "weekly_loss_bonus"
    ) {
      if (isEmpty(select)) {
        toast.error("Please select Offer");
        return false;
      } else if (isEmpty(filter?.fromPeriod)) {
        toast.error("Please select from period");
        return false;
      } else if (isEmpty(filter?.toPeriod)) {
        toast.error("Please select to period");
        return false;
      } else if (isEmpty(filter?.bonus)) {
        toast.error("Please select bonus");
        return false;
      } else {
        return true;
      }
    } else if (select == "slot_game_bonus") {
      if (isEmpty(select)) {
        toast.error("Please select Offer");
        return false;
      } else if (isEmpty(filter?.fromPeriod)) {
        toast.error("Please select from period");
        return false;
      } else if (isEmpty(filter?.toPeriod)) {
        toast.error("Please select to period");
        return false;
      } else if (isEmpty(filter?.gameSlot)) {
        toast.error("Please select GameSlot");
        return false;
      } else if (isEmpty(filter?.use_limit)) {
        toast.error("Please select use limit");
        return false;
      } else if (isEmpty(filter?.min_turnover)) {
        toast.error("Please select min turnover");
        return false;
      } else if (isEmpty(filter?.bonus_available)) {
        toast.error("Please select bonus available");
        return false;
      } else {
        return true;
      }
    } else if (select == "special_date_bonus") {
      if (isEmpty(select)) {
        toast.error("Please select Offer");
        return false;
      } else if (isEmpty(filter?.specialDate)) {
        toast.error("Please select specialDate");
        return false;
      } else if (isEmpty(filter?.minAmount)) {
        toast.error("Please select min amount");
        return false;
      } else if (isEmpty(filter?.deposit_bonus)) {
        toast.error("Please select deposit bonus");
        return false;
      } else if (isEmpty(filter?.bonus)) {
        toast.error("Please select bonus");
        return false;
      } else {
        return true;
      }
    }
  };

  const [checked, setChecked] = useState(false);
  const submit = async (body) => {
    let firstSecond = {
      type: body?.offer,
      amount: body?.amount,
      promotalDiscount: body?.discount,
      promotal_start_date: body?.fromPeriod,
      promotal_end_date: body?.toPeriod,
      onOff: checked,
      turnover: body?.turnover,
    };
    let refralBouns = {
      type: body?.offer,
      bonus: body?.bonus,
      promotal_start_date: body?.fromPeriod,
      promotal_end_date: body?.toPeriod,
      onOff: checked,
      turnover: body?.turnover,
    };
    let slotGame = {
      type: body?.offer,
      gameSlot: body?.gameSlot,
      min_turnover: body?.min_turnover,
      use_limit: body?.use_limit,
      bonus_available: body?.bonus_available,
      promotal_start_date: body?.fromPeriod,
      promotal_end_date: body?.toPeriod,
      onOff: checked,
    };
    let special_bonus = {
      type: body?.offer,
      deposit_bonus: body?.deposit_bonus,
      bonus: body?.bonus,
      specialDate: body?.specialDate,
      minAmount: body?.minAmount,
      onOff: checked,
      turnover: body?.turnover,
    };
    let arr = {
      First_deposit_bonus: firstSecond,
      Altime_deposit_bonus: firstSecond,
      user_referral_bouns: refralBouns,
      weekly_loss_bonus: refralBouns,
      slot_game_bonus: slotGame,
      special_date_bonus: special_bonus,
    };
    try {
      const { status, data: response_users } = await apiPost(
        pathObj.promotionalOffer,
        {
          ...arr[body?.offer],
          userLimit: body?.userLimit,
          maxAmount: body?.maxAmount,
          discription: body?.discription,
        }
      );
      if (status === 200) {
        if (response_users.success) {
          toast.success(response_users.message);
          reset();
          setChecked(false)
        }
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <div>
      <section className="set-limit-sec p-5">
        <Form onSubmit={handleSubmit(submit)}>
          <Row className="align-items-center">
            <Col md={8} lg={6} className="mb-2">
              <Form.Group className="d-flex align-items-start flex-column">
                <Form.Label>Promotional Offer</Form.Label>
                <Form.Select
                  className="w-100"
                  {...register("offer", {
                    required: "Please select offer",
                    onChange: (e) => {
                      getData(e?.target.value);
                    },
                  })}
                  // onChange={(e) => {
                  //   getData(e.target.value);
                  //   setSelect(e.target.value);
                  //   setFilter({
                  //     fromPeriod: "",
                  //     toPeriod: "",
                  //     checked: false,
                  //   });
                  // }}
                  aria-label="Default select example w-auto"
                >
                  <option value="">Select Offer</option>
                  <option value="First_deposit_bonus">
                    First deposit bonus
                  </option>
                  <option value="Altime_deposit_bonus">
                    Altime deposit bonus
                  </option>
                  <option value="user_referral_bouns">
                    User Referral Bouns
                  </option>
                  <option value="weekly_loss_bonus">Weekly loss bonus</option>
                  <option value="slot_game_bonus">Slot Game Bonus</option>
                  <option value="special_date_bonus">Special Date Bonus</option>
                </Form.Select>
                {errors.offer && errors.offer.message && (
                  <label className="invalid-feedback text-left">
                    {errors.offer.message}
                  </label>
                )}
              </Form.Group>
            </Col>
            {watch("offer") == "special_date_bonus" && (
              <>
                <Col md={8} lg={6} className="mb-2">
                  <Form.Group className="d-flex align-items-start flex-column">
                    <Form.Label>Special Date</Form.Label>
                    <Form.Control
                      // onChange={(e) =>
                      //   setFilter({
                      //     ...filter,
                      //     specialDate: e.target.value,
                      //   })
                      // }
                      {...register("specialDate", {
                        required: "Please select specialDate",
                      })}
                      min={new Date().toISOString().split("T")[0]}
                      // value={filter?.specialDate}
                      type="date"
                    />
                    {errors.specialDate && errors.specialDate.message && (
                      <label className="invalid-feedback text-left">
                        {errors.specialDate.message}
                      </label>
                    )}
                  </Form.Group>
                </Col>{" "}
                <Col md={8} lg={6} className="mb-2">
                  <Form.Group className="d-flex align-items-start flex-column">
                    <Form.Label>Min Amount</Form.Label>
                    <Form.Control
                      // value={filter?.minAmount}
                      placeholder="Enter Min Amount"
                      // onChange={(e) =>
                      //   setFilter({
                      //     ...filter,
                      //     minAmount: e.target.value,
                      //   })
                      // }
                      {...register("minAmount", {
                        required: "Please select min Amount",
                      })}
                      aria-label="Default select example w-auto"
                      type="number"
                      min="0"
                    />
                    {errors.minAmount && errors.minAmount.message && (
                      <label className="invalid-feedback text-left">
                        {errors.minAmount.message}
                      </label>
                    )}
                  </Form.Group>
                </Col>
                <Col md={8} lg={6} className="mb-2">
                  <Form.Group className="d-flex align-items-start flex-column">
                    <Form.Label>Deposit Bonus (%)</Form.Label>
                    <Form.Select
                      // value={filter?.deposit_bonus}
                      // onChange={(e) => {
                      //   setFilter({
                      //     ...filter,
                      //     deposit_bonus: e.target.value,
                      //   });
                      // }}
                      {...register("deposit_bonus", {
                        required: "Please select deposit bonus",
                      })}
                      aria-label="Default select example w-auto"
                    >
                      <option value="">Select Bonus</option>
                      {count?.map((res) => {
                        return <option value={res}>{res}</option>;
                      })}
                    </Form.Select>
                    {errors.deposit_bonus && errors.deposit_bonus.message && (
                      <label className="invalid-feedback text-left">
                        {errors.deposit_bonus.message}
                      </label>
                    )}
                  </Form.Group>
                </Col>
                <Col md={8} lg={6} className="mb-2">
                  <Form.Group className="d-flex align-items-start flex-column">
                    <Form.Label>Bonus Limit</Form.Label>
                    <Form.Select
                      // value={filter?.bonus}
                      // onChange={(e) => {
                      //   setFilter({ ...filter, bonus: e.target.value });
                      // }}
                      {...register("bonus", {
                        required: "Please select bonus",
                      })}
                      aria-label="Default select example w-auto"
                    >
                      <option value="">Select Bonus</option>
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                    </Form.Select>
                    {errors.bonus && errors.bonus.message && (
                      <label className="invalid-feedback text-left">
                        {errors.bonus.message}
                      </label>
                    )}
                  </Form.Group>
                </Col>
                <Col md={8} lg={6} className="mb-2">
                  <Form.Group className="d-flex align-items-start flex-column">
                    <Form.Label>Turnover(%)</Form.Label>
                    <Form.Control
                      // value={filter?.turnover}
                      placeholder="Enter Turnover"
                      // onChange={(e) =>
                      //   setFilter({
                      //     ...filter,
                      //     turnover: e.target.value,
                      //   })
                      // }
                      {...register("turnover", {
                        required: "Please select turnover",
                      })}
                      aria-label="Default select example w-auto"
                      type="number"
                      min="0"
                    />
                    {errors.turnover && errors.turnover.message && (
                      <label className="invalid-feedback text-left">
                        {errors.turnover.message}
                      </label>
                    )}
                  </Form.Group>
                </Col>
              </>
            )}
            {(watch("offer") == "First_deposit_bonus" ||
              watch("offer") == "Altime_deposit_bonus") && (
              <>
                <Col md={8} lg={6} className="mb-2">
                  <Form.Group className="d-flex align-items-start flex-column">
                    <Form.Label>Min Deposit Amount</Form.Label>
                    <Form.Control
                      // value={percent}
                      placeholder="Enter Min Deposit Amount"
                      // onChange={(e) => setPercent(e.target.value)}
                      {...register("amount", {
                        required: "Please select amount",
                      })}
                      aria-label="Default select example w-auto"
                      type="number"
                      min="0"
                    />
                    {errors.amount && errors.amount.message && (
                      <label className="invalid-feedback text-left">
                        {errors.amount.message}
                      </label>
                    )}
                  </Form.Group>
                </Col>
                <Col md={8} lg={6} className="mb-2">
                  <Form.Group className="d-flex align-items-start flex-column">
                    <Form.Label>Bonus (%)</Form.Label>
                    <Form.Control
                      // value={filter?.discount}
                      placeholder="Enter bonus"
                      // onChange={(e) =>
                      //   setFilter({
                      //     ...filter,
                      //     discount: e.target.value,
                      //   })
                      // }
                      {...register("discount", {
                        required: "Please select bonus",
                      })}
                      aria-label="Default select example w-auto"
                      type="number"
                      min="0"
                      max="20"
                    />
                    {errors.discount && errors.discount.message && (
                      <label className="invalid-feedback text-left">
                        {errors.discount.message}
                      </label>
                    )}
                  </Form.Group>
                </Col>
                <Col md={8} lg={6} className="mb-2">
                  <Form.Group className="d-flex align-items-start flex-column">
                    <Form.Label>Turnover(%)</Form.Label>
                    <Form.Control
                      // value={filter?.turnover}
                      placeholder="Enter Turnover"
                      // onChange={(e) =>
                      //   setFilter({
                      //     ...filter,
                      //     turnover: e.target.value,
                      //   })
                      // }
                      {...register("turnover", {
                        required: "Please select turnover",
                      })}
                      aria-label="Default select example w-auto"
                      type="number"
                      min="0"
                    />
                    {errors.turnover && errors.turnover.message && (
                      <label className="invalid-feedback text-left">
                        {errors.turnover.message}
                      </label>
                    )}
                  </Form.Group>
                </Col>
              </>
            )}
            {(watch("offer") == "user_referral_bouns" ||
              watch("offer") == "weekly_loss_bonus") && (
              <>
                <Col md={8} lg={6} className="mb-2">
                  <Form.Group className="d-flex align-items-start flex-column">
                    <Form.Label>
                      {select == "user_referral_bouns"
                        ? "Referral bouns"
                        : "Loss bonus"}
                    </Form.Label>
                    <Form.Select
                      // value={filter?.bonus}
                      // onChange={(e) => {
                      //   setFilter({ ...filter, bonus: e.target.value });
                      // }}
                      {...register("bonus", {
                        required: "Please select bonus",
                      })}
                      aria-label="Default select example w-auto"
                    >
                      <option value="">Select Bonus</option>
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                    </Form.Select>
                    {errors.bonus && errors.bonus.message && (
                      <label className="invalid-feedback text-left">
                        {errors.bonus.message}
                      </label>
                    )}
                  </Form.Group>
                </Col>
                <Col md={8} lg={6} className="mb-2">
                  <Form.Group className="d-flex align-items-start flex-column">
                    <Form.Label>Turnover(%)</Form.Label>
                    <Form.Control
                      // value={filter?.turnover}
                      placeholder="Enter Turnover"
                      // onChange={(e) =>
                      //   setFilter({
                      //     ...filter,
                      //     turnover: e.target.value,
                      //   })
                      // }
                      {...register("turnover", {
                        required: "Please select turnover",
                      })}
                      aria-label="Default select example w-auto"
                      type="number"
                      min="0"
                    />
                    {errors.turnover && errors.turnover.message && (
                      <label className="invalid-feedback text-left">
                        {errors.turnover.message}
                      </label>
                    )}
                  </Form.Group>
                </Col>
              </>
            )}
            {watch("offer") == "slot_game_bonus" && (
              <>
                <Col md={8} lg={6} className="mb-2">
                  <Form.Group className="d-flex align-items-start flex-column">
                    <Form.Label>Game Slot</Form.Label>
                    <Form.Select
                      // value={filter?.gameSlot}
                      // onChange={(e) => {
                      //   setFilter({ ...filter, gameSlot: e.target.value });
                      // }}
                      {...register("gameSlot", {
                        required: "Please select gameSlot",
                      })}
                      aria-label="Default select example w-auto"
                    >
                      <option value="">Select Game</option>
                      {obj?.gameSlot?.map((res) => {
                        return (
                          <option value={res?.casinoType}>{res?.name}</option>
                        );
                      })}
                    </Form.Select>
                    {errors.gameSlot && errors.gameSlot.message && (
                      <label className="invalid-feedback text-left">
                        {errors.gameSlot.message}
                      </label>
                    )}
                  </Form.Group>
                </Col>
                {!isEmpty(filter?.gameSlot) && (
                  <>
                    <Col md={8} lg={6} className="mb-2">
                      <Form.Group className="d-flex align-items-start flex-column">
                        <Form.Label>Use Limit</Form.Label>
                        <Form.Select
                          // value={filter?.use_limit}
                          // onChange={(e) => {
                          //   setFilter({
                          //     ...filter,
                          //     use_limit: e.target.value,
                          //   });
                          // }}
                          {...register("use_limit", {
                            required: "Please select use limit",
                          })}
                          aria-label="Default select example w-auto"
                        >
                          <option value="">Select Limit</option>
                          {count?.map((res) => {
                            return <option value={res}>{res}</option>;
                          })}
                        </Form.Select>
                        {errors.use_limit && errors.use_limit.message && (
                          <label className="invalid-feedback text-left">
                            {errors.use_limit.message}
                          </label>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={8} lg={6} className="mb-2">
                      <Form.Group className="d-flex align-items-start flex-column">
                        <Form.Label>Min Turnover</Form.Label>
                        <Form.Control
                          // value={filter?.min_turnover}
                          placeholder="Enter Min Turnover"
                          // onChange={(e) =>
                          //   setFilter({
                          //     ...filter,
                          //     min_turnover: e.target.value,
                          //   })
                          // }
                          {...register("min_turnover", {
                            required: "Please select min turnover",
                          })}
                          aria-label="Default select example w-auto"
                          type="number"
                          min="1"
                        />
                        {errors.min_turnover && errors.min_turnover.message && (
                          <label className="invalid-feedback text-left">
                            {errors.min_turnover.message}
                          </label>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={8} lg={6} className="mb-2">
                      <Form.Group className="d-flex align-items-start flex-column">
                        <Form.Label>Bonus Available</Form.Label>
                        <Form.Select
                          // value={filter?.bonus_available}
                          // onChange={(e) => {
                          //   setFilter({
                          //     ...filter,
                          //     bonus_available: e.target.value,
                          //   });
                          // }}
                          {...register("bonus_available", {
                            required: "Please select bonus available",
                          })}
                          aria-label="Default select example w-auto"
                        >
                          <option value="">Select Bonus Available</option>
                          <option value="single">Single</option>
                          <option value="weekly">Weekly</option>
                          <option value="monthly">Monthly</option>
                          <option value="yearly">Yearly</option>
                        </Form.Select>
                        {errors.bonus_available &&
                          errors.bonus_available.message && (
                            <label className="invalid-feedback text-left">
                              {errors.bonus_available.message}
                            </label>
                          )}
                      </Form.Group>
                    </Col>
                  </>
                )}
              </>
            )}
            {!isEmpty(watch("offer")) && (
              <>
                {watch("offer") !== "special_date_bonus" && (
                  <Col md={8} lg={6} className="mb-2">
                    <Form.Group className="d-flex align-items-start flex-column">
                      <Form.Label>From</Form.Label>
                      <Form.Control
                        // onChange={(e) =>
                        //   setFilter({
                        //     ...filter,
                        //     fromPeriod: e.target.value,
                        //   })
                        // }
                        {...register("fromPeriod", {
                          required: "Please select fromPeriod",
                        })}
                        min={new Date().toISOString().split("T")[0]}
                        // value={filter?.fromPeriod}
                        type="date"
                      />
                      {errors.fromPeriod && errors.fromPeriod.message && (
                        <label className="invalid-feedback text-left">
                          {errors.fromPeriod.message}
                        </label>
                      )}
                    </Form.Group>
                  </Col>
                )}
                {watch("offer") !== "special_date_bonus" && (
                  <Col md={8} lg={6} className="mb-2">
                    <Form.Group className="d-flex align-items-start flex-column">
                      <Form.Label>To</Form.Label>
                      <Form.Control
                        // onChange={(e) =>
                        //   setFilter({
                        //     ...filter,
                        //     toPeriod: e.target.value,
                        //   })
                        // }
                        min={
                          watch("fromPeriod")
                            ? new Date(watch("fromPeriod"))
                                .toISOString()
                                .split("T")[0]
                            : new Date()
                        }
                        {...register("toPeriod", {
                          required: "Please select toPeriod",
                        })}
                        disabled={watch("fromPeriod") ? false : true}
                        // value={filter?.toPeriod}
                        type="date"
                      />
                      {errors.toPeriod && errors.toPeriod.message && (
                        <label className="invalid-feedback text-left">
                          {errors.toPeriod.message}
                        </label>
                      )}
                    </Form.Group>
                  </Col>
                )}

                <Col md={8} lg={6} className="mb-2">
                  <Form.Group className="d-flex align-items-start flex-column">
                    <Form.Label>User Limit</Form.Label>
                    <Form.Control
                      placeholder="Enter User Limit"
                      // onChange={(e) =>
                      //   setFilter({
                      //     ...filter,
                      //     userLimit: e.target.value,
                      //   })
                      // }
                      {...register("userLimit", {
                        required: "Please select userLimit",
                      })}
                      aria-label="Default select example w-auto"
                      type="number"
                      min="0"
                    />
                    {errors.userLimit && errors.userLimit.message && (
                      <label className="invalid-feedback text-left">
                        {errors.userLimit.message}
                      </label>
                    )}
                  </Form.Group>
                </Col>
                <Col md={8} lg={6} className="mb-2">
                  <Form.Group className="d-flex align-items-start flex-column">
                    <Form.Label>Max Offer Amount</Form.Label>
                    <Form.Control
                      placeholder="Enter Offer Max Amount"
                      // onChange={(e) =>
                      //   setFilter({
                      //     ...filter,
                      //     maxAmount: e.target.value,
                      //   })
                      // }
                      {...register("maxAmount", {
                        required: "Please select maxAmount",
                      })}
                      aria-label="Default select example w-auto"
                      type="number"
                      min="0"
                    />
                    {errors.maxAmount && errors.maxAmount.message && (
                      <label className="invalid-feedback text-left">
                        {errors.maxAmount.message}
                      </label>
                    )}
                  </Form.Group>
                </Col>
                <Col md={8} lg={6} className="mb-2">
                  <Form.Group className="d-flex align-items-start flex-column">
                    <Form.Label>Discroption</Form.Label>
                    <Form.Control
                      placeholder="Enter Discription"
                      // onChange={(e) =>
                      //   setFilter({
                      //     ...filter,
                      //     discription: e.target.value,
                      //   })
                      // }
                      {...register("discription", {
                        required: "Please select discription",
                      })}
                      aria-label="Default select example w-auto"
                      type="text"
                      as="textarea"
                    />
                    {errors.discription && errors.discription.message && (
                      <label className="invalid-feedback text-left">
                        {errors.discription.message}
                      </label>
                    )}
                  </Form.Group>
                </Col>
                <Col md={8} lg={6} className="mb-2">
                  <Form.Group className="d-flex align-items-start flex-column">
                    <Form.Label>ON/OFF Status</Form.Label>
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      checked={checked}
                      onClick={(e) => {
                        setChecked(e.target.checked);
                      }}
                    />
                  </Form.Group>{" "}
                </Col>
              </>
            )}
            <Col md={12}>
              <Button
                type="submit"
                // onClick={() => {
                //   if (!isEmpty(select)) {
                //     submit(select);
                //   } else {
                //     toast.error("Please select agent");
                //   }
                // }}
                className="theme_light_btn"
              >
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </section>
    </div>
  );
};

export default PromotionalOffer;

let count = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
];
