import React, { useState } from "react";
import { Link } from "react-router-dom";
import ViewBets from "./ViewBets";
export const FancyListDropdown = ({
  showMatch,
  data,
  selectData,
  res,
  title,
  fancyOdds,
  user,
  setModalOuter,
  setObjectOuter,
}) => {
  const [modal, setModal] = useState(false);
  const [object, setObject] = useState({});
  return (
    showMatch && (
      <tr key={data[0]?.runnerName}>
        <table className="selection-table w-100 mb-2">
          <tbody>
            {" "}
            {/* <tr>
              <td className="bg-light-yellow">
                <strong className="d-flex justify-content-center align-items-center">
                  {" "}
                  Min
                </strong>
              </td>
              <td className="bg-light-yellow ">
                <strong className="d-flex justify-content-center align-items-center">
                  Max
                </strong>
              </td>
            </tr> */}
            {/* <tr>
              <td className=" back-1s p-0" style={{ cursor: "not-allowed" }}>
                <span className="text-danger d-flex justify-content-center align-items-center">
                  {res?.positionLoseAmount
                    ? "(-" + res?.positionLoseAmount + ")"
                    : 0}
                </span>
              </td>
              <td className="" style={{ cursor: "not-allowed" }}>
                <span className="text-success d-flex justify-content-center align-items-center">
                  {res?.positionProfitAmount ? res?.positionProfitAmount : 0}
                </span>
              </td>
            </tr> */}
            <tr>
              {/* <td>{data[0]?.runnerName}</td> */}
              <td className="back-1s p-0" style={{ cursor: "not-allowed" }}>
                <div className="light-blue rounded-0">
                  <strong>
                    {fancyOdds && fancyOdds.length > 0 && fancyOdds[0]?.rt
                      ? fancyOdds[0]?.rt
                      : "--"}
                  </strong>
                  <span className="d-block">
                    {fancyOdds && fancyOdds.length > 0 && fancyOdds[0]?.pt
                      ? fancyOdds[0]?.pt
                      : "--"}
                  </span>
                </div>
              </td>
              <td className="lay-1 p-0" style={{ cursor: "not-allowed" }}>
                <div className="lay-all rounded-0">
                  <strong>
                    {fancyOdds && fancyOdds.length > 0 && fancyOdds[1]?.rt
                      ? fancyOdds[1]?.rt
                      : "--"}
                  </strong>
                  <span className="d-block">
                    {fancyOdds && fancyOdds.length > 0 && fancyOdds[1]?.pt
                      ? fancyOdds[1]?.pt
                      : "--"}
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td className="p-2" colSpan={4}>
                <Link
                  to="#"
                  onClick={() => {
                    setModalOuter(true);
                    setObjectOuter({
                      name: res?.fancyName,
                      eventid: res?.eventId,
                      marketId: res?.marketId,
                      selectionId: res?.selectionId,
                    });
                  }}
                  className="green-btn"
                >
                  {"Book"}
                </Link>{" "}
                <Link
                  to="#"
                  className="green-btn"
                  onClick={() => {
                    setObject({
                      userId: res?.eventId,
                      userType: res?.matchName,
                      type: title,
                      selectionId: res?.selectionId,
                    });
                    setModal(true);
                  }}
                >
                  View Bets
                </Link>
              </td>
            </tr>
          </tbody>
        </table>

        {/* <td width="80" className="border-l">
          <Link
            to="#"
            className="green-btn"
            onClick={() => {
              setObject({
                userId: res?.eventId,
                userType: res?.matchName,
                type: title,
                selectionId: res?.selectionId,
              });
              setModal(true);
            }}
          >
            View Bets
          </Link>
        </td> */}

        {modal && (
          <ViewBets
            onHide={() => {
              setModal(false);
              setObject({});
            }}
            setObject={setObject}
            show={modal}
            object={object}
          />
        )}
      </tr>
    )
  );
};
