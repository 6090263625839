import React, { useState } from "react";
import { Table } from "react-bootstrap";
import obj from "../utils/helpers";
import { startCase } from "lodash";

const BetListLottery = ({ data, type }) => {
  const [expand, setExpand] = useState("");
  return (
    <div className="responsive">
      <Table className="all-bets-dialog-tabel">
        <thead>
          <tr>
            <th scope="col">PL ID</th>
            <th scope="col">Bet ID</th>
            <th scope="col">Bet placed</th>
            <th scope="col">Match</th>
            <th scope="col">Lottery Type</th>
            <th scope="col">Bhav</th>{" "}
            <th scope="col">{type == "pl" ? "P/L" : "Total Bet Amount"}</th>
          </tr>
        </thead>
        <tbody>
          {data?.length > 0 ? (
            data?.map((item, index) => {
              let backprofit = item?.bets
                ?.filter((obj) => {
                  return obj?.decision === obj?.selectionId;
                })
                .reduce((a, v) => {
                  return parseFloat(a) + parseFloat(v.profitAmount);
                }, 0);

              let backLoss = item?.bets
                ?.filter((obj) => {
                  return obj?.decision !== obj?.selectionId;
                })
                .reduce((a, v) => {
                  return parseFloat(a) + parseFloat(v.loseAmount);
                }, 0);
              let total = backprofit - backLoss;
              return (
                <>
                  <tr
                    id="summary0"
                    style={{ display: "table-row" }}
                    key={index + 1}
                  >
                    {" "}
                    <td className="text-start">
                      <i
                        id={"icon_" + item?.matchBetId}
                        className={
                          expand == item?.matchBetId
                            ? "fas fa-minus-square pe-2"
                            : "fas fa-plus-square pe-2"
                        }
                        onClick={(e) => {
                          if (expand == item?.matchBetId) {
                            setExpand("");
                          } else {
                            setExpand(item?.matchBetId);
                          }
                        }}
                      ></i>
                      {item?.clientName || null}
                    </td>
                    <td> {item?.matchBetId}</td>
                    <td>
                      {" "}
                      {obj.dateFormat(item?.createdAt || item?.timeInserted)}
                    </td>{" "}
                    <td>
                      {" "}
                      {item?.bets?.length > 0 && item?.bets[0]?.matchName}
                    </td>{" "}
                    <td> {startCase(item?.lotteryType)}</td>{" "}
                    <td> {item?.bhav}</td>{" "}
                    <td>
                      {" "}
                      {type == "pl" ? (
                        <span
                          className={total > 0 ? "text-success" : "text-danger"}
                        >
                          {total}
                        </span>
                      ) : (
                        <span>
                          ({obj.currencyFormat(Math.abs(item?.amount))})
                        </span>
                      )}
                    </td>
                  </tr>
                  {expand == item?.matchBetId && item?.bets?.length > 0 && (
                    <tr className="expand">
                      <td colSpan="9" className="expand_wrap">
                        <Table style={{ width: "100%" }}>
                          <thead>
                            <tr>
                              <th>Selection</th> <th scope="col">Bet Amount</th>
                              {type == "pl" && <th scope="col">Decision</th>}
                              {type !== "pl" && (
                                <th scope="col">Loss Amount</th>
                              )}
                              {type !== "pl" && (
                                <th scope="col">Profit Amount</th>
                              )}
                              {type == "pl" && <th scope="col">P/L</th>}
                              {type !== "pl" && <th scope="col">Result</th>}
                            </tr>{" "}
                          </thead>
                          <tbody>
                            {item?.bets?.map((res) => {
                              return (
                                <tr>
                                  <td
                                    style={{
                                      fontWeight: "600",
                                      fontSize: "11px",
                                    }}
                                  >
                                    {res?.runnerName}
                                  </td>
                                  <td
                                    style={{
                                      fontWeight: "600",
                                      fontSize: "11px",
                                    }}
                                  >
                                    {" "}
                                    <span>{item?.amount}</span>
                                  </td>
                                  {type == "pl" && (
                                    <td
                                      style={{
                                        fontWeight: "600",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {" "}
                                      <span>{item?.decision}</span>
                                    </td>
                                  )}
                                  {type !== "pl" && (
                                    <>
                                      <td
                                        style={{
                                          fontWeight: "600",
                                          fontSize: "11px",
                                        }}
                                      >
                                        {" "}
                                        <span className={"text-danger"}>
                                          (
                                          {obj.currencyFormat(
                                            Math.abs(res?.loseAmount)
                                          )}
                                          )
                                        </span>
                                      </td>
                                      <td
                                        style={{
                                          fontWeight: "600",
                                          fontSize: "11px",
                                        }}
                                      >
                                        {" "}
                                        <span className={"text-success"}>
                                          (
                                          {obj.currencyFormat(
                                            Math.abs(res?.profitAmount)
                                          )}
                                          )
                                        </span>
                                      </td>
                                    </>
                                  )}
                                  {type == "pl" && (
                                    <td
                                      style={{
                                        fontWeight: "600",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {" "}
                                      <span>
                                        {res?.isDeclared ? (
                                          <>
                                            {res?.decision !==
                                            res?.selectionId ? (
                                              <strong
                                                className="d-block"
                                                style={{ color: "red" }}
                                              >
                                                {startCase(res?.loseAmount)}
                                              </strong>
                                            ) : (
                                              <strong
                                                className="d-block"
                                                style={{ color: "green" }}
                                              >
                                                {startCase(res?.profitAmount)}
                                              </strong>
                                            )}
                                          </>
                                        ) : (
                                          <strong className="d-block">
                                            Pending
                                          </strong>
                                        )}
                                      </span>
                                    </td>
                                  )}
                                  {type !== "pl" && (
                                    <td
                                      style={{
                                        fontWeight: "600",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {" "}
                                      Pending
                                    </td>
                                  )}
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </td>
                    </tr>
                  )}
                </>
              );
            })
          ) : (
            <tr>
              <td colSpan={10}>
                <span>You have no bets in this time period.</span>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default BetListLottery;
