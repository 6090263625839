import React, { useState } from "react";
import { Table, Button, Modal } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { apiGet } from "../../utils/apiFetch";
import apiPath from "../../utils/apiPath";
import { useEffect } from "react";
import { isEmpty } from "lodash";
import helpers from "../../utils/helpers";
const BookFancy = ({ setBookPositionData, data }) => {
  const { eventid, selectionId } = useParams();
  const [detailsData, setDetailsData] = useState([]);
  const [search_params, setSearchParams] = useState({
    eventId: eventid,
    selectionId: selectionId,
  });

  const getDetails = async () => {
    const { status, data: response_users } = await apiGet(
      apiPath.fancyPositionAdmin,
      search_params
    );
    if (status === 200) {
      if (response_users.success) {
        if (response_users.results) {
          setDetailsData(response_users.results);
        }
      }
    }
  };

  useEffect(() => {
    setSearchParams({
      eventId: data?.item?.eventId,
      selectionId: data?.item?.selectionId,
    });
  }, [data]);

  useEffect(() => {
    getDetails();
  }, [search_params]);

  return (
    <Modal
      show={true}
      onHide={() => setBookPositionData({})}
    >
      <Modal.Body>
        <section className="account-table">
          <div className="responsive">
            <h2 className="common-heading">Run Position</h2>
            <Table >
              <thead>
                <tr>
                  <th scope="col">Run</th>
                  <th scope="col">Amount</th>
                </tr>
              </thead>
              <tbody>
                {detailsData?.bets?.length > 0 &&
                  detailsData?.bets?.map((res, index) => {
                    return (
                      <>
                        <tr>
                          <td
                            style={
                              res.position >= 0
                                ? { background: "#cde8fd" }
                                : { background: "#fae5eb" }
                            }
                          >
                            {res.betRun}
                          </td>
                          <td
                            style={
                              res.position >= 0
                                ? { background: "#cde8fd" }
                                : {
                                    background: "#fae5eb",
                                    color: "#d0021b",
                                  }
                            }
                          >
                            {res.position >= 0
                              ? `${helpers.currencyFormat(res.position)}`
                              : `(${helpers.currencyFormat(res.position)})`}
                          </td>
                        </tr>
                      </>
                    );
                  })}
                {isEmpty(detailsData) ? (
                  <tr>
                    <td colSpan={9}>No records found</td>
                  </tr>
                ) : null}
              </tbody>
            </Table>
          </div>
        </section>
      </Modal.Body>
    </Modal>
  );
};

export default BookFancy;