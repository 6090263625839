import { createContext, useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
import { compact, isEmpty, pick } from "lodash";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "wc-toast";
import { apiPost, apiGet } from "../utils/apiFetch";
import apiPath from "../utils/apiPath";
const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({ children }) => {
  let [user, setUser] = useState(() => {});
  const [newSidebar, setNewSideBar] = useState(false);
  const [comission, setComission] = useState(0);
  let [loading, setLoading] = useState(false);
  let [user_coins, setUserCoins] = useState(0.0);
  const [isRefereshLoader, setRefereshLoader] = useState(false);
  const [detailSlipObj, setDetailSlipObj] = useState({});
  const navigate = useNavigate();
  const location = useLocation();
  const [changePass, setChangePass] = useState(false);
  let loginUser = async (body) => {
    body.website = "admin.wifiexch.live";
    const { status, data } = await apiPost(
      apiPath.loginUser,
      pick(body, ["username", "password", "uniqueId", "website"])
    );

    if (status === 200) {
      if (data.success) {
        const token = data?.results?.token || null;
        const refresh_token = data?.results?.refresh_token || null;
        setUserCoins(data.results.totalCoins);
        localStorage.setItem("token", token);
        localStorage.setItem("refresh_token", refresh_token);
        setUser(jwt_decode(token));
        localStorage.setItem("uniqueId", body.uniqueId);
        navigate("/");
        // if (!data?.results?.isLoggedIn) {
        //   setChangePass(true);
        // }
      } else {
        toast.error(data.message);
      }
    } else {
      toast.error("API Error");
    }
  };

  const getCountNotification = async () => {
    const { data } = await apiGet(apiPath.notificationCount);
    if (data?.success) {
      setCount((prev) => {
        if (prev?.totalUnreadCount < data?.results?.totalUnreadCount) {
          setBellSound(true);
        }
        if (prev?.withdrawalRequests < data?.results?.withdrawalRequests) {
          setBellSound(true);
        }
        if (prev?.depositRequests < data?.results?.depositRequests) {
          setBellSound(true);
        }
        return data?.results;
      });
    }
  };

  useEffect(() => {
    if (
      !isEmpty(user) &&
      (user?.userType == "owner" || user?.userType == "sub_owner")
    ) {
      const intervalCall = setInterval(() => {
        getCountNotification();
      }, 5000);
      return () => {
        clearInterval(intervalCall);
      };
    }
  }, [user]);

  const getCoins = async () => {
    if (!isEmpty(user)) {
      setRefereshLoader(true);
      const { status, data } = await apiGet(
        apiPath.refreshAmount + "?userType=agent"
      );
      if (status === 200) {
        if (data.success) {
          setUserCoins(data.results.totalCoins);
          setRefereshLoader(false);
        } else {
          toast.error(data.message);
          setRefereshLoader(false);
        }
      } else {
        toast.error("API Error");
        setRefereshLoader(false);
      }
    }
  };

  const [count, setCount] = useState({});
  const [bellSound, setBellSound] = useState(false);
  const [agentId, setAgentId] = useState("");

  let logoutUser = () => {
    setUser(null);
    localStorage.removeItem("token");
    localStorage.removeItem("refresh_token");
    navigate("/login");
  };
  useEffect(() => {
    let data = localStorage.getItem("token")
      ? jwt_decode(localStorage.getItem("token"))
      : {};
    setUser(data);
  }, []);

  useEffect(() => {
    if (!isEmpty(user)) {
      getCoins();
      if (user?.userType == "owner" || user?.userType == "sub_owner") {
        getCountNotification();
      }
    }
  }, [user]);
  const user_params = compact(location.pathname.split("/"));
  let user_by_created = user?.id;
  let userType = user?.userType;
  if (!isEmpty(user_params[0])) {
    user_by_created = user_params[0];
  }
  if (!isEmpty(user_params[1])) {
    userType = user_params[1];
  }
  const getUsers = async () => {
    const { status, data: response_users } = await apiGet(
      apiPath.downLineList,
      {
        page: 1,
        limit: 100,
        keyword: "",
        status: "active",
        created_by: user_by_created,
        userType: userType,
      }
    );
    if (status === 200) {
      if (response_users.success) {
        if ("balance" in response_users?.results) {
          const balance_info = response_users?.results?.balance;
          setDetailSlipObj({
            totalAvailableLimit: balance_info.totalAvailableLimit,
            totalAmount: balance_info.totalAmount,
            totalCoins: balance_info.totalCoins,
            playerBalance: balance_info.playerBalance,
            availableLimit: balance_info.availableLimit,
            exposure: balance_info.exposure,
            playerExposure: balance_info.playerExposure,
            creditReference: balance_info.creditReference,
            upline:
              Math.abs(balance_info.creditReference) -
              Math.abs(balance_info.availableLimit),
            currentPL: balance_info.currentPL,
            totalRefrence: response_users?.results?.data
              ?.filter((res) => {
                return res?.creditReference > 0;
              })
              ?.reduce((acc, user) => {
                if (user?.creditReference > 0) {
                  const balance =
                    user?.userType == "user"
                      ? Math.sign(
                          user?.totalCoins1 + Math.abs(user?.exposure)
                        ) === -1
                        ? (
                            user?.totalCoins1 + Math.abs(user?.exposure)
                          ).toFixed(2)
                        : (
                            user?.totalCoins1 + Math.abs(user?.exposure)
                          ).toFixed(2)
                      : Math.sign(user?.totalCoins) === -1
                      ? user?.totalCoins.toFixed(2)
                      : user?.totalCoins.toFixed(2);
                  return (acc + user?.creditReference || 0) - balance;
                }
              }, 0),
          });
        }
      }
    }
  };

  useEffect(() => {
    if (!isEmpty(user)) {
      getUsers();
    }
  }, [user]);
  useEffect(() => {
    setNewSideBar(false);
  }, [location]);

  let contextData = {
    user: user,
    user_coins,
    loginUser: loginUser,
    logoutUser: logoutUser,
    getCoins,
    setUserCoins,
    setRefereshLoader: setRefereshLoader,
    isRefereshLoader: isRefereshLoader,
    setComission,
    comission,
    changePass,
    setChangePass,
    newSidebar,
    setNewSideBar,
    detailSlipObj,
    setDetailSlipObj,
    bellSound,
    setBellSound,
    count,
    setCount,
    agentId,
    setAgentId,
  };

  return (
    <AuthContext.Provider value={contextData}>
      {loading ? null : children}
    </AuthContext.Provider>
  );
};
