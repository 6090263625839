import { useContext } from "react";

import { Box, Typography, styled } from "@mui/material";
import { Search, MoreVert } from "@mui/icons-material";
import { emptyProfilePicture } from "../../../constants/data";
import { AccountContext } from "../../../../context/AccountProvider";
import { IoMdMore } from "react-icons/io";
import { Dropdown } from "react-bootstrap";
import { MdDeleteForever } from "react-icons/md";
const Header = styled(Box)`
  height: 44px;
  background: #ededed;
  display: flex;
  padding: 8px 16px;
  align-items: center;
`;

const Image = styled("img")({
  width: 30,
  height: 30,
  objectFit: "cover",
  borderRadius: "50%",
});

const Name = styled(Typography)`
  margin-left: 12px !important;
`;

const RightContainer = styled(Box)`
  margin-left: auto;
  & > svg {
    color: #000;
  }
`;

const Status = styled(Typography)`
  font-size: 11px !important;
  color: rgb(0, 0, 0, 0.6);
  margin-left: 12px !important;
`;

const ChatHeader = ({ person, select, deleteChat }) => {
  const url = emptyProfilePicture;

  return (
    <Header>
      <Image src={url} alt="display picture" />
      <Box>
        <Name>{person?.username || "Jatin"}</Name>
        <Status>{"Online"}</Status>
      </Box>
      <RightContainer>
        {select?.length > 0 && (
          <MdDeleteForever
            onClick={() => deleteChat(select)}
            color="red"
            size={20}
          />
        )}
        {/* <Dropdown className="whatsapp-dropdown">
          <Dropdown.Toggle
            style={{
              background: "transparent",
              border: "none",
              color: "black",
            }}
            id="dropdown-basic"
          >
            <IoMdMore size={20} />
          </Dropdown.Toggle>
          <Dropdown.Menu style={{ padding: "2px" }}>
            <Dropdown.Item
              style={{
                fontSize: "14px",
                color: "red",
                fontWeight: "500",
                display: "flex",
                alignItems: "center",
              }}
            >
              <RiDeleteBin5Line color="red" style={{ marginRight: "7px" }} />
              Clear Chat
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown> */}
      </RightContainer>
    </Header>
  );
};

export default ChatHeader;
