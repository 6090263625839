import React, { useState } from "react";
import { Link } from "react-router-dom";
import ViewBets from "./ViewBets";
import obj from "../../utils/constants";
const DropDown = ({
  showMatch,
  layData,
  backData,
  data,
  title,
  heading,
  res,
  user,
  setModalOuter,
  setObjectOuter,
}) => {
  const [modal, setModal] = useState(false);
  const [object, setObject] = useState({});
  const renderBackOdds = (selection) => {
    const the_odds = backData;
    let filter_odds = [];
    if (title == "Match Odds") {
      filter_odds = the_odds?.filter(
        (todd) => todd.ri === selection?.SelectionId
      );
    } else {
      filter_odds = the_odds?.filter(
        (todd) => todd.runnerName === selection?.runnerName
      );
    }

    return (
      <td className="back-1s p-0" style={{ cursor: "not-allowed" }}>
        <div className="light-blue rounded-0">
          <strong>{filter_odds[0]?.rt || "--"}</strong>
          <span className="d-block">{filter_odds[0]?.bv || "--"}</span>
        </div>
      </td>
    );
  };
  const renderLayOdds = (selection) => {
    const the_odds = layData;
    let filter_odds = [];
    if (title == "Match Odds") {
      filter_odds = the_odds?.filter(
        (todd) => todd.ri === selection?.SelectionId
      );
    } else {
      filter_odds = the_odds?.filter(
        (todd) => todd.runnerName === selection?.runnerName
      );
    }

    return (
      <td className="lay-1 p-0" style={{ cursor: "not-allowed" }}>
        <div className="lay-all rounded-0">
          <strong>{filter_odds[0]?.rt || "--"}</strong>
          <span className="d-block">{filter_odds[0]?.bv || "--"}</span>
        </div>
      </td>
    );
  };

  return (
    <tr>
      {title == "Toss" ? (
        <td colSpan="4" className="px-0 gray-inner_table">
          <table width={"100%"} className="selection-table w-100">
            <tbody className="">
              <tr>
                <td width="70%" className="text-start border-0 ">
                  {data?.length > 0 && data?.length} selections Selections
                </td>
                <td className="border-0 p-0" width="15%">
                  <div className="back-blue back-all-size">
                    <span>Back all</span>
                  </div>
                </td>
                <td className="border-0 p-0" width="15%">
                  <div className="lay-all back-all-size">
                    <span>Lay all</span>
                  </div>
                </td>
              </tr>
              {data?.length > 0 &&
                data?.map((res, index) => {
                  return (
                    <>
                      <tr key={index + 1}>
                        <td width="70%" className="border-start-0">
                          <a>
                            {" "}
                            <i className="far fa-chart-bar pe-2"></i>
                            <strong>
                              {" "}
                              {title == "Book Maker"
                                ? res?.runnerName
                                : res?.RunnerName}
                            </strong>
                          </a>
                        </td>
                        <td
                          className="back-1 p-0"
                          width="15%"
                          style={{ cursor: "not-allowed" }}
                        >
                          <div className="light-blue rounded-0">
                            <strong>0.95</strong>
                            <span className="d-block"></span>
                          </div>
                        </td>
                        <td
                          className="lay-1 p-0"
                          width="15%"
                          style={{ cursor: "not-allowed" }}
                        >
                          <div className="lay-all rounded-0">
                            <strong>0.95</strong>
                            <span className="d-block"></span>
                          </div>
                        </td>
                      </tr>
                    </>
                  );
                })}
                <tr>
              <td className="p-2" colSpan={4}>
                {" "}
                <Link
                  to="#"
                  onClick={() => {
                    setModalOuter(true);
                    setObjectOuter({
                      userId: user?._id,
                      userType: user?.userType,
                      id: res?.eventId,
                      title: title,
                    });
                  }}
                  className="green-btn"
                >
                  {title == "Fancy Bet" ? "Book" : "View"}
                </Link>
                <Link
                  to="#"
                  className="green-btn"
                  onClick={() => {
                    setObject({
                      id: res?.eventId,
                      userType: res?.eventName,
                      type: title,
                      gameType: obj.betCheckObjNew[res?.gameType],
                    });
                    setModal(true);
                  }}
                >
                  View Bets
                </Link>
              </td>
            </tr>
            </tbody>
          </table>
        </td>
      ) : (
        <table className="selection-table w-100 mb-2">
          <tbody>
            <tr>
              <td
                width="60%"
                className="text-start border-0"
                style={{ fontSize: "10px" }}
              >
                {data?.length > 0 && data?.length} Selections
              </td>
              <td width="18%" className="border-0 p-0">
                <div className="back-blue back-all-size">
                  <span>Back all</span>
                </div>
              </td>
              <td width="18%" className="border-0 p-0">
                <div className="lay-all back-all-size">
                  <span>Lay all</span>
                </div>
              </td>
            </tr>
            {data?.length > 0 &&
              data?.map((item, index) => {
                let position = res?.position?.find(
                  (res) =>
                    res?.selectionId == (item?.SelectionId || item?.selectionID)
                )?.position;
                return (
                  <tr key={index + 1}>
                    <td className="border-start-0">
                      <a>
                        {" "}
                        <i className="far fa-chart-bar pe-2"></i>
                        <strong>
                          {" "}
                          {title == "Book Maker"
                            ? item?.runnerName
                            : item?.RunnerName}
                        </strong>
                        <br />
                        <span
                          className={
                            Math.sign(position) == -1
                              ? "text-success p-1"
                              : "text-danger p-1"
                          }
                        >
                          ({Math.abs(position).toFixed(2)})
                        </span>
                      </a>
                    </td>
                    {title !== "Toss" && renderBackOdds(item)}
                    {title !== "Toss" && renderLayOdds(item)}
                  </tr>
                );
              })}
            <tr>
              <td className="p-2" colSpan={4}>
                {" "}
                <Link
                  to="#"
                  onClick={() => {
                    setModalOuter(true);
                    setObjectOuter({
                      userId: user?._id,
                      userType: user?.userType,
                      id: res?.eventId,
                      title: title,
                    });
                  }}
                  className="green-btn"
                >
                  {title == "Fancy Bet" ? "Book" : "View"}
                </Link>
                <Link
                  to="#"
                  className="green-btn"
                  onClick={() => {
                    setObject({
                      id: res?.eventId,
                      userType: res?.eventName,
                      type: title,
                      gameType: obj.betCheckObjNew[res?.gameType],
                    });
                    setModal(true);
                  }}
                >
                  View Bets
                </Link>
              </td>
            </tr>
          </tbody>
        </table>
      )}
      {modal && (
        <ViewBets
          onHide={() => {
            setModal(false);
            setObject({});
          }}
          setObject={setObject}
          show={modal}
          object={object}
        />
      )}
    </tr>
  );
};

export default DropDown;
