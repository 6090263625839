import React, { useContext, useEffect, useState } from "react";
import { Button, FloatingLabel, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import AuthContext from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import {
  FaUserAlt,
  FaLock,
  FaShieldAlt,
  FaEye,
  FaEyeSlash,
} from "react-icons/fa";
import { isEmpty } from "lodash";
import { Helmet } from "react-helmet";
const LoginNew = () => {
  const navigate = useNavigate();
  let { loginUser, user } = useContext(AuthContext);
  const handleShow = () => {
    setShowPassword(!showPassword);
  };
  const [showPassword, setShowPassword] = useState(true);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      username: "",
      password: "",
      uniqueId: Math.random() * 10000,
    },
  });

  const onSubmit = (data) => {
    loginUser(data);
  };

  useEffect(() => {
    if (!isEmpty(user)) {
      navigate("/");
    }
  }, [user]);

  const [logo, setLogo] = useState("assets/images/Logo_Transparent.png");

  useEffect(() => {

    // Get the domain name
    const domainName = window.location.hostname;
    // Dynamically set the logo based on the domain
    if (domainName.includes("anybett.live") || domainName.includes("anybett.live") || domainName.includes("admin.anybett.live")) {
      setLogo("assets/images/anybett.png");
    }else if (domainName.includes("aaexch.live") || domainName.includes("aaexch.live") || domainName.includes("admin.aaexch.live")) {
      setLogo("assets/images/aaexch.png");
    }
  }, []);

  return (
    <div class="loginbg">
     {/* <Helmet>
        <meta
          name="viewport"
          content="width=device-width, minimum-scale=1, maximum-scale = 1, user-scalable = no"
        />
      </Helmet> */}
      <div class="login">
        <div style={{ marginBottom: "40px" }}>
        <img className="login-logo" src={logo} />
        </div>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div class="form-group">
            <label style={{ marginBottom: "10px" }} for="email">
              User Name:
            </label>
            <Form.Group className="mb-2">
              <Form.Control
                type="text"
                placeholder="Username"
                className={errors.username ? "is-invalid" : ""}
                {...register("username", {
                  required: "Please enter Username",
                })}
              />
              {errors.username && errors.username.message && (
                <label className="invalid-feedback text-left">
                  {errors.username.message}
                </label>
              )}
            </Form.Group>
          </div>

          <div class="form-group">
            <label style={{ marginBottom: "10px" }} for="pwd">
              Password:
            </label>
            {/* <Form.Group className="mb-2">
              <Form.Control
                type="password"
                placeholder="Password"
                className={errors.password ? "is-invalid" : ""}
                {...register("password", {
                  required: "Please enter password",
                })}
              />
              {errors.password && errors.password.message && (
                <label className="invalid-feedback text-left">
                  {errors.password.message}
                </label>
              )}
            </Form.Group> */}


            <Form.Group className="mb-2 position-relative">
              {/* <FloatingLabel> */}
                <Form.Control
                  type={!showPassword ? "text" : "password"}
                  placeholder="Password"
                  maxLength={15}
                  {...register("password", {
                    required: "Please enter password",
                  })}
                  className={errors.password ? "is-invalid" : ""}
                />
                <Button
                  className="position-absolute translate-middle-y top-50 end-0 me-2 bg-transparent border-0 text-dark p-0 "
                  onClick={() => handleShow()}
                >
                  {!showPassword ? (
                    <FaEye className="opacity-100 fs-5" size="17" />
                  ) : (
                    <FaEyeSlash className="opacity-100 fs-5" size="17" />
                  )}
                </Button>
              {/* </FloatingLabel> */}
              {errors.password && errors.password.message && (
                <label className="invalid-feedback text-left">
                  {errors.password.message}
                </label>
              )}
            </Form.Group>
          </div>
          <div>
            <button type="submit" tabindex="3" class="text-center login-bt">
              LOGIN
            </button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default LoginNew;
