import React, { useState } from "react";
import { Table, Button, Modal } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { apiGet } from "../../utils/apiFetch";
import apiPath from "../../utils/apiPath";
import constants from "../../utils/constants";
import { useEffect } from "react";
import obj from "../../utils/constants";
import { isEmpty } from "lodash";
import { Helmet } from "react-helmet";
const ViewSportPremium = ({ object, onHide, setObject }) => {
  const params = useParams();
  const [isLoader, setLoader] = useState(false);
  const [detailsData, setDetailsData] = useState({});
  const [search_params, setSearchParams] = useState({
    eventId: object?.eventId,
    selectionId: object?.selectionId,
    marketId: object?.marketId,
    userId: object?.userId,
    userType: object?.userType,
    betType: object?.type == "Book Maker" ? "bookmaker" : "betFair",
  });

  const setFunc = (object) => {
    if (!isEmpty(object)) {
      setSearchParams({
        eventId: object?.eventId,
        selectionId: object?.selectionId,
        marketId: object?.marketId,
        userId: object?.userId,
        userType: object?.userType,
        betType: object?.type == "Book Maker" ? "bookmaker" : "betFair",
      });
    }
  };
  useEffect(() => {
    setFunc(object);
  }, [object]);

  useEffect(() => {
    getDetails();
  }, [search_params]);

  const getDetails = async () => {
    const { status, data: response_users } = await apiGet(
      apiPath.riskManagementSPortPremiumDetails +
        `?eventId=${search_params.eventId}&selectionId=${search_params.selectionId}&marketId=${search_params.marketId}&userType=${search_params.userType}&userId=${search_params.userId}`
    );
    if (status === 200) {
      if (response_users.success) {
        if (response_users.results) {
          setDetailsData(response_users.results);
        }
      }
    }
  };

  var first = 0;
  var third = 0;
  var second = 0;
  return (
    // <section className="main-inner-outer py-4">
    <Modal size="xl" show={true} onHide={onHide}>
      <Modal.Body>
        <section className="account-table">
          <div className="container-fluid">
            <div className="db-sec d-flex justify-content-between align-items-center mb-2">
              <h2 className="common-heading">
                {/* {detailsData?.eventName} ( {params?.type} ) */}
              </h2>
              <Button className="green-btn" onClick={() => onHide()}>
                Close
              </Button>
            </div>

            {detailsData?.length > 0 && (
              <div className="responsive">
                <Table>
                  <tbody>
                    <tr>
                      <td rowspan="2">
                        <strong> Player ID</strong>
                      </td>{" "}
                      <td rowspan="2">
                        <strong> Selection</strong>
                      </td>
                      <td rowspan="2">
                        <strong> Bet Amount</strong>
                      </td>{" "}
                      <td rowspan="2">
                        <strong> Profit Amount</strong>
                      </td>{" "}
                      <td rowspan="2">
                        <strong> Loss Amount</strong>
                      </td>
                    </tr>
                  </tbody>
                  {!isLoader ? (
                    <tbody className="new-match-tbody">
                      {detailsData &&
                        detailsData?.length > 0 &&
                        detailsData?.map((res) => {
                          return (
                            <>
                              <tr>
                                {/* <td
                                  className="text-start"
                                  // style={{ display: "flex", width: "100%" }}
                                >
                                  {search_params?.userType == "agent" ? (
                                    <>
                                      <a href={"#"} className="text-primary">
                                        <span>
                                          {
                                            constants?.user_status[
                                              res?.profileData?.userType || ""
                                            ]
                                          }
                                        </span>
                                      </a>
                                      {res?.profileData?.username || null}
                                    </>
                                  ) : (
                                    <Link
                                      to="#"
                                      onClick={() => {
                                        setObject({
                                          eventId: res?.eventId,
                                          selectionId: res?.selectionId,
                                          marketId: res?.marketId,
                                          userType: res?.profileData?.userType,
                                          userId: res?.profileData?._id,
                                        });
                                        setFunc({
                                          eventId: res?.eventId,
                                          selectionId: res?.selectionId,
                                          marketId: res?.marketId,
                                          userType: res?.profileData?.userType,
                                          userId: res?.profileData?._id,
                                        });
                                      }}
                                      // to={`/DownlinePnl-sport-premium/${res?.eventId}/${res?.selectionId}/${res?.marketId}/${res?.profileData?.userType}/${res?.profileData?._id}`}
                                    >
                                      <span>
                                        {
                                          constants?.user_status[
                                            res?.profileData?.userType || ""
                                          ]
                                        }
                                      </span>
                                      {res?.profileData?.username || null}
                                    </Link>
                                  )}
                                </td> */}

                                <td className="text-start">
                                  {res?.clientName}
                                </td>
                                <td class="text-start"> {res?.selectionId}</td>
                                <td class="text-start"> {res?.amount}</td>
                                <td class="text-start">
                                  <p class="text-success">
                                    ({res?.profitAmount?.toFixed(2)})
                                  </p>
                                </td>
                                <td class="text-start">
                                  <p class="text-danger">
                                    (-{res?.loseAmount?.toFixed(2)})
                                  </p>
                                </td>
                              </tr>
                            </>
                            // )
                          );
                        })}
                      {/* {detailsData?.length > 0 && (
                        <tr style={{ fontWeight: "600" }}>
                          <td
                            className="text-start"
                           
                          >
                            Total
                          </td>

                          <td class="border-0 bg-yellow">
                            <p class="text-success"> {second?.toFixed(2)}</p>
                          </td>
                          <td class="border-0 bg-yellow">
                            <p class="text-danger">(-{first?.toFixed(2)})</p>
                          </td>
                        </tr>
                      )} */}

                      {isEmpty(detailsData) ? (
                        <tr>
                          <td colSpan={9}>No records found</td>
                        </tr>
                      ) : null}
                    </tbody>
                  ) : (
                    <div
                      id="loader"
                      className="spinner"
                      style={{ display: `block` }}
                    ></div>
                  )}
                </Table>
              </div>
            )}
          </div>
        </section>
      </Modal.Body>
    </Modal>
    // </section>
  );
};

export default ViewSportPremium;
