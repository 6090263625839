import React, { useEffect, useState, useContext } from "react";
import { Container, Row, Col, Form, Table, Button } from "react-bootstrap";
import { apiGet } from "../utils/apiFetch";
import apiPath from "../utils/apiPath";
import { toast } from "wc-toast";
import { useLocation, useParams, Link, useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import constant from "../utils/constants";
import { compact, isEmpty, startCase } from "lodash";
import AuthContext from "../context/AuthContext";
import helpers from "../utils/helpers";
import moment from "moment";

const Adownlinesportspl = () => {
  const parmas = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const user_params = compact(location.pathname.split("/"));
  let { user } = useContext(AuthContext);
  const [pageCount, setPageCount] = useState(0);
  const [getProperty, setProperty] = useState("none");
  var previousDate = moment().subtract(1, "days").format("YYYY-MM-DD");
  var currentDate = moment().format("YYYY-MM-DD");
  const [matchData, setMatchData] = useState("");
  const [viewpage, setViewPage] = useState(0);
  const [getEventId, setEventId] = useState("");
  const [matchBetsData, setMatchBetsData] = useState("");
  const [betsObj, setBetsObj] = useState({});
  const [obj, setObj] = useState({});
  const [filter, setFilter] = useState({
    page: 1,
    page_size: 10,
    fromPeriod: previousDate,
    toPeriod: currentDate,
    filterByDay: "",
    created_by: "",
    userType: user?.userType,
    eventType: "4",
    keyword: "",
  });

  const handlePageClick = (event) => {
    setFilter((prevState) => {
      return {
        ...prevState,
        page: event.selected + 1,
      };
    });
  };

  useEffect(() => {
    setFilter((prevState) => {
      return {
        ...prevState,
        created_by: parmas?.id,
        userType: parmas?.user_type,
      };
    });
  }, [parmas]);
  const [data, setData] = useState([]);

  const getData = async (type = "search") => {
    if (filter.filterByDay != "") {
      if (filter.filterByDay == "today") {
        filter.fromPeriod = currentDate;
        filter.toPeriod = currentDate;
      }
      if (filter.filterByDay == "yesterday") {
        filter.fromPeriod = previousDate;
        filter.toPeriod = currentDate;
      }
    }
    let path = apiPath.reportMatch;

    try {
      const { status, data: response_users } = await apiGet(
        path,
        type == "search" ? filter : type
      );
      if (status === 200) {
        if (response_users.success) {
          setMatchData(response_users.results?.data);
          setData(
            response_users.results.data.sort(function (a, b) {
              if (a.eventType < b.eventType) {
                return -1;
              }
              if (a.eventType > b.eventType) {
                return 1;
              }
              return 0;
            })
          );
          setPageCount(response_users.results.totalPages);
          // console.log(response_users?.results?.data, "res");
          const PnlPlus = response_users?.results?.data?.reduce((a, v) => {
            if (Math.sign(v.amount) === 1) {
              a = parseFloat(a) + parseFloat(v.amount);
            }
            return a;
          }, 0);
          const PnlMinus = response_users?.results?.data?.reduce((a, v) => {
            if (Math.sign(v.amount) === -1) {
              a = parseFloat(a) + parseFloat(v?.amount);
            }

            return a;
          }, 0);

          var finalPnl = 0;
          if (PnlPlus > PnlMinus) {
            finalPnl = PnlPlus + PnlMinus;
          } else {
            finalPnl = PnlMinus + PnlPlus;
          }
          console.log();

          const commission = 0.0;
          setObj({
            PnlPlus: PnlPlus || 0,
            PnlMinus: PnlMinus || 0,
            commission: commission || 0,
            finalPnl: finalPnl,
          });
        }
      }
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };

  useEffect(() => {
    getData();
    setViewPage(filter.page ? filter.page - 1 : 0);
  }, []);
  useEffect(() => {
    getData();
    setViewPage(filter.page ? filter.page - 1 : 0);
  }, [filter?.eventType]);

  const getMatchBetsData = async (eventId) => {
    let path = apiPath.matchBet;

    try {
      const { status, data: response_users } = await apiGet(path, {
        eventId: eventId,
      });
      if (status === 200) {
        if (response_users.success) {
          setMatchBetsData(response_users.results?.data);

          const PnlPlus = response_users?.results?.data?.reduce((a, v) => {
            a = parseFloat(a) + parseFloat(v.amount);
            return a;
          }, 0);
          // console.log("PnlPlus",PnlPlus);

          setBetsObj({
            totalAmount: PnlPlus || 0.0,
          });
        }
      }
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };
  useEffect(() => {
    if (getEventId) {
      getMatchBetsData(getEventId);
    }
  }, [getEventId]);

  return (
    <div>
      <section className="main-inner-outer py-4">
        <Container fluid>
          <Row>
            <div className="db-sec">
              <h2 className="common-heading">Profit/Loss</h2>
            </div>
            {getEventId == "" ? (
              <>
                <Col md={12}>
                  <div className="inner-wrapper">
                    <Form className="bet_status">
                      <Row>
                        <Col xl={12} md={12}>
                          <Row>
                            <Col
                              lg={3}
                              sm={6}
                              className="mb-lg-0 mb-3 flex-grow-0 pe-3"
                            >
                              <div className="bet-sec bet-period">
                                <Form.Label className="px-2">From</Form.Label>
                                <Form.Group className="form-group">
                                  <Form.Control
                                    className="small_form_control"
                                    onChange={(e) =>
                                      setFilter({
                                        ...filter,
                                        fromPeriod: e.target.value,
                                        filterByDay: "",
                                      })
                                    }
                                    max={new Date().toISOString().split("T")[0]}
                                    value={filter.fromPeriod}
                                    type="date"
                                  />
                                  <Form.Control
                                    className="small_form_control"
                                    type="text"
                                    placeholder="00:00"
                                    disabled
                                  />
                                </Form.Group>
                              </div>
                            </Col>
                            <Col
                              lg={3}
                              sm={6}
                              className="mb-lg-0 mb-3 flex-grow-0 ps-3"
                            >
                              <div className="bet-sec bet-period">
                                <Form.Label className="px-2">To</Form.Label>
                                <Form.Group className="form-group">
                                  <Form.Control
                                    className="small_form_control"
                                    onChange={(e) =>
                                      setFilter({
                                        ...filter,
                                        toPeriod: e.target.value,
                                        filterByDay: "",
                                      })
                                    }
                                    min={
                                      filter?.fromPeriod
                                        ? new Date(filter?.fromPeriod)
                                            .toISOString()
                                            .split("T")[0]
                                        : new Date()
                                    }
                                    max={new Date().toISOString().split("T")[0]}
                                    value={filter.toPeriod}
                                    type="date"
                                  />
                                  <Form.Control
                                    className="small_form_control"
                                    type="text"
                                    placeholder="23:59"
                                    disabled
                                  />
                                </Form.Group>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <div className="history-btn mt-2">
                        <ul className="list-unstyled mb-0">
                          <li>
                            <Button
                              className={
                                filter.filterByDay === "today"
                                  ? "me-0 theme_dark_btn"
                                  : "me-0 theme_light_btn"
                              }
                              onClick={(e) =>
                                setFilter({
                                  ...filter,
                                  filterByDay: "today",
                                })
                              }
                            >
                              Just For Today
                            </Button>
                          </li>
                          <li>
                            <Button
                              className={
                                filter.filterByDay === "yesterday"
                                  ? "me-0 theme_dark_btn"
                                  : "me-0 theme_light_btn"
                              }
                              onClick={(e) =>
                                setFilter({
                                  ...filter,
                                  filterByDay: "yesterday",
                                })
                              }
                            >
                              From Yesterday
                            </Button>
                          </li>
                          <li>
                            <Button
                              className="me-0 theme_light_btn theme_dark_btn"
                              onClick={() => getData("search")}
                            >
                              Search
                            </Button>
                          </li>
                          <li>
                            <Button
                              className="me-0 theme_light_btn"
                              onClick={() => {
                                setFilter({
                                  page: 1,
                                  page_size: 10,
                                  fromPeriod: "",
                                  toPeriod: "",
                                  filterByDay: "",
                                  created_by: "",
                                  userType: "",
                                });
                                getData({
                                  page: 1,
                                  page_size: 10,
                                  fromPeriod: "",
                                  toPeriod: "",
                                  filterByDay: "",
                                  created_by: "",
                                  userType: "",
                                });
                              }}
                            >
                              Reset
                            </Button>
                          </li>
                        </ul>
                      </div>
                    </Form>
                  </div>
                </Col>
                <Col md={12}>
                  <div className="inner-wrapper">
                    <button
                      onClick={(e) =>
                        setFilter({
                          ...filter,
                          eventType: "4",
                        })
                      }
                      className="btn-result"
                    >
                      Cricket
                    </button>
                    <button
                      onClick={(e) =>
                        setFilter({
                          ...filter,
                          eventType: "1",
                        })
                      }
                      className="btn-result"
                    >
                      Soccer
                    </button>
                    <button
                      onClick={(e) =>
                        setFilter({
                          ...filter,
                          eventType: "2",
                        })
                      }
                      className="btn-result"
                    >
                      Tennis
                    </button>
                    <Form.Control
                      type="text"
                      placeholder="Search"
                      onChange={(e) => {
                        setFilter({
                          ...filter,
                          keyword: e.target.value.replace(/\s+$/, ""),
                        });
                      }}
                      style={{ maxWidth: `17%` }}
                    />
                  </div>
                </Col>
              </>
            ) : (
              ""
            )}
            <Col md={12} sm={12} lg={12} className="mt-2">
              <div className="inner-wrapper">
                <section className="account-table donwline-match-pl w-100">
                  <div className="responsive transaction-history w-100">
                    {getEventId == "" ? (
                      <>
                        <Table>
                          <thead>
                            <tr>
                              <th scope="col"> S.No.</th>
                              <th scope="col">Sport Name</th>
                              <th scope="col">Match Name</th>
                              <th scope="col">Match Date.</th>
                              <th scope="col">Pnl+</th>
                              <th scope="col">Pnl-</th>
                              <th scope="col">Commission</th>
                              <th scope="col">Final P&L</th>
                            </tr>
                          </thead>
                          <tbody>
                            <>
                              {data?.length > 0 && (
                                <>
                                  <td>&nbsp;</td>
                                  <td>&nbsp;</td>
                                  <td className="text-start">Total</td>
                                  <td>&nbsp;</td>
                                  <td>
                                    <span className="text-danger back-trans">
                                      {helpers.currencyFormat(
                                        -Math.abs(obj?.PnlPlus)
                                      )}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="text-success back-trans">
                                      {helpers.currencyFormat(
                                        Math.abs(obj?.PnlMinus)
                                      )}
                                    </span>
                                  </td>
                                  <td>
                                    {helpers.currencyFormat(obj?.commission)}
                                  </td>
                                  <td>
                                    {Math.sign(obj?.finalPnl) === 1 ? (
                                      <span className="text-danger back-trans">
                                        {helpers.currencyFormat(
                                          -Math.abs(obj?.finalPnl)
                                        )}
                                      </span>
                                    ) : (
                                      <span className="text-success back-trans">
                                        (
                                        {helpers.currencyFormat(
                                          Math.abs(obj?.finalPnl)
                                        )}
                                        )
                                      </span>
                                    )}
                                  </td>
                                </>
                              )}
                              {data && data?.length > 0 ? (
                                data?.map((item, index) => {
                                  return (
                                    <>
                                      <tr key={index + 1}>
                                        <>
                                          <td className="text-start">
                                            {index + 1}
                                          </td>
                                          <td>
                                            {item?.eventType === "4"
                                              ? "Cricket"
                                              : item?.eventType === "1"
                                              ? "Soccer"
                                              : item?.eventType === "2"
                                              ? "Tennis"
                                              : ""}
                                          </td>
                                          <td>
                                            <Link
                                              onClick={(e) =>
                                                setEventId(item?.eventId)
                                              }
                                            >
                                              {" "}
                                              {item?.matchName}
                                            </Link>
                                          </td>
                                          <td>
                                            {helpers.dateFormat(
                                              item?.eventDateTime
                                            )}
                                          </td>
                                          <td>
                                            {Math.sign(item?.amount) === 1 ? (
                                              <span className="text-danger">
                                                {helpers.currencyFormat(
                                                  -Math.abs(item?.amount)
                                                )}
                                              </span>
                                            ) : (
                                              "0.00"
                                            )}
                                          </td>
                                          <td>
                                            {Math.sign(item?.amount) === 1 ? (
                                              "0.00"
                                            ) : (
                                              <span className="text-success">
                                                {helpers.currencyFormat(
                                                  Math.abs(item?.amount)
                                                )}
                                              </span>
                                            )}
                                          </td>
                                          <td>{"0.00"}</td>
                                          <td>
                                            {" "}
                                            {Math.sign(item?.amount) === 1 ? (
                                              <span className="text-danger">
                                                {helpers.currencyFormat(
                                                  -Math.abs(item?.amount)
                                                )}
                                              </span>
                                            ) : (
                                              <span className="text-success">
                                                {helpers.currencyFormat(
                                                  Math.abs(item?.amount)
                                                )}
                                              </span>
                                            )}
                                          </td>
                                        </>
                                      </tr>
                                    </>
                                  );
                                })
                              ) : (
                                <tr>
                                  <td colSpan={8}>
                                    <span>
                                      You have no bets in this time period.
                                    </span>
                                  </td>
                                </tr>
                              )}
                            </>
                          </tbody>
                        </Table>
                        <div className="bottom-pagination">
                          <ReactPaginate
                            breakLabel="..."
                            nextLabel=" >"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={10}
                            pageCount={pageCount}
                            previousLabel="< "
                            renderOnZeroPageCount={null}
                            activeClassName="p-0"
                            activeLinkClassName="pagintion-li"
                          />
                        </div>
                      </>
                    ) : (
                      <Table>
                        <thead>
                          <tr>
                            <th scope="col">Title</th>
                            <th scope="col">P&L</th>
                            <th scope="col">Com+</th>
                            <th scope="col">Com-</th>
                            <th scope="col">Won By</th>
                            <th scope="col">Net P&L</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <>
                            {matchBetsData?.length > 0 && (
                              <>
                                <td className="text-start">Total</td>
                                <td>
                                  {Math.sign(betsObj?.totalAmount) === 1 ? (
                                    <span className="text-danger back-trans">
                                      {helpers.currencyFormat(
                                        -Math.abs(betsObj?.totalAmount)
                                      )}
                                    </span>
                                  ) : (
                                    <span className="text-success back-trans">
                                      (
                                      {helpers.currencyFormat(
                                        Math.abs(betsObj?.totalAmount)
                                      )}
                                      )
                                    </span>
                                  )}
                                </td>

                                <td>
                                  <span className="text-success back-trans">
                                    {" "}
                                    0.00
                                  </span>
                                </td>
                                <td>
                                  <span className="text-danger back-trans">
                                    0.00
                                  </span>
                                </td>
                                <td></td>
                                <td>
                                  {Math.sign(betsObj?.totalAmount) === 1 ? (
                                    <span className="text-danger back-trans">
                                      {helpers.currencyFormat(
                                        -Math.abs(betsObj?.totalAmount)
                                      )}
                                    </span>
                                  ) : (
                                    <span className="text-success back-trans">
                                      (
                                      {helpers.currencyFormat(
                                        Math.abs(betsObj?.totalAmount)
                                      )}
                                      )
                                    </span>
                                  )}
                                </td>
                                <td></td>
                              </>
                            )}
                            {matchBetsData?.length > 0 ? (
                              matchBetsData?.map((item, index) => {
                                return (
                                  <>
                                    <tr key={index + 1}>
                                      <>
                                        <td className="text-start">
                                          {item?.matchName} {">"}{" "}
                                          {item?.runnerName}
                                          {item?.market
                                            ? ">" + item?.market
                                            : ""}
                                        </td>
                                        <td>
                                          {Math.sign(item?.amount) === 1 ? (
                                            <span className="text-danger">
                                              {helpers.currencyFormat(
                                                -Math.abs(item?.amount)
                                              )}
                                            </span>
                                          ) : (
                                            <span className="text-success">
                                              (
                                              {helpers.currencyFormat(
                                                Math.abs(item?.amount)
                                              )}
                                              )
                                            </span>
                                          )}
                                        </td>
                                        <td>
                                          <span className="text-success">
                                            0.00
                                          </span>
                                        </td>
                                        <td>
                                          <span className="text-danger">
                                            0.00
                                          </span>
                                        </td>
                                        <td>{item?.winner}</td>
                                        <td>
                                          {Math.sign(item?.amount) === 1 ? (
                                            <span className="text-danger">
                                              {helpers.currencyFormat(
                                                -Math.abs(item?.amount)
                                              )}
                                            </span>
                                          ) : (
                                            <span className="text-success">
                                              (
                                              {helpers.currencyFormat(
                                                Math.abs(item?.amount)
                                              )}
                                              )
                                            </span>
                                          )}
                                        </td>
                                        <td class="">
                                          <a
                                            onClick={() =>
                                              navigate(
                                                `/MatchBetDetails/${item.betFaireType}/${item.eventId}/${item.marketId}/${item.selectionId}`
                                              )
                                            }
                                          >
                                            <button class="btn-send">
                                              Show Bets
                                            </button>
                                          </a>
                                        </td>
                                      </>
                                    </tr>
                                  </>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan={6}>
                                  <span>
                                    You have no bets in this time period.
                                  </span>
                                </td>
                              </tr>
                            )}
                          </>
                        </tbody>
                      </Table>
                    )}
                  </div>
                </section>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default Adownlinesportspl;
