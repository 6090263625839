import React, { useState } from "react";
import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { FaPlusSquare } from "react-icons/fa";
import { FaMinusSquare } from "react-icons/fa";
import { LiaWindowClose } from "react-icons/lia";
import DetailSlip from "../components/DetailSlip";
const CasinoControl = () => {
  const [dropdown, setDropdown] = useState(false);
  const [dropdownEvo, setDropdownEvo] = useState(false);
  const [dropdownAviator, setDropdownAviator] = useState(false);
  const [dropdownEzugi, setDropdownEzugi] = useState(false);
  const [dropdownVivo, setDropdownVivo] = useState(false);
  const [dropdownLive, setDropdownLive] = useState(false);
  const [dropdownAsia, setDropdownAsia] = useState(false);
  return (
    <section className="find-member-sec">
      {/* <DetailSlip /> */}
     {/* <Helmet>
        <meta name="viewport" content="width=device-width,  initial-scale=.2" />
      </Helmet> */}
      <Container fluid>
        <Row className="mb-md-0 mb-3 p-3">
          <div className="casino-control-outer">
            <p>Providers -</p>
            <div className="casino-control">
              <div>
                <span>Indian Poker</span>
                <input type="radio" />
              </div>
              <div>
                <span>Evolution</span>
                <input type="radio" />
              </div>
              <div>
                <span>Aviator</span>
                <input type="radio" />
              </div>
              <div>
                <span>Vivo</span>
                <input type="radio" />
              </div>
              {/* <div>
                <span>Bet Games</span>
                <input type="radio" />
              </div> */}
              <div>
                <span>Ezugi</span>
                <input type="radio" />
              </div>
              <div>
                <span>Live</span>
                <input type="radio" />
              </div>
              <div>
                <span>Asia Gamming</span>
                <input type="radio" />
              </div>
            </div>
          </div>
        </Row>
        <Row className="mb-md-0 mb-3 p-3">
          <div className="casino-control-outer">
            <p>Casino Control -</p>
            <div className="casino-control-bottom-outer">
              <div className="casino-control-bottom">
                {dropdown ? (
                  <FaMinusSquare
                    onClick={() => setDropdown(!dropdown)}
                    style={{ marginRight: "10px" }}
                    size={18}
                  />
                ) : (
                  <FaPlusSquare
                    onClick={() => setDropdown(!dropdown)}
                    style={{ marginRight: "10px" }}
                    size={18}
                  />
                )}
                <span>Indain Poker</span>
              </div>
              {dropdown && (
                <div className="casino-control-bottom-detail">
                  <div>
                    <LiaWindowClose size={20} />
                    <span>7 Up & 7 Down</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Teenpati</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Teenpati T20</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Dragon Tiger</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Higher Lower</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Worli Matka</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Poker</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>32 Card Casion</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Andhar Bahar</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Amar Akbar Anthoy</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Race 20-20</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Bollywood Casino</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Six Player Poker</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Roulette</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Sicbo</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Muflis Teenpati</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Trio</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Baccarat</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Casino Meter</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>20-20 Poker</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>2 Card Teenpati</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Casino War</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>The Trap</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Open Teenpati</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>29 Card Baccarat</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Race to 17</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Roulette</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Daragon Tiger</span>
                    <input type="radio" />
                  </div>
                </div>
              )}
            </div>
            <div className="casino-control-bottom-outer">
              <div className="casino-control-bottom">
                {dropdownEvo ? (
                  <FaMinusSquare
                    onClick={() => setDropdownEvo(!dropdownEvo)}
                    style={{ marginRight: "10px" }}
                    size={18}
                  />
                ) : (
                  <FaPlusSquare
                    onClick={() => setDropdownEvo(!dropdownEvo)}
                    style={{ marginRight: "10px" }}
                    size={18}
                  />
                )}
                <span>Evolution</span>
              </div>
              {dropdownEvo && (
                <div className="casino-control-bottom-detail">
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Evolution</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Sicbo</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Football French Roulette</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Casino Hold'm</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Baccarat Live</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Andhar Bahar</span>
                    <input type="radio" />
                  </div>

                  <div>
                    <LiaWindowClose size={20} />
                    <span>Lightning Baccarat First Person</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Speed Auto Roulette</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Cash or Crash</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Mega Ball</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Auto Roulette</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Speed Auto Roulette</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Roulette</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Triple Card Poker</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Lightning Dice</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Dragon Tiger</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Casino Hold'em</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Roulette</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Lightning Lotto</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>King Slot</span>
                    <input type="radio" />
                  </div>
                </div>
              )}
            </div>
            <div className="casino-control-bottom-outer">
              <div className="casino-control-bottom">
                {dropdownAviator ? (
                  <FaMinusSquare
                    onClick={() => setDropdownAviator(!dropdownAviator)}
                    style={{ marginRight: "10px" }}
                    size={18}
                  />
                ) : (
                  <FaPlusSquare
                    onClick={() => setDropdownAviator(!dropdownAviator)}
                    style={{ marginRight: "10px" }}
                    size={18}
                  />
                )}
                <span>Aviator</span>
              </div>
              {dropdownAviator && (
                <div className="casino-control-bottom-detail">
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Aviator</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Keno</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Mini Roulette</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Hilo</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Dice</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Mines</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Plinko</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Goal</span>
                    <input type="radio" />
                  </div>
                </div>
              )}
            </div>
            <div className="casino-control-bottom-outer">
              <div className="casino-control-bottom">
                {dropdownEzugi ? (
                  <FaMinusSquare
                    onClick={() => setDropdownEzugi(!dropdownEzugi)}
                    style={{ marginRight: "10px" }}
                    size={18}
                  />
                ) : (
                  <FaPlusSquare
                    onClick={() => setDropdownEzugi(!dropdownEzugi)}
                    style={{ marginRight: "10px" }}
                    size={18}
                  />
                )}
                <span>Ezugi</span>
              </div>
              {dropdownEzugi && (
                <div className="casino-control-bottom-detail">
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Ezugi</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Ultimate Sicbo</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Sicbo</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Grand Wheel</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Deada or Alive Saloon</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>32 Cards</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Andhar Bahar</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Teenpatti</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Speed Auto Roulette</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Ultimate Blackjack</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Keno</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Namaste Roulette</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>VIP Blackjack</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Lucky</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Diamond Roulette</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Devli's Number</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Atlantis Rising</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Mega Dragon</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Lucky Wizard</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>VIP Fortune Baccarat</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Baccarat Pro</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Royal Poker</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Cricket War</span>
                    <input type="radio" />
                  </div>
                </div>
              )}
            </div>
            <div className="casino-control-bottom-outer">
              <div className="casino-control-bottom">
                {dropdownVivo ? (
                  <FaMinusSquare
                    onClick={() => setDropdownVivo(!dropdownVivo)}
                    style={{ marginRight: "10px" }}
                    size={18}
                  />
                ) : (
                  <FaPlusSquare
                    onClick={() => setDropdownVivo(!dropdownVivo)}
                    style={{ marginRight: "10px" }}
                    size={18}
                  />
                )}
                <span>Vivo</span>
              </div>
              {dropdownVivo && (
                <div className="casino-control-bottom-detail">
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Vivo</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Baccarat</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Casino Hold'm</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Dragon Tiger</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Queence Baccarat</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Baccarat Live</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Sunmaceu Baccarat</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Lobby Blackjack</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Spin</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Baccarat 1</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Baccarat 2</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Baccarat 3</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Baccarat 4</span>
                    <input type="radio" />
                  </div>
                </div>
              )}
            </div>
            {/* <div className="casino-control-bottom-outer">
              <div className="casino-control-bottom">
                {dropdown ? (
                  <FaMinusSquare
                    onClick={() => setDropdown(!dropdown)}
                    style={{ marginRight: "10px" }}
                    size={18}
                  />
                ) : (
                  <FaPlusSquare
                    onClick={() => setDropdown(!dropdown)}
                    style={{ marginRight: "10px" }}
                    size={18}
                  />
                )}
                <span>Bet Games</span>
              </div>
              {dropdown && (
                <div className="casino-control-bottom-detail">
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Bet Games</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Andhar Bahar</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Baccarat</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Bet On Poker</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>DIce Duel</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Lucky 5</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Luck 6</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Luck 7</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Luck 6+</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Satta Matka</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Speedy 7</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>War oF Bets</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Wheel oF Fortune</span>
                    <input type="radio" />
                  </div>
                </div>
              )}
            </div> */}
            <div className="casino-control-bottom-outer">
              <div className="casino-control-bottom">
                {dropdownAsia ? (
                  <FaMinusSquare
                    onClick={() => setDropdownAsia(!dropdownAsia)}
                    style={{ marginRight: "10px" }}
                    size={18}
                  />
                ) : (
                  <FaPlusSquare
                    onClick={() => setDropdownAsia(!dropdownAsia)}
                    style={{ marginRight: "10px" }}
                    size={18}
                  />
                )}
                <span>Asia Gamming</span>
              </div>
              {dropdownAsia && (
                <div className="casino-control-bottom-detail">
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Asia Gamming</span>
                    <input type="radio" />
                  </div>
                </div>
              )}
            </div>
            <div className="casino-control-bottom-outer">
              <div className="casino-control-bottom">
                {dropdownLive ? (
                  <FaMinusSquare
                    onClick={() => setDropdownLive(!dropdownLive)}
                    style={{ marginRight: "10px" }}
                    size={18}
                  />
                ) : (
                  <FaPlusSquare
                    onClick={() => setDropdownLive(!dropdownLive)}
                    style={{ marginRight: "10px" }}
                    size={18}
                  />
                )}
                <span>Live</span>
              </div>
              {dropdownLive && (
                <div className="casino-control-bottom-detail">
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Plinko</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Cricket Baccarat</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Baccarat Live</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Cappadqcia</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Baccarat Pro</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Fiesta Baccarat</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Super 6 Baccarat</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Dragon Tiger</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Cricket War</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Baccarat 1</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Baccarat 10</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Baccarat 3</span>
                    <input type="radio" />
                  </div>
                  <div>
                    <LiaWindowClose size={20} />
                    <span>Blackjack 5</span>
                    <input type="radio" />
                  </div>
                </div>
              )}
            </div>
          </div>
        </Row>
      </Container>
    </section>
  );
};

export default CasinoControl;
