import React, { useState } from "react";
import { Table, Button, Modal } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { apiGet } from "../../utils/apiFetch";
import apiPath from "../../utils/apiPath";
import { useEffect } from "react";
import obj from "../../utils/constants";
import { isEmpty } from "lodash";
import helpers from "../../utils/helpers";
import { Helmet } from "react-helmet";
const ViewBets = ({ onHide, setObject, show, object }) => {
  const params = useParams();
  const [data, setData] = useState([]);
  const [getBetType, setBetType] = useState("betfair");
  const [getType, setType] = useState("4");
  const [detail, setDetail] = useState({});
  const [filter, setFilter] = useState({
    type: "4",
    betType: "betfair",
    status: "active",
    eventId: "",
  });
  const getData = async (type = "search") => {
    const { status, data: response_users } = await apiGet(
      apiPath.getBetslive,
      type == "search" ? filter : type
    );
    if (status === 200) {
      if (response_users.success) {
        setBetType(filter.betType);
        setType(filter.type);
        setData(response_users?.results);
      }
    }
  };

  useEffect(() => {
    setDetail(object);
    setFilter((prev) => {
      return {
        ...prev,
        eventId: object?.userId,
        betType:
          object?.type == "Match Odds"
            ? "betfair"
            : object?.type == "Fancy Bet"
            ? "fancy"
            : object?.type == "Book Maker"
            ? "bookmaker"
            : object?.type == "Toss"
            ? "toss"
            : "",
        type: object?.gameType || 4,
        selectionId: object?.selectionId || "",
      };
    });
    getData({
      ...filter,
      eventId: object?.userId,
      betType:
        object?.type == "Match Odds"
          ? "betfair"
          : object?.type == "Fancy Bet"
          ? "fancy"
          : object?.type == "Book Maker"
          ? "bookmaker"
          : object?.type == "Toss"
          ? "toss"
          : "",
      type: object?.gameType || 4,
      selectionId: object?.selectionId || "",
    });
  }, [object]);

  useEffect(() => {
    const intervalID = setInterval(() => {
      getData({
        ...filter,
        eventId: detail?.userId,
        betType:
          detail?.type == "Match Odds"
            ? "betfair"
            : detail?.type == "Fancy Bet"
            ? "fancy"
            : detail?.type == "Book Maker"
            ? "bookmaker"
            : detail?.type == "Toss"
            ? "toss"
            : "",
        type: detail?.gameType || 4,
        selectionId: detail?.selectionId || "",
      });
    }, 20 * 1000);

    return () => clearInterval(intervalID);
  }, [detail]);
  return (
    <Modal size="xl" show={true} centered onHide={onHide}>
      <Modal.Body>
        <section className="account-table">
          <div className="container-fluid">
            <div className="db-sec d-flex justify-content-between align-items-center mb-2">
              <h2 className="common-heading">
                {detail?.userType} ({detail?.type})
              </h2>
              <Button className="green-btn" onClick={() => onHide()}>
                X
              </Button>
            </div>
            <div className="responsive">
              <Table>
                <thead>
                  <tr>
                    <th scope="col">PL ID</th>
                    <th scope="col"> Bet ID</th>
                    <th scope="col">Bet placed</th>
                    <th scope="col">IP Address </th>
                    <th scope="col">Market</th>
                    <th scope="col">Selection</th>
                    <th scope="col">Type</th>
                    {filter?.betType != "casino" && (
                      <th scope="col">Odds req.</th>
                    )}

                    <th scope="col">Stake </th>

                    {filter?.betType != "casino" && (
                      <>
                        <th scope="col">Liability</th>
                        <th scope="col"> Profit/Loss</th>
                      </>
                    )}
                  </tr>
                </thead>
                {filter?.betType != "casino" && data && data?.length > 0
                  ? data?.map((item) => {
                      if (item.isMatched || !isEmpty(item?.fancyName)) {
                        return (
                          <tr>
                            <td>{item?.clientName || "-"}</td>
                            <td>
                              {" "}
                              {getType === "3"
                                ? item?.casinoBetId
                                : filter?.betType === "fancy"
                                ? item?.sessionBetId
                                : item?.matchBetId || "-"}
                            </td>
                            <td> {helpers.dateFormat(item?.timeInserted)}</td>
                            <td>
                              {" "}
                              {(item?.ipAddress &&
                                item?.ipAddress.replace("::ffff:", "")) ||
                                "-"}
                            </td>
                            <td className="text-start">
                            <div style={{padding:"1px"}}>
                                <span>
                                  {obj.betCheckObj[Number(item?.eventType)]}
                                </span>
                                <span className="angle_unicode">▸</span>
                                <strong>
                                  {getType === "3"
                                    ? item?.casinoName
                                    : item?.matchName}
                                </strong>
                                <span className="angle_unicode">▸</span>
                                <span>
                                  {" "}
                                  {filter?.betType === "betfair" &&
                                    "Match Odds"}{" "}
                                  {filter?.betType === "bookmaker" &&
                                    "Book Maker"}
                                  {filter?.betType === "fancy" &&
                                    item?.fancyName}
                                </span>
                              </div>
                            </td>
                            <td>
                              {filter?.betType === "fancy"
                                ? item?.betRun + "/" + item?.bhav
                                : filter?.betType === "sportBook"
                                ? item?.runnerName
                                : filter?.betType === "casino"
                                ? item?.platformTxId
                                : item?.teamName}
                            </td>
                            <td> {item?.betType || item?.type} </td>
                            <td> {getType === "3" ? 0 : item?.bhav || "-"} </td>
                            <td> {item?.amount || "-"} </td>
                            <td> {item?.loseAmount || "-"} </td>
                            <td>
                              {getType === "3" ? (
                                Math.sign(item?.playerPL) === -1 ? (
                                  <span className="text-danger">
                                    ({helpers.currencyFormat(item?.playerPL)})
                                  </span>
                                ) : (
                                  <span className="text-success">
                                    (
                                    {helpers.currencyFormat(
                                      Math.abs(item?.playerPL)
                                    )}
                                    )
                                  </span>
                                )
                              ) : item?.betType == "back" ||
                                item?.type == "Yes" ? (
                                <span className="text-success">
                                  {item?.profitAmount}
                                </span>
                              ) : (
                                <span className="text-danger">
                                  -({item?.loseAmount})
                                </span>
                              )}
                            </td>
                          </tr>
                        );
                      }
                    })
                  : null}

                {filter?.betType == "casino" && data && data?.length > 0
                  ? data?.map((item) => {
                      return (
                        <tr>
                          <td>{item?.clientName || "-"}</td>
                          <td> {item?.casinoBetId}</td>
                          <td> {helpers.dateFormat(item?.timeInserted)}</td>
                          <td>
                            {" "}
                            {(item?.ipAddress &&
                              item?.ipAddress.replace("::ffff:", "")) ||
                              "-"}
                          </td>
                          <td className="text-start">
                            {obj.betCheckObj[Number(getType)]}
                            <span className="angle_unicode">▸</span>
                            <strong>
                              {getType === "3"
                                ? item?.casinoName
                                : item?.matchName}
                            </strong>
                            <span className="angle_unicode">▸</span>
                            {getBetType === "betfair" && "Match Odds"}{" "}
                            {getBetType === "Bookmaker" && "Book Maker"}
                            {getBetType === "sportBook" && "Sport Book"}
                            {getBetType === "fancy" && item?.fancyName}{" "}
                            {getBetType === "casino" && item?.casinoType}{" "}
                          </td>
                          <td> {item?.platformTxId}</td>
                          <td> {item?.gameCode} </td>
                          <td> {item?.betAmount || "-"} </td>
                        </tr>
                      );
                    })
                  : null}
                {isEmpty(data) && (
                  <tbody>
                    <tr>
                      <td colSpan={12}>No Record Found</td>
                    </tr>
                  </tbody>
                )}
              </Table>
            </div>
          </div>
        </section>
      </Modal.Body>
    </Modal>
  );
};

export default ViewBets;
