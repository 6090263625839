import { isEmpty, reduce } from "lodash";
import React, { useContext, useState } from "react";
import { Button, Table } from "react-bootstrap";
import obj from "../../utils/constants";
import DropDown from "./DropDown";
import helpers from "../../utils/helpers";
import AuthContext from "../../context/AuthContext";
import { FancyListDropdown } from "./FancyListDropdown";
import { Link, useNavigate } from "react-router-dom";
import { MdRefresh } from "react-icons/md";
import ViewSportPremium from "./ViewSportPremium";
const FancyBetTypeListing = ({ title, data, refresh }) => {
  let { user } = useContext(AuthContext);
  const [modal, setModal] = useState(false);
  const [object, setObject] = useState({});
  const navigate = useNavigate();
  console.log(data,'data')
  return (
    <div className="risk-management-table">
      <div className="risk-management-table-header">
        {" "}
        <h2 className="common-heading">{title}</h2>
        <div>
          {" "}
          <MdRefresh onClick={() => refresh()} size={20} />
        </div>
      </div>
      <div className="account-table match-odd-table">
        <div className="responsive">
          <Table>
            <tbody>
              <tr>
                <td width="10%" rowSpan="2">
                  <strong> Sports</strong>
                </td>
                <td width="10%" rowSpan="2">
                  <strong> Market Date</strong>
                </td>
                <td width="25%" rowSpan="2">
                  <strong>Event/Market Name</strong>
                </td>
                <td
                  width="21%"
                  className="text-center border-l bg-light-yellow"
                  colSpan="3"
                >
                  <strong>Book P/L</strong>
                </td>
                <td width="6%" rowSpan="2" className="text-center">
                  <strong>View</strong>
                </td>
              </tr>
            </tbody>
            <tbody className="match-tbody">
              {data?.length > 0 &&
                data.map((res, index) => {
                  return (
                    <>
                      <tr>
                        <td className="text-center">
                          <p>{`${
                            res[1][0]?.eventType === "4"
                              ? "Cricket"
                              : res[1][0]?.eventType === "1"
                              ? "Soccer"
                              : res[1][0]?.eventType === "2"
                              ? "Tennis"
                              : "Casino"
                          }`}</p>
                        </td>
                        <td className="text-center">
                          {helpers.dateFormat(
                            res[1][0].timeInserted,
                            user.timeZone
                          )}{" "}
                        </td>
                        <td className="bg-yellow border-0">
                          <a>
                            {" "}
                            <Button
                              style={{
                                padding: "3px 5px",
                                fontSize: "12px",
                                marginRight: "5px",
                                background: "#16a7c0",
                                fontWeight: "600",
                                border: "none",
                              }}
                              onClick={() =>
                                navigate(
                                  `/sportanalysis/${res[1][0]?.eventId}/${res[1][0]?.marketId}`
                                )
                              }
                            >
                              View Details
                            </Button>
                            <strong>
                              <Link
                                to={`/sportanalysis/${res[1][0]?.eventId}/${res[1][0]?.marketId}`}
                              >
                                {res[0]}
                              </Link>
                            </strong>{" "}
                            <span
                              className="ms-3"
                              style={{ fontWeight: "600" }}
                            >
                              Total Bets : {res[1]?.length}
                            </span>
                          </a>
                        </td>
                        <td className="border-0 bg-yellow">
                          <p className="text-danger">
                            (
                            {
                              -reduce(
                                res[1],
                                function (sum, n) {
                                  return sum + n?.positionLoseAmount;
                                },
                                0
                              )
                            }
                            )
                          </p>
                        </td>

                        <td className="border-0 bg-yellow"></td>

                        <td className="border-0 bg-yellow">
                          <p className="text-success">
                            {reduce(
                              res[1],
                              function (sum, n) {
                                return sum + n?.positionProfitAmount;
                              },
                              0
                            )}
                          </p>
                        </td>
                        <td className="border-right-0 text-center">
                          <Link
                            to={`/sportanalysis/${res[1][0]?.eventId}/${res[1][0]?.marketId}`}
                            // onClick={() => {
                            //   setModal(true);
                            //   setObject({
                            //     eventId: res?.eventId,
                            //     selectionId: res?.selectionId,
                            //     marketId: res?.marketId,
                            //     userType: user?.userType,
                            //     userId: user?._id,
                            //   });
                            // }}
                            className="green-btn"
                          >
                            {"View"}
                          </Link>
                        </td>
                      </tr>
                    </>
                  );
                })}
              {isEmpty(data) && (
                <tr>
                  <td colSpan={7}>No Record Found</td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </div>
      {modal && (
        <ViewSportPremium
          onHide={() => {
            setModal(false);
            setObject({});
          }}
          setObject={setObject}
          show={modal}
          object={object}
        />
      )}
    </div>
  );
};

export default FancyBetTypeListing;
