const obj = {
  user_status: {
    owner: "OW",
    sub_owner: "SOW",
    super_agent: "SA",
    agent: "AG",
    user: "CL",
  },
  new_user_status: {
    super_agent: "SA",
    agent: "AG",
    user: "CL",
  },
  user_next_status: {
    owner: "sub_owner",
    sub_owner: "super_agent",
    super_agent: "agent",
    agent: "user",
  },
  market_Status: {
    1: "Open",
    2: "In Active",
    3: "Suspended",
    4: "Closed",
    9: "Ball Start",
  },
  market_category: {
    1: "Market",
    3: "Bookmakers",
    5: "ManualOdds",
    10: "Win Toss",
    2: "Session",
    6: "Over by Over Session Market",
    7: "Player",
    9: "Wicket",
    11: "Bowler Session",
    8: "Last Digit ODD",
    4: "LineMarket",
    14: "Premium ODDs",
  },
  settingHeading: {
    bookmaker: "Bookmaker Listing",
    betFaire: "Bet Fair Listing",
    fancy: "Fancy Listing",
    sportBook: "Premium Fancy Listing",
  },
  messageType: {
    downline: "Downline",
    all: "All",
    user: "User",
  },
  settingArray: [
    {
      type: "betFaire",
      minAmount: "",
      maxAmount: "",
      betDelay: "",
      onShow: 0,
      oddsLimit: "",
      maxProfit: "",
      maxError: false,
    },
    {
      type: "bookmaker",
      minAmount: "",
      maxAmount: "",
      betDelay: "",
      onShow: 0,
      oddsLimit: "",
      maxProfit: "",
      maxError: false,
    },
    {
      type: "fancy",
      minAmount: "",
      maxAmount: "",
      betDelay: "",
      onShow: 0,
      oddsLimit: "",
      maxProfit: "",
      maxError: false,
    },
    {
      type: "sportBook",
      minAmount: "",
      maxAmount: "",
      betDelay: "",
      onShow: 0,
      oddsLimit: "",
      maxProfit: "",
      maxError: false,
    },
  ],
  betCheckData: [
    {
      value: "Cricket",
      label: "4",
    },
    {
      value: "Tennis",
      label: "2",
    },
    {
      value: "Soccer",
      label: "1",
    },
    {
      value: "Casino",
      label: "3",
    },
  ],
  betCheckObj: {
    4: "Cricket",
    2: "Tennis",
    1: "Soccer",
    3: "Casino",
  },
  betCheckObjNew: {
    cricket: 4,
    tennis: 2,
    soccer: 1,
    casino: 3,
  },
  betCheckDataLabel: [
    {
      value: "Cricket",
      label: "cricket",
    },
    {
      value: "Tennis",
      label: "tennis",
    },
    {
      value: "Soccer",
      label: "soccer",
    },
    {
      value: "Casino",
      label: "casino",
    },
  ],
  betCheckDataInner: [
    {
      value: "Bet Fair",
      label: "betfair",
    },
    {
      value: "Bookmaker",
      label: "bookmaker",
    },
    {
      value: "Fancy",
      label: "fancy",
    },
   
   
    {
      value: "Toss",
      label: "toss",
    }, {
      value: "Lottery",
      label: "lottery",
    },
  ],
  matchType: {
    cricket: "Cricket",
    tennis: "Tennis",
    soccer: "Soccer",
    casino: "Casino",
  },
  homeCasino: [
    {
      casino: "kingmaker",
      name: "Baccarat",
      platForm: "KINGMAKER",
      gameType: "TABLE",
      casinoType: "KM-TABLE-026",
      image: "assets/images/home/KM-TABLE-026.png",
    },
    {
      casino: "kingmaker",
      name: "Coin Toss",
      platForm: "KINGMAKER",
      gameType: "TABLE",
      casinoType: "KM-TABLE-036",
      image: "assets/images/home/KM-TABLE-036.png",
    },
  ],
};

export default obj;

export const CasinoArray = [
  { title: "Aviatrix", gameCode: "67722", legacy: "67722-1_8" },
  {
    title: "Aviator",
    gameCode: "67722",
    legacy: "67722-2_8",
  },
  {
    gameCode: 5001,
    title: "Ezugi",
    id: "",
  },
  {
    gameCode: 5269,
    title: "Ultimate Sicbo",
    id: "224100",
  },
  {
    gameCode: 5252,
    title: "Sicbo",
    id: "224000",
  },
  {
    gameCode: 5001,
    title: "Grand Wheel",
    id: "266",
  },
  {
    gameCode: 5001,
    title: "Dead or Alive Saloon",
    id: "180",
  },
  {
    gameCode: 5114,
    title: "32 Cards",
    id: "228002",
  },

  {
    gameCode: 5074,
    title: "Andhar Bahar",
    id: "228000",
  },
  {
    gameCode: 5068,
    title: "Teen Patti",
    id: "227100",
  },
  {
    gameCode: 5048,
    title: "Speed Roulette",
    id: "221002",
  },
  {
    gameCode: 5046,
    title: "Ultimate BlackJack",
    id: "5051",
  },
  {
    gameCode: 5035,
    title: "Kingmaker Keno",
    id: "606000",
  },
  {
    gameCode: 5066,
    title: "Namaste Roulette",
    id: "411000",
  },
  {
    gameCode: 5059,
    title: "VIP BlackJack",
    id: "225",
  },
  {
    gameCode: 5051,
    title: "Lucky 7",
    id: "228001",
  },
  {
    gameCode: 5050,
    title: "Diamond Roulette",
    id: "221003",
  },
  {
    gameCode: 5001,
    title: "Devil's Number",
    id: "257",
  },
  {
    gameCode: 5001,
    title: "Atlantis Rising",
    id: "249",
  },
  {
    gameCode: 5001,
    title: "Mega Dragon",
    id: "277",
  },
  {
    gameCode: 5001,
    title: "Lucky Wizard",
    id: "274",
  },
  {
    gameCode: 5266,
    title: "VIP Fortune Baccarat",
    id: "106",
  },
  {
    gameCode: 5001,
    title: "Baccarat Pro",
    id: "41101",
  },
  {
    gameCode: 5271,
    title: "Royal Poker",
    id: "537000",
  },
  {
    gameCode: 5270,
    title: "Cricket War",
    id: "45100",
  },
  {
    title: "Evolution",
    gameCode: "101310",
  },

  {
    title: "Sicbo",
    gameCode: "21464",
    id: "RngSicbo00000001",
  },
  {
    title: "Football French Roulette",
    gameCode: "21000",
    id: "frenchroulettene",
  },
  {
    title: "Casino Hold'em",
    gameCode: "21000",
    id: "casinoholdemne00",
  },
  {
    title: "Baccarat Classic",
    gameCode: "21000",
    id: "baccarat2ne00000",
  },
  {
    title: "Andhar Bahar",
    gameCode: "21296",
    id: "AndarBahar000001",
  },
  {
    title: "Lightning Baccarat",
    gameCode: "21280",
    id: "rng-lbaccarat000",
  },
  {
    title: "Speed Auto Roulette",
    gameCode: "21257",
    id: "SpeedAutoRo00001",
  },
  {
    title: "Cash or Crash",
    gameCode: "21254",
    id: "CashOrCrash00001",
  },
  {
    title: "Mega Ball",
    gameCode: "21000",
    id: "MegaBall00000001",
  },
  {
    title: "Auto Roulette",
    gameCode: "21190",
    id: "01rb77cq1gtenhmo",
  },
  {
    title: "Speed Auto Roulette",
    gameCode: "21187",
    id: "lkcbrbdckjxajdol",
  },
  {
    title: "Roulette",
    gameCode: "21027",
    id: "wzg6kdkad1oe7m5k",
  },
  {
    title: "Triple Card Poker",
    gameCode: "21000",
    id: "n5emwq5c5dwepwam",
  },
  {
    title: "Lightning Dice",
    gameCode: "21018",
    id: "LightningDice001",
  },
  {
    title: "Dragon Tiger",
    gameCode: "21012",
    id: "DragonTiger00001",
  },
  {
    title: "Casino Hold'em",
    gameCode: "21010",
    id: "HoldemTable00001",
  },
  {
    title: "American Roulette",
    gameCode: "21002",
    id: "AmericanTable001",
  },
  {
    title: "Ultimate Poker",
    gameCode: "21000",
    id: "UTHTable00000001",
  },
  {
    title: "Lightning Lotto",
    gameCode: "21456",
    id: "Lightning6000001",
  },
  {
    title: "King Slots",
    gameCode: "21000",
    id: "kingofslots00000",
  },
  {
    title: "Lobby",
    gameCode: "98789",
  },

  {
    title: "7 up & 7 down",
    gameCode: "98789",
  },
  {
    title: "Live Teenpati",
    gameCode: "56767",
  },
  {
    title: "Teenpatti T20",
    gameCode: "56768",
  },
  {
    title: "Dragon Tiger",
    gameCode: "98790",
  },
  {
    title: "Hi Low",
    gameCode: "56968",
  },
  {
    title: "Worli Matka",
    gameCode: "92037",
  },
  {
    title: "Poker",
    gameCode: "67564",
  },
  {
    title: "32 Cards Casino",
    gameCode: "56967",
  },
  {
    title: "Andhar Bahar",
    gameCode: "87564",
  },
  {
    title: "Amar Akbar Anthony",
    gameCode: "98791",
  },
  {
    title: "Race 20-20",
    gameCode: "90100",
  },
  {
    title: "Bollywood Casino",
    gameCode: "67570",
  },
  {
    title: "Six Player Poker",
    gameCode: "67565",
  },
  {
    title: "Roulette",
    gameCode: "98788",
  },
  {
    title: "Sic-bo",
    gameCode: "98566",
  },
  {
    title: "Mufflis Teenpati",
    gameCode: "67601",
  },
  {
    title: "Trio",
    gameCode: "67610",
  },
  {
    title: "Baccarat",
    gameCode: "92038",
  },
  {
    title: "Casino Meter",
    gameCode: "67575",
  },
  {
    title: "Poker 20-20",
    gameCode: "67567",
  },
  {
    title: "2 Card Teenpati",
    gameCode: "67660",
  },
  {
    title: "Queen Race",
    gameCode: "67620",
  },
  {
    title: "Teenpati Test",
    gameCode: "67630",
  },
  {
    title: "Casino War",
    gameCode: "67580",
  },
  {
    title: "The Trap",
    gameCode: "67680",
  },
  {
    title: "Teenpati Open",
    gameCode: "67640",
  },
  {
    title: "29 Card Baccarat",
    gameCode: "67690",
  },
  {
    title: "Race to 17",
    gameCode: "67710",
  },
  {
    title: "Roulette (Virtual)",
    gameCode: "98792",
  },
  {
    title: "Dragon Tiger (Virtual)",
    gameCode: "98794",
  },
  {
    title: "Amar Akbar Anthony (Virtual)",
    gameCode: "98795",
  },
  {
    title: "Six Player Poker (Virtual)",
    gameCode: "67566",
  },
  {
    title: "Poker (Virtual)",
    gameCode: "67563",
  },
  {
    title: "32 Card Casino (Virtual)",
    gameCode: "56966",
  },
  {
    title: "Teenpatti One-Day (Virtual)",
    gameCode: "56766",
  },
  {
    title: "Andhar Bahar (Virtual)",
    gameCode: "87565",
  },
  {
    title: "Teenpatti T20 (Virtual)",
    gameCode: "56769",
  },
  {
    title: "Hi Low (Virtual)",
    gameCode: "56969",
  },
  {
    title: "Matka (Virtual)",
    gameCode: "92036",
  },
  {
    title: "7 Up & Down (Virtual)",
    gameCode: "98793",
  },
];
