import React, { useState, useContext } from "react";
import { Container, Row, Col, Form, Table, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import BetTypeListing from "./BetTypeListing";
import FancyBetTypeListing from "./FancyBetTypeListing";
import FancyBetTypeListingNew from "./FancyBetTypeListingNew";
import SportBookListing from "./SportBookListing";
import PremiumListing from "./PremiumListing";
import BinaryListing from "./BinaryListing";
import { apiGet, apiPost } from "../../utils/apiFetch";
import apiPath from "../../utils/apiPath";
import { useEffect } from "react";
import obj from "../../utils/constants";
import helpers from "../../utils/helpers";
import AuthContext from "../../context/AuthContext";
import io from "socket.io-client";
import { isEmpty, groupBy, inRange } from "lodash";
import { Helmet } from "react-helmet";
import DetailSlip from "../../components/DetailSlip";
import BetTypeListingMobile from "./BetTypeListing";
import Header from "../../components/Header";
const RiskManagementMobile = () => {
  const [headerTab, setHeaderTab] = useState("amount");
  const [matchData, setMatchData] = useState([]);
  const [topData, setTopdata] = useState({});
  const [eventId, setEventId] = useState("");
  const [matchName, setMatchName] = useState("");
  const [detailsData, setDetailsData] = useState({});
  const [fancyCentralizedIdArray, setFancyCentralizedIdArray] = useState([]);
  const [fancyList, setFancyList] = useState([]);
  const [sportBookList, setSportBookList] = useState([]);
  const [premiumCricketList, setPremiumCricketList] = useState([]);
  const [back_odds, setBackOdds] = useState([]);
  const [lay_odds, setLayOdds] = useState([]);
  const [betFairData, setBetFairData] = useState([]);
  const [tossData, setTossData] = useState([]);
  const [bookmakerData, setBookmakerData] = useState([]);
  const [betFairCentralizedIds, setBetFairCentralizedIds] = useState([]);
  const [bookmakerCentralizedIds, setBookMakerCentralizedIds] = useState([]);
  const [betFaireDataCheck, setBetFaireDataCheck] = useState(false);
  const [bookmakerDataCheck, setBookmakerDataCheck] = useState(false);
  const [search_params, setSearchParams] = useState({
    eventType: 4,
    status: "in_play",
  });
  let { user } = useContext(AuthContext);
  const [fancyOdds, setFancyOddds] = useState({});
  const [type, setType] = useState("cricket");
  const getMatchData = async (id) => {
    let obj = {};
    if (id) {
      obj = {
        eventType: id,
        status: "in_play",
      };
    } else {
      obj = { ...search_params };
    }
    const { status, data: response_users } = await apiGet(
      apiPath.matchFilterList,
      obj
    );
    if (status === 200) {
      if (response_users.success) {
        if (response_users.results) {
          setMatchData(response_users.results);
          setDetailsData({});
          setEventId("");
          setMatchName("");
        }
      }
    }
  };

  const getRiskData = async () => {
    const { status, data: response_users } = await apiGet(
      apiPath.riskMatchList,
      {
        userId: user?._id,
        userType: user?.userType,
      }
    );
    if (status === 200) {
      if (response_users.success) {
        setBetFaireDataCheck(true);
        if (response_users.results) {
          const fancyCentralizedIdArr = response_users?.results.fancyList?.map(
            (rt) => rt.marketId
          );
          setFancyCentralizedIdArray(fancyCentralizedIdArr);
          setBetFairData(
            response_users?.results?.length > 0
              ? response_users?.results?.sort(function (a, b) {
                  return (
                    new Date(b?.eventDateTime) - new Date(a?.eventDateTime)
                  );
                })
              : []
          );
          if (response_users?.results?.length > 0) {
            getOdds(
              response_users?.results?.map((res) => {
                return res?.marketId;
              })
            );
          }
          const betFairCentralizedIdsData =
            response_users?.results &&
            response_users?.results.length > 0 &&
            response_users?.results?.map((r) => r.marketId);

          if (betFairCentralizedIdsData && betFairCentralizedIdsData.length) {
            setBetFairCentralizedIds(betFairCentralizedIdsData);
          }
        }
      }
    }
  };

  // const getRiskToss = async () => {
  //   const { status, data: response_users } = await apiGet(
  //     apiPath.riskmanagemantToss,
  //     {
  //       userId: user._id,
  //       userType: user.userType,
  //     }
  //   );
  //   if (status === 200) {
  //     if (response_users.success) {
  //       if (response_users?.results?.length > 0) {
  //         setTossData(
  //           response_users?.results
  //             ? response_users?.results?.sort(function (a, b) {
  //                 return new Date(b.eventDateTime) - new Date(a.eventDateTime);
  //               })
  //             : []
  //         );
  //       }
  //     }
  //   }
  // };
  const getRiskDataBookmaker = async () => {
    const { status, data: response_users } = await apiGet(
      apiPath.riskMatchListBookmaker
    );
    if (status === 200) {
      if (response_users.success) {
        setBookmakerDataCheck(true);
        if (response_users?.results && response_users.results.length > 0) {
          setBookmakerData(
            response_users?.results?.length > 0
              ? response_users?.results?.sort(function (a, b) {
                  return (
                    new Date(b?.eventDateTime) - new Date(a?.eventDateTime)
                  );
                })
              : []
          );
          const bookMakerCentralizedIdsData = response_users?.results?.map(
            (r) => r.eventId
          );
          if (bookMakerCentralizedIdsData?.length > 0) {
            getOddsBookmaker(bookMakerCentralizedIdsData);
          }
          if (
            bookMakerCentralizedIdsData &&
            bookMakerCentralizedIdsData.length
          ) {
            setBookMakerCentralizedIds(bookMakerCentralizedIdsData);
          }
        }
      }
    }
  };

  const getRiskFancyData = async () => {
    const { status, data: response_users } = await apiGet(
      apiPath.riskManagementFancy
    );
    if (status === 200) {
      if (response_users.success) {
        if (response_users.results?.length > 0) {
          setFancyList(
            Object.entries(
              groupBy(response_users?.results, (x) => x.matchName)
            ) || []
          );
        } else {
          setFancyList([]);
        }
      }
    }
  };

  const getFancyData = async (id, selectionId) => {
    const { status, data: message } = await apiGet(
      apiPath.getFancyOdds + `?eventId=${id}`
    );
    if (status === 200) {
      if (message.success) {
        if (message?.results?.length > 0) {
          let obj = message?.results?.find((res) => {
            return res?.selectionId == selectionId;
          });
          if (!isEmpty(obj)) {
            setFancyOddds(obj);
          }
        }
      }
    }
  };

  const getRiskPremiumData = async () => {
    const { status, data: response_users } = await apiGet(
      apiPath.riskManagementPremium
    );
    if (status === 200) {
      if (response_users.success) {
        if (response_users.results) {
          setSportBookList(response_users?.results?.sportBook || []);
          setPremiumCricketList(response_users?.results?.premiumCricket || []);
        }
      }
    }
  };

  const getOdds = async (id) => {
    const { status, data: response_users } = await apiGet(
      apiPath.getMatchOdds + `?marketId=${id?.join(",")}&multi=true`
    );
    if (status === 200) {
      if (response_users.success) {
        if (response_users.results?.length > 0) {
          setBetFairData((current) =>
            current.map((obj) => {
              let newObj = response_users?.results?.find((res) => {
                return res?.mi == obj?.marketId;
              });
              if (obj?.marketId == newObj?.mi) {
                const back_odds = newObj.rt.filter((rt) => rt.ib) || [];
                const lay_odds = newObj.rt.filter((rt) => !rt.ib) || [];
                return { ...obj, back_odds: back_odds, lay_odds: lay_odds };
              }
              return obj;
            })
          );
        }
      }
    }
  };

  const checkLayOdds = (rt) => {
    if (inRange(rt, 0, 10)) {
      return Number(rt + 0.5);
    } else if (inRange(rt, 10, 30)) {
      return Number(rt + 1);
    } else if (inRange(rt, 30, 50)) {
      return Number(rt + 1.5);
    } else if (inRange(rt, 50, 99)) {
      return Number(rt + 2);
    } else {
      return rt;
    }
  };
  const getOddsBookmaker = async (id) => {
    const { status, data: response_users } = await apiGet(
      apiPath.bookmakerOdds + `?eventId=${id?.join(",")}&multi=true`
    );
    if (status === 200) {
      if (response_users.success) {
        if (response_users.results?.length > 0) {
          setBookmakerData((current) =>
            current.map((obj) => {
              let newObj = response_users?.results?.find((res) => {
                return res?.mi == obj?.marketId;
              });
              if (obj?.marketId == newObj?.mi) {
                const back_odds = newObj?.rt?.filter((rt) => rt.ib) || [];
                const newLayOdds = newObj?.rt?.filter((rt) => !rt.ib) || [];
                let lay_odds = [];
                if (back_odds?.length > 0 && newLayOdds?.length > 0) {
                  lay_odds = back_odds?.map((res) => {
                    let obj = newLayOdds?.find((item) => {
                      return item?.runnerName == res?.runnerName;
                    });
                    if (
                      obj?.runnerName == res?.runnerName &&
                      obj?.rt == res?.rt
                    ) {
                      return obj;
                    } else {
                      return {
                        ...res,
                        ib: false,
                        rt: checkLayOdds(Number(res?.rt)),
                      };
                    }
                  });
                } else {
                  lay_odds = newLayOdds;
                }
                return { ...obj, back_odds: back_odds, lay_odds: lay_odds };
              }
              return obj;
            })
          );
        }
      }
    }
  };
  useEffect(() => {
    if (!isEmpty(user)) {
      // getMatchData();
      getRiskData();
      getRiskFancyData();
      getRiskPremiumData();
      getRiskDataBookmaker();
      // getRiskToss();
    }
  }, [user]);

  useEffect(() => {
    const intervalID = setInterval(() => {
      if (!isEmpty(user)) {
        // getMatchData();
        getRiskData();
        getRiskFancyData();
        getRiskPremiumData();
        getRiskDataBookmaker();
        // getRiskToss();
      }
    }, 10 * 1000);

    return () => clearInterval(intervalID);
  }, [user]);

  const socketOddsWork = async (eventId, selectionId) => {
    const newSocket = io(
      `${process.env.REACT_APP_API_BASE_URL1}?token=${user._id}&userType=front&eventId=${eventId}`,
      {
        transports: ["websocket"],
        forceNew: true,
      }
    );

    const listenBetfairOdds = (event) => {
      const socket_data =
        (event && event?.results?.find((item) => item.eventId == eventId)) ||
        [];
      if (
        socket_data &&
        socket_data?.eventId &&
        eventId == socket_data?.eventId
      ) {
        if (socket_data.rt?.length) {
          setBetFairData((current) =>
            current.map((obj) => {
              if (obj.eventId == eventId) {
                const back_odds = socket_data.rt.filter((rt) => rt.ib) || [];
                const lay_odds = socket_data.rt.filter((rt) => !rt.ib) || [];
                return { ...obj, back_odds: back_odds, lay_odds: lay_odds };
              }
              return obj;
            })
          );
        }
      }
    };

    const listenBookmakerData = (message) => {
      if (message?.results?.rt.length > 0) {
        setBookmakerData((current) => {
          if (current?.length > 0) {
            return current?.map((obj) => {
              if (obj?.eventId == message?.eventId) {
                const back_odds =
                  message?.results?.rt?.filter((rt) => rt.ib) || [];
                const lay_odds =
                  message?.results?.rt?.filter((rt) => !rt.ib) || [];
                return {
                  ...obj,
                  back_odds: back_odds,
                  lay_odds: lay_odds,
                };
              }
              return obj;
            });
          }
        });
      }
    };

    const listenDiamondFancy = (message) => {
      setFancyList((current) => {
        if (current?.length > 0) {
          return current?.map((obj) => {
            const socket_data =
              (message?.results &&
                message?.results?.find(
                  (item) => item.selectionId == selectionId
                )) ||
              [];

            return {
              ...obj,
              odds: socket_data?.rt,
            };
          });
        }
      });
    };

    newSocket.on("listenDiamondFancy", listenDiamondFancy);
    newSocket.on("listenBetFairOdds", listenBetfairOdds);
    newSocket.on("listenBookmakerOdds", listenBookmakerData);

    return () => newSocket.close();
  };

  let newObj = {
    cricket: 4,
    tennis: 2,
    soccer: 1,
  };

  return (
    <>
      <Header />
      <div>
        {/* <DetailSlip /> */}
        <section className="main-inner-outer py-4" style={{ fontSize: "14px" }}>
          <Container fluid>
            <h4 className="page-title">Risk Management</h4>
            <div className="inner-wrapper">
              <div className="common-container">
                {betFaireDataCheck && bookmakerDataCheck ? (
                  <>
                    {betFairData?.length > 0 &&
                      betFairData?.filter((res) => {
                        return res?.gameType == "cricket";
                      })?.length > 0 && (
                        <BetTypeListingMobile
                          titleNew={"Match Odds - Cricket"}
                          title={"Match Odds"}
                          data={
                            betFairData?.filter((res) => {
                              return res?.gameType == "cricket";
                            }) || []
                          }
                          socketOddsWork={socketOddsWork}
                          refresh={getRiskData}
                        />
                      )}
                    {bookmakerData?.length > 0 &&
                      bookmakerData?.filter((res) => {
                        return res?.gameType == "cricket";
                      })?.length > 0 && (
                        <BetTypeListingMobile
                          titleNew={"Book Maker - Cricket"}
                          title={"Book Maker"}
                          data={
                            bookmakerData?.filter((res) => {
                              return res?.gameType == "cricket";
                            }) || []
                          }
                          socketOddsWork={socketOddsWork}
                          refresh={getRiskDataBookmaker}
                        />
                      )}
                    {betFairData?.length > 0 &&
                      betFairData?.filter((res) => {
                        return res?.gameType == "soccer";
                      })?.length > 0 && (
                        <BetTypeListingMobile
                          titleNew={"Match Odds - Soccer"}
                          title={"Match Odds"}
                          data={
                            betFairData?.filter((res) => {
                              return res?.gameType == "soccer";
                            }) || []
                          }
                          socketOddsWork={socketOddsWork}
                          refresh={getRiskData}
                        />
                      )}
                    {bookmakerData?.length > 0 &&
                      bookmakerData?.filter((res) => {
                        return res?.gameType == "soccer";
                      })?.length > 0 && (
                        <BetTypeListingMobile
                          titleNew={"Book Maker - Soccer"}
                          title={"Book Maker"}
                          data={
                            bookmakerData?.filter((res) => {
                              return res?.gameType == "soccer";
                            }) || []
                          }
                          socketOddsWork={socketOddsWork}
                          refresh={getRiskDataBookmaker}
                        />
                      )}
                    {betFairData?.length > 0 &&
                      betFairData?.filter((res) => {
                        return res?.gameType == "tennis";
                      })?.length > 0 && (
                        <BetTypeListingMobile
                          titleNew={"Match Odds - Tennis"}
                          title={"Match Odds"}
                          data={
                            betFairData?.filter((res) => {
                              return res?.gameType == "tennis";
                            }) || []
                          }
                          socketOddsWork={socketOddsWork}
                          refresh={getRiskData}
                        />
                      )}

                    {/* {tossData && tossData?.length > 0 && (
                      <BetTypeListingMobile
                        title={"Toss"}
                        titleNew={"Toss"}
                        data={tossData || []}
                        socketOddsWork={socketOddsWork}
                        refresh={getRiskToss}
                      />
                    )} */}

                    {fancyList?.length > 0 && (
                      <FancyBetTypeListingNew
                        title={"Fancy Bet"}
                        data={fancyList || []}
                        socketOddsWork={socketOddsWork}
                        refresh={getRiskFancyData}
                        getFancyData={getFancyData}
                        fancyOdds={fancyOdds}
                        setFancyOddds={setFancyOddds}
                      />
                    )}
                  </>
                ) : (
                  <div
                    id="loader"
                    className="spinner"
                    style={{ display: `block` }}
                  ></div>
                )}
                {sportBookList?.length > 0 && (
                  <SportBookListing
                    title={"Sports Book"}
                    data={sportBookList ? sportBookList : []}
                    refresh={getRiskPremiumData}
                  />
                )}
              </div>
            </div>
          </Container>
        </section>
      </div>
    </>
  );
};

export default RiskManagementMobile;
