import React, { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { CiSquarePlus } from "react-icons/ci";
import { apiGet } from "../utils/apiFetch";
import apiPath from "../utils/apiPath";
import { useNavigate } from "react-router-dom";
import { CiSquareRemove, CiSquareMinus } from "react-icons/ci";
const SidebarNew = () => {
  const navigate = useNavigate();
  const [active, setActive] = useState([]);
  const [innerActive, setInnerActive] = useState([]);
  const [data, setData] = useState({
    cricket: [],
    tennis: [],
    soccer: [],
  });
  const getData = async () => {
    const { status, data: response_users } = await apiGet(apiPath.seriesList);
    if (response_users?.success) {
      if (response_users?.results?.length > 0) {
        response_users?.results?.map((res) => {
          return { ...res, expand: false };
        });
        setData({
          cricket: response_users?.results?.filter((res) => {
            return res?.gameType == "cricket";
          }),
          tennis: response_users?.results?.filter((res) => {
            return res?.gameType == "tennis";
          }),
          soccer: response_users?.results?.filter((res) => {
            return res?.gameType == "soccer";
          }),
        });
      }
    }
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <div>
      <Accordion
        onSelect={(e) => {
          setActive((prev) => {
            return prev.includes(e)
              ? prev?.filter((res) => {
                  return res !== e;
                })
              : [...prev, e];
          });
        }}
        activeKey={active}
        className="accordion-outer"
      >
        <Accordion.Item eventKey="0">
          <Accordion.Header>Cricket</Accordion.Header>
          <Accordion.Body>
            <div className="accordion-outer-item">
              {data?.cricket?.map((res) => {
                return (
                  <>
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setData((prev) => {
                          return {
                            ...prev,
                            cricket: prev?.cricket?.map((item) => {
                              if (item?._id == res?._id) {
                                return { ...item, expand: !res?.expand };
                              } else {
                                return item;
                              }
                            }),
                          };
                        });
                      }}
                    >
                      {res?.expand ? (
                        <CiSquareMinus cursor={"pointer"} size={16} />
                      ) : (
                        <CiSquarePlus cursor={"pointer"} size={16} />
                      )}
                      <span>{res?.seriesName}</span>
                    </div>
                    {res?.expand && (
                      <div className="accordion-outer-item-inner">
                        {res?.matchList?.map((item) => {
                          return (
                            <div
                              onClick={() =>
                                navigate(
                                  `/sportanalysis/${item?.eventId}/${item?.marketId}`
                                )
                              }
                            >
                              <CiSquareRemove
                                className="accordion-outer-item-inner-icon"
                                cursor={"pointer"}
                                size={16}
                              />
                              <span>{item?.eventName}</span>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </>
                );
              })}
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>Soccer</Accordion.Header>
          <Accordion.Body>
            <div className="accordion-outer-item">
              {data?.soccer?.map((res) => {
                return (
                  <>
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setData((prev) => {
                          return {
                            ...prev,
                            soccer: prev?.soccer?.map((item) => {
                              if (item?._id == res?._id) {
                                return { ...item, expand: !res?.expand };
                              } else {
                                return item;
                              }
                            }),
                          };
                        });
                      }}
                    >
                      {res?.expand ? (
                        <CiSquareMinus cursor={"pointer"} size={16} />
                      ) : (
                        <CiSquarePlus cursor={"pointer"} size={16} />
                      )}
                      <span>{res?.seriesName}</span>
                    </div>
                    {res?.expand && (
                      <div className="accordion-outer-item-inner">
                        {res?.matchList?.map((item) => {
                          return (
                            <div
                              onClick={() =>
                                navigate(
                                  `/sportanalysis/${item?.eventId}/${item?.marketId}`
                                )
                              }
                            >
                              <CiSquareRemove cursor={"pointer"} size={16} />
                              <span>{item?.eventName}</span>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </>
                );
              })}
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>Tennis</Accordion.Header>
          <Accordion.Body>
            <div className="accordion-outer-item">
              {data?.tennis?.map((res) => {
                return (
                  <>
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setData((prev) => {
                          return {
                            ...prev,
                            tennis: prev?.tennis?.map((item) => {
                              if (item?._id == res?._id) {
                                return { ...item, expand: !res?.expand };
                              } else {
                                return item;
                              }
                            }),
                          };
                        });
                      }}
                    >
                      {res?.expand ? (
                        <CiSquareMinus cursor={"pointer"} size={16} />
                      ) : (
                        <CiSquarePlus cursor={"pointer"} size={16} />
                      )}
                      <span>{res?.seriesName}</span>
                    </div>
                    {res?.expand && (
                      <div className="accordion-outer-item-inner">
                        {res?.matchList?.map((item) => {
                          return (
                            <div
                              onClick={() =>
                                navigate(
                                  `/sportanalysis/${item?.eventId}/${item?.marketId}`
                                )
                              }
                            >
                              <CiSquareRemove cursor={"pointer"} size={16} />
                              <span>{item?.eventName}</span>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </>
                );
              })}
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default SidebarNew;
