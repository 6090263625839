import React, { useEffect, useState, useContext, useMemo } from "react";
import AddUser from "./Users/Add";
import { Container, Row, Col } from "react-bootstrap";
import { Link, useLocation, useParams } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import { apiGet } from "../utils/apiFetch";
import apiPath from "../utils/apiPath";
import UserList from "./Users/UserList";
import Search from "./Users/Search";
import Hierarchy from "./Users/Hierarchy";
import helpers from "../utils/helpers";
import constants from "../utils/constants";
import { compact, isEmpty, startCase } from "lodash";
import DetailSlip from "../components/DetailSlip";
import { Helmet } from "react-helmet";

const Home = () => {
  const location = useLocation();
  const user_params = compact(location.pathname.split("/"));
  let { user, setDetailSlipObj } = useContext(AuthContext);
  let user_by_created = user?.id;
  let userType = user?.userType;
  if (!isEmpty(user_params[0])) {
    user_by_created = user_params[0];
  }
  if (!isEmpty(user_params[1])) {
    userType = user_params[1];
  }
  const [show_model, setShowModel] = useState(false);
  const [reset, setRest] = useState(false);
  const [results, setResults] = useState([]);
  const [search_params, setSearchParams] = useState({
    page: 1,
    limit: 100,
    keyword: "",
    status: "active",
    created_by: user_by_created,
    userType: userType,
  });
  const [balance, setBalance] = useState({
    totalAvailableLimit: 0,
    totalAmount: 0,
    totalCoins: 0,
    playerBalance: 0,
    availableLimit: 0,
    exposure: 0,
  });

  const onSubmit = (params) => {
    setSearchParams((prevState) => {
      return {
        ...prevState,
        page: 1,
        keyword: params?.keyword,
        status: params?.status,
      };
    });
  };

  const handlePageClick = (event) => {
    setSearchParams((prevState) => {
      return {
        ...prevState,
        page: event.selected + 1,
      };
    });
  };

  const getUsers = async (obj) => {
    const { status, data: response_users } = await apiGet(
      apiPath.downLineList,
      !isEmpty(obj) ? obj : search_params
    );
    if (status === 200) {
      if (response_users.success) {
        setResults(response_users?.results || []);
        if ("balance" in response_users?.results) {
          setBalance((prevState) => {
            const balance_info = response_users?.results?.balance;
            return {
              ...prevState,
              totalAvailableLimit: balance_info.totalAvailableLimit,
              totalAmount: balance_info.totalAmount,
              totalCoins: balance_info.totalCoins,
              playerBalance: balance_info.playerBalance,
              availableLimit: balance_info.availableLimit,
              exposure: balance_info.exposure,
              playerExposure: balance_info.playerExposure,
            };
          });
          const balance_info = response_users?.results?.balance;
          setDetailSlipObj({
            totalAvailableLimit: balance_info.totalAvailableLimit,
            totalAmount: balance_info.totalAmount,
            totalCoins: balance_info.totalCoins,
            playerBalance: balance_info.playerBalance,
            availableLimit: balance_info.availableLimit,
            exposure: balance_info.exposure,
            playerExposure: balance_info.playerExposure,
            creditReference: balance_info.creditReference,
            upline:
              Math.abs(balance_info.creditReference) -
              Math.abs(balance_info.availableLimit),
            currentPL: balance_info.currentPL,
            totalRefrence: response_users?.results?.data
              ?.filter((res) => {
                return res?.creditReference > 0;
              })
              ?.reduce((acc, user) => {
                if (user?.creditReference > 0) {
                  const balance =
                    user?.userType == "user"
                      ? Math.sign(
                          user?.totalCoins1 + Math.abs(user?.exposure)
                        ) === -1
                        ? (
                            user?.totalCoins1 + Math.abs(user?.exposure)
                          ).toFixed(2)
                        : (
                            user?.totalCoins1 + Math.abs(user?.exposure)
                          ).toFixed(2)
                      : Math.sign(user?.totalCoins) === -1
                      ? user?.totalCoins.toFixed(2)
                      : user?.totalCoins.toFixed(2);
                  return (acc + user?.creditReference || 0) - balance;
                }
              }, 0),
          });
        }
      }
    }
    setRest(false);
  };

  const resetList = () => {
    setRest(true);
    setSearchParams((prevState) => {
      return {
        ...prevState,
        page: 1,
        page_size: 10,
        keyword: "",
        status: "active",
      };
    });
  };

  const refreshUsers = () => {
    getUsers();
  };

  useEffect(() => {
    const user_params = compact(location?.pathname?.split("/"));
    let user_by_created = user?.id;
    if (!isEmpty(user_params[0])) {
      user_by_created = user_params[0];
      setSearchParams((prevState) => {
        return {
          ...prevState,
          page: 1,
          page_size: 10,
          keyword: "",
          status: "active",
          created_by: user_by_created,
          userType: userType,
        };
      });
    } else {
      setSearchParams((prevState) => {
        return {
          ...prevState,
          page: 1,
          page_size: 10,
          keyword: "",
          status: "active",
          created_by: user?.id,
          userType: user?.userType,
        };
      });
    }
  }, [location]);

  useEffect(() => {
    getUsers();
  }, [
    search_params?.page,
    search_params?.keyword,
    search_params?.status,
    search_params?.created_by,
    search_params?.userType,
  ]);

  useEffect(() => {
    const intervalID = setInterval(() => {
      getUsers(search_params);
    }, 30 * 1000);

    return () => clearInterval(intervalID);
  }, [search_params]);

  const [message, setMessage] = useState([]);
  const messageList = async () => {
    let hostname = window.location.hostname;
    hostname = hostname.replace(/^www\./, "");
    hostname = hostname.replace(/^ag\./, "");
    hostname = hostname || "wifiexch.com";
    const { status, data: response_users } = await apiGet(
      apiPath.messageList + "&domain=" + hostname
    );
    if (status === 200) {
      if (response_users.success) {
        setMessage(response_users.results);
      }
    }
  };
  useEffect(() => {
    messageList();
  }, []);

  const availableBalance =
    (balance?.totalAmount &&
      Math.abs(balance?.totalAmount) - Math.abs(balance?.playerExposure)) ||
    0;
  return (
    <div>
     <section className="find-member-sec py-3 pb-0">
        <Container fluid>
          <h4 className="page-title">Clients</h4>
          <Row>
            <Col xl={12} className="mb-md-0 mb-3">
              <Row>
                <Col
                  xl={6}
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  className="d-flex flex-wrap"
                >
                  <Search
                    onSubmit={onSubmit}
                    search_params={search_params}
                    reset={reset}
                  />
                  <Hierarchy results={results} />
                </Col>
                <Col xl={6} lg={6} md={6} sm={6} xs={12}>
                  <div className="d-flex flex-wrap justify-content-end">
                    <div className="find-member-director text-xl-end ">
                      <a
                        href="#"
                        className="btn"
                        onClick={() => {
                          setShowModel(!show_model);
                        }}
                      >
                        <i className="fas fa-user-plus pe-1"></i> Add{" "}
                        {startCase(
                          constants.user_next_status[
                            user_params[1] !== undefined &&
                            user_params[0] !== ""
                              ? user_params[1]
                              : user?.userType
                          ]
                        )}
                      </a>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      {/* <DetailSlip /> */}
      <section className="total-balance-sec d-flex justify-content-center align-items-center">
          <ul className="list-unstyled detail-header mb-2" style={{width:"97%"}}>
            <li>
              <dt>Total Balance</dt>
              <strong>
                INR {`${helpers.currencyFormat(balance?.totalAmount)}`}
              </strong>
            </li>
            <li>
              <dt>Total Exposure</dt>
              <strong>
                INR{" "}
                <span>{`${helpers.currencyFormat(
                  balance?.playerExposure
                )}`}</span>
              </strong>
            </li>

            <li>
              <dt>Total Avail. bal.</dt>
              <strong>
                INR {`${helpers.currencyFormat(availableBalance)}`}
              </strong>
            </li>

            <li>
              <dt>Balance</dt>
              <strong>
                INR {`${helpers.currencyFormat(balance?.totalCoins)}`}
              </strong>
            </li>

            <li>
              <dt>Available Balance</dt>
              <strong>
                INR{" "}
                {`${helpers.currencyFormat(
                  Math.abs(availableBalance) + balance?.totalCoins
                )}`}
              </strong>
            </li>

            <li>
              <dt>Total Player Balance</dt>
              <strong>
                INR {`${helpers.currencyFormat(balance?.playerBalance)}`}
              </strong>
            </li>
          </ul>
      </section>
      <UserList
        results={results}
        handlePageClick={handlePageClick}
        getUsers={getUsers}
        data={user?.userType}
        balance={balance}
        availableBalance={availableBalance}
      />
      {show_model ? (
        <AddUser
          refreshUsers={refreshUsers}
          id={user_params[0] ? user_params[0] : user?._id}
          slug={user_params[1] ? user_params[1] : user?.userType}
          setShowModel={() => {
            setShowModel(!show_model);
          }}
        />
      ) : null}
    </div>
  );
};

export default Home;
