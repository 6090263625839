import { Box, styled, Typography, Divider } from "@mui/material";

import { emptyChatImage } from "../../../constants/data";

const Component = styled(Box)`
  background: #f8f9fa;
  padding: 30px 0;
  text-align: center;
  height: 95vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Image = styled("img")({
  width: 700,
});

const EmptyChat = () => {
  return (
    <Component>
      <Image src={emptyChatImage} alt="empty" />
    </Component>
  );
};

export default EmptyChat;
