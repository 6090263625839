import { isEmpty } from "lodash";
import React, { useContext, useState } from "react";
import { Container, Row, Col, Form, Table, Button } from "react-bootstrap";
import obj from "../../utils/constants";
import DropDown from "./DropDown";
import helpers from "../../utils/helpers";
import AuthContext from "../../context/AuthContext";
import { FancyListDropdown } from "./FancyListDropdown";
import { Link } from "react-router-dom";
import { MdRefresh } from "react-icons/md";
import FancyBetDialog from "./FancyBetDialog";
import { IoMdArrowDropright } from "react-icons/io";
const FancyBetTypeListingNew = ({
  title,
  data,
  socketOddsWork,
  refresh,
  getFancyData,
  fancyOdds,
  setFancyOddds,
}) => {
  const [showMatch, setShowMatch] = useState(false);
  let { user } = useContext(AuthContext);
  const [selectData, setSelectData] = useState([]);
  const [id, setId] = useState("");
  const [modal, setModal] = useState(false);
  const [object, setObject] = useState({});
  return (
    <div className="risk-management-table risk-management-table-new">
      <div className="risk-management-table-header">
        {" "}
        <h2 className="common-heading">{title}</h2>
        <div>
          {" "}
          <MdRefresh onClick={() => refresh()} size={20} />
        </div>
      </div>

      <div className="account-table match-odd-table">
        <div className="responsive">
          <Table>
            {/* <tbody style={{marginBottom:"5px"}}>
              <tr>
                <td width="100%">
                  <strong>Event/Market Name</strong>
                </td>
              </tr>
            </tbody> */}
            <tbody className="match-tbody">
              {isEmpty(data) && (
                <tr>
                  <td colSpan={7}>No Record Found</td>
                </tr>
              )}
              {data?.length > 0 &&
                data.map((res) => {
                  return (
                    <>
                      <tr>
                        <td
                          style={{ paddingTop: "13px", paddingBottom: "13px" }}
                        >
                          {/* <div className="d-flex justify-content-between align-items-center mb-2"> */}
                          <strong>{res[0]}</strong> {/* </div> */}
                        </td>
                      </tr>
                      {res[1]?.length > 0 &&
                        res[1]?.map((item, index) => {
                          return (
                            <>
                              <tr key={index + 1}>
                                <td>
                                  <div className="d-flex justify-content-between align-items-center mb-2">
                                    <a>
                                      <strong>{item?.fancyName}</strong>{" "}
                                      {/* <IoMdArrowDropright /> */}
                                      {/* <span className="ms-3">
                                            {item?.fancyName}{" "}
                                          </span> */}
                                    </a>
                                    <>
                                      {item?._id == id ? (
                                        <Button
                                          onClick={() => {
                                            setShowMatch(false);
                                            setSelectData("");
                                            setId("");
                                            socketOddsWork();
                                            setFancyOddds({});
                                          }}
                                          className={"angle-up"}
                                        >
                                          <i className="fas fa-angle-up"></i>
                                        </Button>
                                      ) : (
                                        <Button
                                          onClick={() => {
                                            setShowMatch(true);
                                            setId(item?._id);
                                            setSelectData(item?.odds);
                                            socketOddsWork(
                                              item?.eventId,
                                              item?.selectionId
                                            );
                                            getFancyData(
                                              item?.eventId,
                                              item?.selectionId
                                            );
                                          }}
                                          className={"angle-up down-up"}
                                        >
                                          <i className="fas fa-angle-up"></i>
                                        </Button>
                                      )}
                                    </>
                                  </div>
                                  <div
                                    className="d-flex w-100 justify-content-between align-items-center p-0"
                                    style={{
                                      lineHeight: "1.5rem",
                                      border: ".5px solid lightgrey",
                                    }}
                                  >
                                    <div className="bg-light-yellow w-50">
                                      <strong className="d-flex justify-content-center align-items-center">
                                        {" "}
                                        Min
                                      </strong>
                                      <div
                                        className=" back-1s p-0"
                                        style={{
                                          cursor: "not-allowed",
                                          background: "white",
                                        }}
                                      >
                                        <span className="text-danger d-flex justify-content-center align-items-center">
                                          {item?.positionLoseAmount
                                            ? "(-" +
                                              item?.positionLoseAmount +
                                              ")"
                                            : 0}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="bg-light-yellow  w-50">
                                      <strong className="d-flex justify-content-center align-items-center">
                                        Max
                                      </strong>
                                      <div
                                        className=""
                                        style={{
                                          cursor: "not-allowed",
                                          background: "white",
                                        }}
                                      >
                                        <span className="text-success d-flex justify-content-center align-items-center">
                                          {item?.positionProfitAmount
                                            ? item?.positionProfitAmount
                                            : 0}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                              {item?._id == id ? (
                                <FancyListDropdown
                                  showMatch={showMatch}
                                  data={item?.jsonData}
                                  selectData={selectData}
                                  res={item}
                                  user={user}
                                  title={title}
                                  setModalOuter={setModal}
                                  modalOuter={modal}
                                  setObjectOuter={setObject}
                                  fancyOdds={
                                    fancyOdds?.rt?.length > 0 &&
                                    item?.selectionId == fancyOdds?.selectionId
                                      ? fancyOdds?.rt
                                      : []
                                  }
                                />
                              ) : (
                                ""
                              )}
                            </>
                          );
                        })}
                    </>
                  );
                })}
              {/* {data?.length > 0 &&
                data.map((res, index) => {
                  return (
                    <>
                      <tr key={index + 1}>
                        <td>
                          <div className="d-flex justify-content-between align-items-center mb-2">
                            <a>
                              <strong>{res?.matchName}</strong>{" "}
                              <IoMdArrowDropright />
                              <span className="ms-3">{res?.fancyName} </span>
                            </a>
                            <>
                              {index + 1 == id ? (
                                <Button
                                  onClick={() => {
                                    setShowMatch(false);
                                    setSelectData("");
                                    setId("");
                                    socketOddsWork();
                                    setFancyOddds({});
                                  }}
                                  className={"angle-up"}
                                >
                                  <i className="fas fa-angle-up"></i>
                                </Button>
                              ) : (
                                <Button
                                  onClick={() => {
                                    setShowMatch(true);
                                    setId(index + 1);
                                    setSelectData(res?.odds);
                                    socketOddsWork(
                                      res?.eventId,
                                      res?.selectionId
                                    );
                                    getFancyData(
                                      res?.eventId,
                                      res?.selectionId
                                    );
                                  }}
                                  className={"angle-up down-up"}
                                >
                                  <i className="fas fa-angle-up"></i>
                                </Button>
                              )}
                            </>
                          </div>
                        </td>
                      </tr>
                      {index + 1 == id ? (
                        <FancyListDropdown
                          showMatch={showMatch}
                          data={res?.jsonData}
                          selectData={selectData}
                          res={res}
                          user={user}
                          title={title}
                          setModalOuter={setModal}
                          modalOuter={modal}
                          setObjectOuter={setObject}
                          fancyOdds={
                            fancyOdds?.rt?.length > 0 &&
                            res?.selectionId == fancyOdds?.selectionId
                              ? fancyOdds?.rt
                              : []
                          }
                        />
                      ) : (
                        ""
                      )}
                    </>
                  );
                })} */}
            </tbody>
          </Table>
        </div>
      </div>
      {modal && (
        <FancyBetDialog
          onHide={() => {
            setModal(false);
            setObject({});
          }}
          setObject={setObject}
          show={modal}
          object={object}
        />
      )}
    </div>
  );
};

export default FancyBetTypeListingNew;
