import React, { useContext, useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "../src/assets/css/style.css";
import "../src/assets/css/developer.css";
import "../src/assets/css/responsive.css";
import Login from "./Auth/Login";
import NoMatch from "./pages/NoMatch";
import { Route, Routes, useLocation } from "react-router-dom";
import PrivateRoute from "./utils/PrivateRoute";
import AuthContext from "./context/AuthContext";
import { routes } from "./Route";
import ViewDialog from "./pages/RiskManagement/ViewDialog";
import FancyBetDialog from "./pages/RiskManagement/FancyBetDialog";
import ViewSportPremium from "./pages/RiskManagement/ViewSportPremium";
import ViewBetsResult from "./pages/ViewBetsResult";
import MarketBets from "./pages/MarketBets";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import LoginMobile from "./Auth/LoginMobile";
import LoginNew from "./Auth/LoginNew";
import { ChangePass } from "./components/ChangePass";
import ViewBets from "./pages/RiskManagement/ViewBets";
import SidebarNew from "./components/SidebarNew";
import BankingMobile from "./pages/BankingMobile/BankingMobile";
import Banking from "./pages/Banking/Banking";
import useWindowDimensions from "./components/getWindowSize";
import RiskManagementMobile from "./pages/RiskManagementMobile/RiskManagementMobile";
import RiskManagement from "./pages/RiskManagement/RiskManagement";
function App() {
  const location = useLocation();
  let { user, changePass, newSidebar } = useContext(AuthContext);
  const [mobile, setMobile] = useState(false);
  const { width } = useWindowDimensions();

  useEffect(() => {


    // Get the domain name
    const domainName = window.location.hostname;

    console.log('domainName',domainName);

    // Dynamically set the title and favicon based on the domain
    let newTitle = "WifiExch";
    let newFavicon = "%PUBLIC_URL%/favicon.ico"; // Default favicon path

    if (domainName.includes("mirajexchange.com")) {
      newTitle = "MirajExch";
      newFavicon = "%PUBLIC_URL%/favicon.ico"; // Path to MirajExch favicon
    } else if (domainName.includes("anybett.live") || domainName.includes("anybett.live") || domainName.includes("admin.anybett.live")) {
      newTitle = "Anybett";
      newFavicon = "%PUBLIC_URL%/ax.ico"; // Path for Anybett favicon
    }else if (domainName.includes("aaexch.live") || domainName.includes("aaexch.live") || domainName.includes("admin.aaexch.live")) {
      newTitle = "aaexch";
      newFavicon = "%PUBLIC_URL%/ax.ico"; // Path for Anybett favicon
    }

    // Update the document title
    document.title = newTitle;

    // Update the favicon
    const faviconElement = document.getElementById("favicon");
    if (faviconElement) {
      faviconElement.href = newFavicon;
    }

  }, []);

  return (
    <div className="App">
      {newSidebar && <SidebarNew />}
      <wc-toast
        position={
          location.pathname == "/add-match" ? "top-center" : "top-right"
        }
      ></wc-toast>
      <Routes>
        <Route element={<PrivateRoute />}>
          {routes?.map((item, index) => {
            return item?.permission?.includes(user?.userType) ? (
              <Route
                exact
                path={item.path}
                key={index}
                element={<item.Component />}
              />
            ) : item?.permission?.length == 0 ? (
              <Route
                exact
                path={item.path}
                key={index}
                element={<item.Component />}
              />
            ) : (
              ""
            );
          })}
        </Route>
        <Route
          exact
          path="/login"
          // element={isMobile ? <LoginMobile /> : <Login />}
          // element={<Login />}
          element={<LoginNew />}
        ></Route>
        <Route
          exact
          path="/banking"
          element={width > 756 ? <Banking /> : <BankingMobile />}
        ></Route>
        <Route
          exact
          path="/RiskManagement"
          element={width > 756 ? <RiskManagement /> : <RiskManagementMobile />}
        ></Route>
        <Route
          exact
          path="/DownlinePnl/:userId/:userType/:id/:type"
          element={<ViewDialog />}
        ></Route>
        <Route
          exact
          path="/ViewBets/:userId/:userType/:type"
          element={<ViewBets />}
        ></Route>
        <Route
          exact
          path="/ViewBets/:userId/:userType/:type/:selectionId"
          element={<ViewBets />}
        ></Route>
        <Route
          exact
          path="/DownlinePnl-Fancy/:name/:eventid/:marketId/:selectionId"
          element={<FancyBetDialog />}
        ></Route>
        <Route
          exact
          path="/DownlinePnl-sport-premium/:eventId/:selectionId/:marketId/:userType/:userId"
          element={<ViewSportPremium />}
        ></Route>
        <Route
          exact
          path="/view-bets-result/:eventId/:marketId/:selectionId"
          element={<ViewBetsResult />}
        ></Route>
        <Route
          exact
          path="/match-market-bets/:eventId/:betFairType"
          element={<MarketBets />}
        ></Route>
        <Route path="*" element={<NoMatch />} />
      </Routes>
      {changePass && <ChangePass />}
    </div>
  );
}

export default App;
